const cityChild = [
  {
    code: '110101',
    name: '东城区',
    cityCode: '1101',
    enName: 'Dongcheng',
  },
  {
    code: '110102',
    name: '西城区',
    cityCode: '1101',
    enName: 'Xicheng',
  },
  {
    code: '110105',
    name: '朝阳区',
    cityCode: '1101',
    enName: 'Chaoyang',
  },
  {
    code: '110106',
    name: '丰台区',
    cityCode: '1101',
    enName: 'Fengtai',
  },
  {
    code: '110107',
    name: '石景山区',
    cityCode: '1101',
    enName: 'Shijingshan',
  },
  {
    code: '110108',
    name: '海淀区',
    cityCode: '1101',
    enName: 'Haidian',
  },
  {
    code: '110109',
    name: '门头沟区',
    cityCode: '1101',
    enName: 'Mentougou',
  },
  {
    code: '110111',
    name: '房山区',
    cityCode: '1101',
    enName: 'Fangshan',
  },
  {
    code: '110112',
    name: '通州区',
    cityCode: '1101',
    enName: 'Tongzhou',
  },
  {
    code: '110113',
    name: '顺义区',
    cityCode: '1101',
    enName: 'Shunyi',
  },
  {
    code: '110114',
    name: '昌平区',
    cityCode: '1101',
    enName: 'Changping',
  },
  {
    code: '110115',
    name: '大兴区',
    cityCode: '1101',
    enName: 'Daxing',
  },
  {
    code: '110116',
    name: '怀柔区',
    cityCode: '1101',
    enName: 'Huairou',
  },
  {
    code: '110117',
    name: '平谷区',
    cityCode: '1101',
    enName: 'Pinggu',
  },
  { code: '110118', name: '密云区', cityCode: '1101', enName: 'Miyun' },
  {
    code: '110119',
    name: '延庆区',
    cityCode: '1101',
    enName: 'Yanqing',
  },
  {
    code: '120101',
    name: '和平区',
    cityCode: '1201',
    enName: 'Heping',
  },
  {
    code: '120102',
    name: '河东区',
    cityCode: '1201',
    enName: 'Hedong',
  },
  { code: '120103', name: '河西区', cityCode: '1201', enName: 'Hexi' },
  {
    code: '120104',
    name: '南开区',
    cityCode: '1201',
    enName: 'Nankai',
  },
  { code: '120105', name: '河北区', cityCode: '1201', enName: 'Hebei' },
  {
    code: '120106',
    name: '红桥区',
    cityCode: '1201',
    enName: 'Hongqiao',
  },
  {
    code: '120110',
    name: '东丽区',
    cityCode: '1201',
    enName: 'Dongli',
  },
  {
    code: '120111',
    name: '西青区',
    cityCode: '1201',
    enName: 'Xiqing',
  },
  {
    code: '120112',
    name: '津南区',
    cityCode: '1201',
    enName: 'Jingnan',
  },
  {
    code: '120113',
    name: '北辰区',
    cityCode: '1201',
    enName: 'Beichen',
  },
  {
    code: '120114',
    name: '武清区',
    cityCode: '1201',
    enName: 'Wuqing',
  },
  { code: '120115', name: '宝坻区', cityCode: '1201', enName: 'Baodi' },
  {
    code: '120116',
    name: '滨海新区',
    cityCode: '1201',
    enName: 'Binghaixin',
  },
  {
    code: '120117',
    name: '宁河区',
    cityCode: '1201',
    enName: 'Ninghe',
  },
  {
    code: '120118',
    name: '静海区',
    cityCode: '1201',
    enName: 'Jinghai',
  },
  {
    code: '120119',
    name: '蓟州区',
    cityCode: '1201',
    enName: 'Jizhou',
  },
  {
    code: '130102',
    name: '长安区',
    cityCode: '1301',
    enName: 'Changan',
  },
  {
    code: '130104',
    name: '桥西区',
    cityCode: '1301',
    enName: 'Qiaoxi',
  },
  {
    code: '130105',
    name: '新华区',
    cityCode: '1301',
    enName: 'Xinhua',
  },
  {
    code: '130107',
    name: '井陉矿区',
    cityCode: '1301',
    enName: 'Jingjing Mineral District',
  },
  { code: '130108', name: '裕华区', cityCode: '1301', enName: 'Yuhua' },
  {
    code: '130109',
    name: '藁城区',
    cityCode: '1301',
    enName: 'Gaocheng',
  },
  {
    code: '130110',
    name: '鹿泉区',
    cityCode: '1301',
    enName: 'Luquan',
  },
  {
    code: '130111',
    name: '栾城区',
    cityCode: '1301',
    enName: 'Luancheng',
  },
  {
    code: '130121',
    name: '井陉县',
    cityCode: '1301',
    enName: 'Jingjing',
  },
  {
    code: '130123',
    name: '正定县',
    cityCode: '1301',
    enName: 'Zhengding',
  },
  {
    code: '130125',
    name: '行唐县',
    cityCode: '1301',
    enName: 'Xingtang',
  },
  {
    code: '130126',
    name: '灵寿县',
    cityCode: '1301',
    enName: 'Lingshou',
  },
  { code: '130127', name: '高邑县', cityCode: '1301', enName: 'Gaoyi' },
  {
    code: '130128',
    name: '深泽县',
    cityCode: '1301',
    enName: 'Shenze',
  },
  {
    code: '130129',
    name: '赞皇县',
    cityCode: '1301',
    enName: 'Zanhuang',
  },
  { code: '130130', name: '无极县', cityCode: '1301', enName: 'Wuji' },
  {
    code: '130131',
    name: '平山县',
    cityCode: '1301',
    enName: 'Pingshan',
  },
  {
    code: '130132',
    name: '元氏县',
    cityCode: '1301',
    enName: 'Yuanshi',
  },
  { code: '130133', name: '赵县', cityCode: '1301', enName: 'Zhao' },
  {
    code: '130183',
    name: '晋州市',
    cityCode: '1301',
    enName: 'Jinzhou',
  },
  { code: '130184', name: '新乐市', cityCode: '1301', enName: 'Xinle' },
  { code: '130202', name: '路南区', cityCode: '1302', enName: 'Lunan' },
  { code: '130203', name: '路北区', cityCode: '1302', enName: 'Lubei' },
  { code: '130204', name: '古冶区', cityCode: '1302', enName: 'Guye' },
  {
    code: '130205',
    name: '开平区',
    cityCode: '1302',
    enName: 'Kaiping',
  },
  {
    code: '130207',
    name: '丰南区',
    cityCode: '1302',
    enName: 'Fengnan',
  },
  {
    code: '130208',
    name: '丰润区',
    cityCode: '1302',
    enName: 'Fengrun',
  },
  {
    code: '130209',
    name: '曹妃甸区',
    cityCode: '1302',
    enName: 'Caofeidian',
  },
  { code: '130223', name: '滦县', cityCode: '1302', enName: 'Tuan' },
  {
    code: '130224',
    name: '滦南县',
    cityCode: '1302',
    enName: 'Tuannan',
  },
  {
    code: '130225',
    name: '乐亭县',
    cityCode: '1302',
    enName: 'Leting',
  },
  {
    code: '130227',
    name: '迁西县',
    cityCode: '1302',
    enName: 'Qianxi',
  },
  {
    code: '130229',
    name: '玉田县',
    cityCode: '1302',
    enName: 'Yutian',
  },
  {
    code: '130281',
    name: '遵化市',
    cityCode: '1302',
    enName: 'Zunhua',
  },
  {
    code: '130283',
    name: '迁安市',
    cityCode: '1302',
    enName: 'Qianan',
  },
  {
    code: '130302',
    name: '海港区',
    cityCode: '1303',
    enName: 'Haigang',
  },
  {
    code: '130303',
    name: '山海关区',
    cityCode: '1303',
    enName: 'Shanhaiguan',
  },
  {
    code: '130304',
    name: '北戴河区',
    cityCode: '1303',
    enName: 'Beidaihe',
  },
  {
    code: '130306',
    name: '抚宁区',
    cityCode: '1303',
    enName: 'Funing',
  },
  {
    code: '130321',
    name: '青龙满族自治县',
    cityCode: '1303',
    enName: 'Qinglong Manchu Autonomous County',
  },
  {
    code: '130322',
    name: '昌黎县',
    cityCode: '1303',
    enName: 'Changli',
  },
  {
    code: '130324',
    name: '卢龙县',
    cityCode: '1303',
    enName: 'Lulong',
  },
  {
    code: '130402',
    name: '邯山区',
    cityCode: '1304',
    enName: 'Hanshan',
  },
  {
    code: '130403',
    name: '丛台区',
    cityCode: '1304',
    enName: 'Congtai',
  },
  {
    code: '130404',
    name: '复兴区',
    cityCode: '1304',
    enName: 'Fuxing',
  },
  {
    code: '130406',
    name: '峰峰矿区',
    cityCode: '1304',
    enName: 'Fengfeng Mineral District',
  },
  {
    code: '130421',
    name: '邯郸县',
    cityCode: '1304',
    enName: 'Handan',
  },
  {
    code: '130423',
    name: '临漳县',
    cityCode: '1304',
    enName: 'Linzhang',
  },
  {
    code: '130424',
    name: '成安县',
    cityCode: '1304',
    enName: 'Chengan',
  },
  {
    code: '130425',
    name: '大名县',
    cityCode: '1304',
    enName: 'Daming',
  },
  { code: '130426', name: '涉县', cityCode: '1304', enName: 'She' },
  { code: '130427', name: '磁县', cityCode: '1304', enName: 'Ci' },
  {
    code: '130428',
    name: '肥乡县',
    cityCode: '1304',
    enName: 'Feixiang',
  },
  {
    code: '130429',
    name: '永年县',
    cityCode: '1304',
    enName: 'Yongnian',
  },
  { code: '130430', name: '邱县', cityCode: '1304', enName: 'Qiu' },
  { code: '130431', name: '鸡泽县', cityCode: '1304', enName: 'Jize' },
  {
    code: '130432',
    name: '广平县',
    cityCode: '1304',
    enName: 'Guangping',
  },
  {
    code: '130433',
    name: '馆陶县',
    cityCode: '1304',
    enName: 'Guantao',
  },
  { code: '130434', name: '魏县', cityCode: '1304', enName: 'Wei' },
  {
    code: '130435',
    name: '曲周县',
    cityCode: '1304',
    enName: 'Quzhou',
  },
  { code: '130481', name: '武安市', cityCode: '1304', enName: 'Wuan' },
  {
    code: '130502',
    name: '桥东区',
    cityCode: '1305',
    enName: 'Qiaodong',
  },
  {
    code: '130503',
    name: '桥西区',
    cityCode: '1305',
    enName: 'Qiaoxi',
  },
  {
    code: '130521',
    name: '邢台县',
    cityCode: '1305',
    enName: 'Xingtai',
  },
  {
    code: '130522',
    name: '临城县',
    cityCode: '1305',
    enName: 'Lincheng',
  },
  {
    code: '130523',
    name: '内丘县',
    cityCode: '1305',
    enName: 'Neiqiu',
  },
  {
    code: '130524',
    name: '柏乡县',
    cityCode: '1305',
    enName: 'Baixiang',
  },
  {
    code: '130525',
    name: '隆尧县',
    cityCode: '1305',
    enName: 'Longyao',
  },
  { code: '130526', name: '任县', cityCode: '1305', enName: 'Ren' },
  { code: '130527', name: '南和县', cityCode: '1305', enName: 'Nanhe' },
  {
    code: '130528',
    name: '宁晋县',
    cityCode: '1305',
    enName: 'Ningjin',
  },
  { code: '130529', name: '巨鹿县', cityCode: '1305', enName: 'Julu' },
  { code: '130530', name: '新河县', cityCode: '1305', enName: 'Xinhe' },
  {
    code: '130531',
    name: '广宗县',
    cityCode: '1305',
    enName: 'Guangzong',
  },
  {
    code: '130532',
    name: '平乡县',
    cityCode: '1305',
    enName: 'Pingxiang',
  },
  { code: '130533', name: '威县', cityCode: '1305', enName: 'Wei' },
  {
    code: '130534',
    name: '清河县',
    cityCode: '1305',
    enName: 'Qinghe',
  },
  { code: '130535', name: '临西县', cityCode: '1305', enName: 'Linxi' },
  {
    code: '130581',
    name: '南宫市',
    cityCode: '1305',
    enName: 'Nangong',
  },
  { code: '130582', name: '沙河市', cityCode: '1305', enName: 'Shahe' },
  {
    code: '130602',
    name: '竞秀区',
    cityCode: '1306',
    enName: 'Jingxiu',
  },
  {
    code: '130606',
    name: '莲池区',
    cityCode: '1306',
    enName: 'Lianchi',
  },
  {
    code: '130607',
    name: '满城区',
    cityCode: '1306',
    enName: 'Mancheng',
  },
  {
    code: '130608',
    name: '清苑区',
    cityCode: '1306',
    enName: 'Qingyuan',
  },
  {
    code: '130609',
    name: '徐水区',
    cityCode: '1306',
    enName: 'Xushui',
  },
  {
    code: '130623',
    name: '涞水县',
    cityCode: '1306',
    enName: 'Laishui',
  },
  {
    code: '130624',
    name: '阜平县',
    cityCode: '1306',
    enName: 'Fuping',
  },
  {
    code: '130626',
    name: '定兴县',
    cityCode: '1306',
    enName: 'Dingxing',
  },
  { code: '130627', name: '唐县', cityCode: '1306', enName: 'Tang' },
  {
    code: '130628',
    name: '高阳县',
    cityCode: '1306',
    enName: 'Gaoyang',
  },
  {
    code: '130629',
    name: '容城县',
    cityCode: '1306',
    enName: 'Rongcheng',
  },
  {
    code: '130630',
    name: '涞源县',
    cityCode: '1306',
    enName: 'Laiyuan',
  },
  {
    code: '130631',
    name: '望都县',
    cityCode: '1306',
    enName: 'Wangdou',
  },
  { code: '130632', name: '安新县', cityCode: '1306', enName: 'Anxin' },
  { code: '130633', name: '易县', cityCode: '1306', enName: 'Yi' },
  {
    code: '130634',
    name: '曲阳县',
    cityCode: '1306',
    enName: 'Quyang',
  },
  { code: '130635', name: '蠡县', cityCode: '1306', enName: 'Li' },
  {
    code: '130636',
    name: '顺平县',
    cityCode: '1306',
    enName: 'Shunping',
  },
  { code: '130637', name: '博野县', cityCode: '1306', enName: 'Boye' },
  { code: '130638', name: '雄县', cityCode: '1306', enName: 'Xiong' },
  {
    code: '130681',
    name: '涿州市',
    cityCode: '1306',
    enName: 'Zhuozhou',
  },
  { code: '130683', name: '安国市', cityCode: '1306', enName: 'Anguo' },
  {
    code: '130684',
    name: '高碑店市',
    cityCode: '1306',
    enName: 'Gaobeidian',
  },
  {
    code: '130702',
    name: '桥东区',
    cityCode: '1307',
    enName: 'Qiaodong',
  },
  {
    code: '130703',
    name: '桥西区',
    cityCode: '1307',
    enName: 'Qiaoxi',
  },
  {
    code: '130705',
    name: '宣化区',
    cityCode: '1307',
    enName: 'Xuanhua',
  },
  {
    code: '130706',
    name: '下花园区',
    cityCode: '1307',
    enName: 'Xiahuayuan',
  },
  { code: '130708', name: '万全区', cityCode: '1307', enName: 'Wanqu' },
  {
    code: '130709',
    name: '崇礼区',
    cityCode: '1307',
    enName: 'Chongli',
  },
  {
    code: '130722',
    name: '张北县',
    cityCode: '1307',
    enName: 'Zhangbei',
  },
  {
    code: '130723',
    name: '康保县',
    cityCode: '1307',
    enName: 'Kangbao',
  },
  {
    code: '130724',
    name: '沽源县',
    cityCode: '1307',
    enName: 'Guyuan',
  },
  {
    code: '130725',
    name: '尚义县',
    cityCode: '1307',
    enName: 'Shangyi',
  },
  { code: '130726', name: '蔚县', cityCode: '1307', enName: 'Wei' },
  {
    code: '130727',
    name: '阳原县',
    cityCode: '1307',
    enName: 'Yangyuan',
  },
  {
    code: '130728',
    name: '怀安县',
    cityCode: '1307',
    enName: 'Huaian',
  },
  {
    code: '130730',
    name: '怀来县',
    cityCode: '1307',
    enName: 'Huailai',
  },
  {
    code: '130731',
    name: '涿鹿县',
    cityCode: '1307',
    enName: 'Zhuolu',
  },
  {
    code: '130732',
    name: '赤城县',
    cityCode: '1307',
    enName: 'Chicheng',
  },
  {
    code: '130802',
    name: '双桥区',
    cityCode: '1308',
    enName: 'Shuangqiao',
  },
  {
    code: '130803',
    name: '双滦区',
    cityCode: '1308',
    enName: 'Shuangluan',
  },
  {
    code: '130804',
    name: '鹰手营子矿区',
    cityCode: '1308',
    enName: 'Yingshouyingzikuang Mineral Area',
  },
  {
    code: '130821',
    name: '承德县',
    cityCode: '1308',
    enName: 'Chengde',
  },
  {
    code: '130822',
    name: '兴隆县',
    cityCode: '1308',
    enName: 'Xinglong',
  },
  {
    code: '130823',
    name: '平泉县',
    cityCode: '1308',
    enName: 'Pingan',
  },
  {
    code: '130824',
    name: '滦平县',
    cityCode: '1308',
    enName: 'Luanping',
  },
  {
    code: '130825',
    name: '隆化县',
    cityCode: '1308',
    enName: 'Longhua',
  },
  {
    code: '130826',
    name: '丰宁满族自治县',
    cityCode: '1308',
    enName: 'Fengning Manchu Autonomous County',
  },
  {
    code: '130827',
    name: '宽城满族自治县',
    cityCode: '1308',
    enName: 'Kuancheng Manchu Autonomous County',
  },
  {
    code: '130828',
    name: '围场满族蒙古族自治县',
    cityCode: '1308',
    enName: 'Weichang Mongolian Autonomous County',
  },
  {
    code: '130902',
    name: '新华区',
    cityCode: '1309',
    enName: 'Xinhua',
  },
  { code: '130903', name: '运河区', cityCode: '1309', enName: 'Yunhe' },
  { code: '130921', name: '沧县', cityCode: '1309', enName: 'Cang' },
  { code: '130922', name: '青县', cityCode: '1309', enName: 'Qing' },
  {
    code: '130923',
    name: '东光县',
    cityCode: '1309',
    enName: 'Dongguang',
  },
  {
    code: '130924',
    name: '海兴县',
    cityCode: '1309',
    enName: 'Haixing',
  },
  {
    code: '130925',
    name: '盐山县',
    cityCode: '1309',
    enName: 'Yanshan',
  },
  {
    code: '130926',
    name: '肃宁县',
    cityCode: '1309',
    enName: 'Suning',
  },
  { code: '130927', name: '南皮县', cityCode: '1309', enName: 'Nanpi' },
  {
    code: '130928',
    name: '吴桥县',
    cityCode: '1309',
    enName: 'Wuqiao',
  },
  { code: '130929', name: '献县', cityCode: '1309', enName: 'Xian' },
  {
    code: '130930',
    name: '孟村回族自治县',
    cityCode: '1309',
    enName: 'Mengcun Manchu Autonomous County',
  },
  { code: '130981', name: '泊头市', cityCode: '1309', enName: 'Botou' },
  {
    code: '130982',
    name: '任丘市',
    cityCode: '1309',
    enName: 'Renqiu',
  },
  {
    code: '130983',
    name: '黄骅市',
    cityCode: '1309',
    enName: 'Huanghua',
  },
  {
    code: '130984',
    name: '河间市',
    cityCode: '1309',
    enName: 'Hejian',
  },
  { code: '131002', name: '安次区', cityCode: '1310', enName: 'Anci' },
  {
    code: '131003',
    name: '广阳区',
    cityCode: '1310',
    enName: 'Guangyang',
  },
  { code: '131022', name: '固安县', cityCode: '1310', enName: 'Guan' },
  {
    code: '131023',
    name: '永清县',
    cityCode: '1310',
    enName: 'Yongqing',
  },
  {
    code: '131024',
    name: '香河县',
    cityCode: '1310',
    enName: 'Xianghe',
  },
  {
    code: '131025',
    name: '大城县',
    cityCode: '1310',
    enName: 'Dacheng',
  },
  { code: '131026', name: '文安县', cityCode: '1310', enName: 'Wenan' },
  {
    code: '131028',
    name: '大厂回族自治县',
    cityCode: '1310',
    enName: 'Dachang Manchu Autonomous County',
  },
  {
    code: '131081',
    name: '霸州市',
    cityCode: '1310',
    enName: 'Bazhou',
  },
  { code: '131082', name: '三河市', cityCode: '1310', enName: 'Sanhe' },
  {
    code: '131102',
    name: '桃城区',
    cityCode: '1311',
    enName: 'Taocheng',
  },
  {
    code: '131103',
    name: '冀州区',
    cityCode: '1311',
    enName: 'Jizhou',
  },
  {
    code: '131121',
    name: '枣强县',
    cityCode: '1311',
    enName: 'Zaoqiang',
  },
  { code: '131122', name: '武邑县', cityCode: '1311', enName: 'Wuyi' },
  {
    code: '131123',
    name: '武强县',
    cityCode: '1311',
    enName: 'Wuqiang',
  },
  {
    code: '131124',
    name: '饶阳县',
    cityCode: '1311',
    enName: 'Raoyang',
  },
  {
    code: '131125',
    name: '安平县',
    cityCode: '1311',
    enName: 'Anping',
  },
  {
    code: '131126',
    name: '故城县',
    cityCode: '1311',
    enName: 'Gucheng',
  },
  { code: '131127', name: '景县', cityCode: '1311', enName: 'Jing' },
  {
    code: '131128',
    name: '阜城县',
    cityCode: '1311',
    enName: 'Fucheng',
  },
  {
    code: '131182',
    name: '深州市',
    cityCode: '1311',
    enName: 'Shenzhou',
  },
  {
    code: '139001',
    name: '定州市',
    cityCode: '1390',
    enName: 'Dingzhou',
  },
  { code: '139002', name: '辛集市', cityCode: '1390', enName: 'Xinji' },
  {
    code: '140105',
    name: '小店区',
    cityCode: '1401',
    enName: 'Xiaodian',
  },
  {
    code: '140106',
    name: '迎泽区',
    cityCode: '1401',
    enName: 'Yingze',
  },
  {
    code: '140107',
    name: '杏花岭区',
    cityCode: '1401',
    enName: 'Xinghualing',
  },
  {
    code: '140108',
    name: '尖草坪区',
    cityCode: '1401',
    enName: 'Jiancaoping',
  },
  {
    code: '140109',
    name: '万柏林区',
    cityCode: '1401',
    enName: 'Wanbolin',
  },
  {
    code: '140110',
    name: '晋源区',
    cityCode: '1401',
    enName: 'Jinyuan',
  },
  {
    code: '140121',
    name: '清徐县',
    cityCode: '1401',
    enName: 'Qingxu',
  },
  {
    code: '140122',
    name: '阳曲县',
    cityCode: '1401',
    enName: 'Yangqu',
  },
  {
    code: '140123',
    name: '娄烦县',
    cityCode: '1401',
    enName: 'Loufan',
  },
  {
    code: '140181',
    name: '古交市',
    cityCode: '1401',
    enName: 'Gujiao',
  },
  {
    code: '140202',
    name: '城区',
    cityCode: '1402',
    enName: 'Urban District',
  },
  {
    code: '140203',
    name: '矿区',
    cityCode: '1402',
    enName: 'Mineral District',
  },
  {
    code: '140211',
    name: '南郊区',
    cityCode: '1402',
    enName: 'Nanjiao',
  },
  {
    code: '140212',
    name: '新荣区',
    cityCode: '1402',
    enName: 'Xinrong',
  },
  {
    code: '140221',
    name: '阳高县',
    cityCode: '1402',
    enName: 'Yanggao',
  },
  {
    code: '140222',
    name: '天镇县',
    cityCode: '1402',
    enName: 'Tianzhen',
  },
  {
    code: '140223',
    name: '广灵县',
    cityCode: '1402',
    enName: 'Guangling',
  },
  {
    code: '140224',
    name: '灵丘县',
    cityCode: '1402',
    enName: 'Lingqiu',
  },
  {
    code: '140225',
    name: '浑源县',
    cityCode: '1402',
    enName: 'Hunyuan',
  },
  {
    code: '140226',
    name: '左云县',
    cityCode: '1402',
    enName: 'Zuoyun',
  },
  {
    code: '140227',
    name: '大同县',
    cityCode: '1402',
    enName: 'Datong',
  },
  {
    code: '140302',
    name: '城区',
    cityCode: '1403',
    enName: 'Urban District',
  },
  {
    code: '140303',
    name: '矿区',
    cityCode: '1403',
    enName: 'Mineral District',
  },
  { code: '140311', name: '郊区', cityCode: '1403', enName: 'Suburbs' },
  {
    code: '140321',
    name: '平定县',
    cityCode: '1403',
    enName: 'Pingding',
  },
  { code: '140322', name: '盂县', cityCode: '1403', enName: 'Yu' },
  {
    code: '140402',
    name: '城区',
    cityCode: '1404',
    enName: 'Urban District',
  },
  { code: '140411', name: '郊区', cityCode: '1404', enName: 'Suburbs' },
  {
    code: '140421',
    name: '长治县',
    cityCode: '1404',
    enName: 'Changzhi',
  },
  {
    code: '140423',
    name: '襄垣县',
    cityCode: '1404',
    enName: 'Xiangyuan',
  },
  {
    code: '140424',
    name: '屯留县',
    cityCode: '1404',
    enName: 'Tunliu',
  },
  {
    code: '140425',
    name: '平顺县',
    cityCode: '1404',
    enName: 'Pingshun',
  },
  {
    code: '140426',
    name: '黎城县',
    cityCode: '1404',
    enName: 'Licheng',
  },
  {
    code: '140427',
    name: '壶关县',
    cityCode: '1404',
    enName: 'Huguan',
  },
  {
    code: '140428',
    name: '长子县',
    cityCode: '1404',
    enName: 'Zhangzi',
  },
  {
    code: '140429',
    name: '武乡县',
    cityCode: '1404',
    enName: 'Wuxiang',
  },
  { code: '140430', name: '沁县', cityCode: '1404', enName: 'Qin' },
  {
    code: '140431',
    name: '沁源县',
    cityCode: '1404',
    enName: 'Qinyuan',
  },
  {
    code: '140481',
    name: '潞城市',
    cityCode: '1404',
    enName: 'Lucheng',
  },
  {
    code: '140502',
    name: '城区',
    cityCode: '1405',
    enName: 'Urban District',
  },
  {
    code: '140521',
    name: '沁水县',
    cityCode: '1405',
    enName: 'Qinshui',
  },
  {
    code: '140522',
    name: '阳城县',
    cityCode: '1405',
    enName: 'Yangcheng',
  },
  {
    code: '140524',
    name: '陵川县',
    cityCode: '1405',
    enName: 'Lingchuan',
  },
  {
    code: '140525',
    name: '泽州县',
    cityCode: '1405',
    enName: 'Zezhou',
  },
  {
    code: '140581',
    name: '高平市',
    cityCode: '1405',
    enName: 'Gaoping',
  },
  {
    code: '140602',
    name: '朔城区',
    cityCode: '1406',
    enName: 'Shuocheng',
  },
  {
    code: '140603',
    name: '平鲁区',
    cityCode: '1406',
    enName: 'Pinglu',
  },
  {
    code: '140621',
    name: '山阴县',
    cityCode: '1406',
    enName: 'Shanyin',
  },
  { code: '140622', name: '应县', cityCode: '1406', enName: 'Ying' },
  { code: '140623', name: '右玉县', cityCode: '1406', enName: 'Youyu' },
  {
    code: '140624',
    name: '怀仁县',
    cityCode: '1406',
    enName: 'Huairen',
  },
  { code: '140702', name: '榆次区', cityCode: '1407', enName: 'Yuci' },
  { code: '140721', name: '榆社县', cityCode: '1407', enName: 'Yushe' },
  { code: '140722', name: '左权县', cityCode: '1407', enName: 'Zuoan' },
  {
    code: '140723',
    name: '和顺县',
    cityCode: '1407',
    enName: 'Heshun',
  },
  {
    code: '140724',
    name: '昔阳县',
    cityCode: '1407',
    enName: 'Xiyang',
  },
  {
    code: '140725',
    name: '寿阳县',
    cityCode: '1407',
    enName: 'Shouyang',
  },
  { code: '140726', name: '太谷县', cityCode: '1407', enName: 'Taigu' },
  { code: '140727', name: '祁县', cityCode: '1407', enName: 'Qi' },
  {
    code: '140728',
    name: '平遥县',
    cityCode: '1407',
    enName: 'Pingyao',
  },
  {
    code: '140729',
    name: '灵石县',
    cityCode: '1407',
    enName: 'Lingshi',
  },
  {
    code: '140781',
    name: '介休市',
    cityCode: '1407',
    enName: 'Jiexiu',
  },
  { code: '140802', name: '盐湖区', cityCode: '1408', enName: 'Yanhu' },
  { code: '140821', name: '临猗县', cityCode: '1408', enName: 'Linyi' },
  {
    code: '140822',
    name: '万荣县',
    cityCode: '1408',
    enName: 'Wanrong',
  },
  { code: '140823', name: '闻喜县', cityCode: '1408', enName: 'Wenxi' },
  {
    code: '140824',
    name: '稷山县',
    cityCode: '1408',
    enName: 'Jishan',
  },
  {
    code: '140825',
    name: '新绛县',
    cityCode: '1408',
    enName: 'Xinjiang',
  },
  { code: '140826', name: '绛县', cityCode: '1408', enName: 'Jiang' },
  { code: '140827', name: '垣曲县', cityCode: '1408', enName: 'Yuan' },
  { code: '140828', name: '夏县', cityCode: '1408', enName: 'Xia' },
  {
    code: '140829',
    name: '平陆县',
    cityCode: '1408',
    enName: 'Pinglu',
  },
  {
    code: '140830',
    name: '芮城县',
    cityCode: '1408',
    enName: 'Ruicheng',
  },
  {
    code: '140881',
    name: '永济市',
    cityCode: '1408',
    enName: 'Yongji',
  },
  { code: '140882', name: '河津市', cityCode: '1408', enName: 'Hejin' },
  { code: '140902', name: '忻府区', cityCode: '1409', enName: 'Xinfu' },
  {
    code: '140921',
    name: '定襄县',
    cityCode: '1409',
    enName: 'Dinggxian',
  },
  { code: '140922', name: '五台县', cityCode: '1409', enName: 'Wutai' },
  { code: '140923', name: '代县', cityCode: '1409', enName: 'Dai' },
  {
    code: '140924',
    name: '繁峙县',
    cityCode: '1409',
    enName: 'Fanzhi',
  },
  {
    code: '140925',
    name: '宁武县',
    cityCode: '1409',
    enName: 'Ningwu',
  },
  {
    code: '140926',
    name: '静乐县',
    cityCode: '1409',
    enName: 'Jingle',
  },
  {
    code: '140927',
    name: '神池县',
    cityCode: '1409',
    enName: 'Shenchi',
  },
  {
    code: '140928',
    name: '五寨县',
    cityCode: '1409',
    enName: 'Wuzhai',
  },
  { code: '140929', name: '岢岚县', cityCode: '1409', enName: 'Kelan' },
  { code: '140930', name: '河曲县', cityCode: '1409', enName: 'He' },
  { code: '140931', name: '保德县', cityCode: '1409', enName: 'Baode' },
  {
    code: '140932',
    name: '偏关县',
    cityCode: '1409',
    enName: 'Pianguan',
  },
  {
    code: '140981',
    name: '原平市',
    cityCode: '1409',
    enName: 'Yuanping',
  },
  {
    code: '141002',
    name: '尧都区',
    cityCode: '1410',
    enName: 'Yaodou',
  },
  { code: '141021', name: '曲沃县', cityCode: '1410', enName: 'Quwo' },
  {
    code: '141022',
    name: '翼城县',
    cityCode: '1410',
    enName: 'Yicheng',
  },
  {
    code: '141023',
    name: '襄汾县',
    cityCode: '1410',
    enName: 'Xiangfen',
  },
  {
    code: '141024',
    name: '洪洞县',
    cityCode: '1410',
    enName: 'Hongdong',
  },
  { code: '141025', name: '古县', cityCode: '1410', enName: 'Gu' },
  { code: '141026', name: '安泽县', cityCode: '1410', enName: 'Anze' },
  {
    code: '141027',
    name: '浮山县',
    cityCode: '1410',
    enName: 'Fushan',
  },
  { code: '141028', name: '吉县', cityCode: '1410', enName: 'Ji' },
  {
    code: '141029',
    name: '乡宁县',
    cityCode: '1410',
    enName: 'Xiangning',
  },
  {
    code: '141030',
    name: '大宁县',
    cityCode: '1410',
    enName: 'Daning',
  },
  { code: '141031', name: '隰县', cityCode: '1410', enName: 'Xi' },
  {
    code: '141032',
    name: '永和县',
    cityCode: '1410',
    enName: 'Yonghe',
  },
  { code: '141033', name: '蒲县', cityCode: '1410', enName: 'Pu' },
  { code: '141034', name: '汾西县', cityCode: '1410', enName: 'Fenxi' },
  { code: '141081', name: '侯马市', cityCode: '1410', enName: 'Houma' },
  {
    code: '141082',
    name: '霍州市',
    cityCode: '1410',
    enName: 'Huozhou',
  },
  { code: '141102', name: '离石区', cityCode: '1411', enName: 'Lishi' },
  {
    code: '141121',
    name: '文水县',
    cityCode: '1411',
    enName: 'Wenshui',
  },
  {
    code: '141122',
    name: '交城县',
    cityCode: '1411',
    enName: 'Jiaocheng',
  },
  { code: '141123', name: '兴县', cityCode: '1411', enName: 'Xing' },
  { code: '141124', name: '临县', cityCode: '1411', enName: 'Lin' },
  {
    code: '141125',
    name: '柳林县',
    cityCode: '1411',
    enName: 'Liulin',
  },
  {
    code: '141126',
    name: '石楼县',
    cityCode: '1411',
    enName: 'Shilou',
  },
  { code: '141127', name: '岚县', cityCode: '1411', enName: 'Lan' },
  {
    code: '141128',
    name: '方山县',
    cityCode: '1411',
    enName: 'Fangshan',
  },
  {
    code: '141129',
    name: '中阳县',
    cityCode: '1411',
    enName: 'Zhongyang',
  },
  {
    code: '141130',
    name: '交口县',
    cityCode: '1411',
    enName: 'Jiaokou',
  },
  {
    code: '141181',
    name: '孝义市',
    cityCode: '1411',
    enName: 'Xiaoyi',
  },
  {
    code: '141182',
    name: '汾阳市',
    cityCode: '1411',
    enName: 'Fenyang',
  },
  {
    code: '150102',
    name: '新城区',
    cityCode: '1501',
    enName: 'Xincheng',
  },
  {
    code: '150103',
    name: '回民区',
    cityCode: '1501',
    enName: 'Huimin',
  },
  {
    code: '150104',
    name: '玉泉区',
    cityCode: '1501',
    enName: 'Yuquan',
  },
  {
    code: '150105',
    name: '赛罕区',
    cityCode: '1501',
    enName: 'Saihan',
  },
  {
    code: '150121',
    name: '土默特左旗',
    cityCode: '1501',
    enName: 'Tumd East Banner',
  },
  {
    code: '150122',
    name: '托克托县',
    cityCode: '1501',
    enName: 'Tuoketuo',
  },
  {
    code: '150123',
    name: '和林格尔县',
    cityCode: '1501',
    enName: 'Helingeer',
  },
  {
    code: '150124',
    name: '清水河县',
    cityCode: '1501',
    enName: 'Qingshuihe',
  },
  {
    code: '150125',
    name: '武川县',
    cityCode: '1501',
    enName: 'Wuchuan',
  },
  {
    code: '150202',
    name: '东河区',
    cityCode: '1502',
    enName: 'Donghe',
  },
  {
    code: '150203',
    name: '昆都仑区',
    cityCode: '1502',
    enName: 'Kundoulun',
  },
  {
    code: '150204',
    name: '青山区',
    cityCode: '1502',
    enName: 'Qingshan',
  },
  {
    code: '150205',
    name: '石拐区',
    cityCode: '1502',
    enName: 'Shiguai',
  },
  {
    code: '150206',
    name: '白云鄂博矿区',
    cityCode: '1502',
    enName: 'Baiyunebo Mineral District',
  },
  {
    code: '150207',
    name: '九原区',
    cityCode: '1502',
    enName: 'Jiuyuan',
  },
  {
    code: '150221',
    name: '土默特右旗',
    cityCode: '1502',
    enName: 'Tumoteyou Banner',
  },
  {
    code: '150222',
    name: '固阳县',
    cityCode: '1502',
    enName: 'Guyang',
  },
  {
    code: '150223',
    name: '达尔罕茂明安联合旗',
    cityCode: '1502',
    enName: 'Darhan-Muminggan Joint County',
  },
  {
    code: '150302',
    name: '海勃湾区',
    cityCode: '1503',
    enName: 'Haibowan',
  },
  {
    code: '150303',
    name: '海南区',
    cityCode: '1503',
    enName: 'Hainan',
  },
  { code: '150304', name: '乌达区', cityCode: '1503', enName: 'Wuda' },
  {
    code: '150402',
    name: '红山区',
    cityCode: '1504',
    enName: 'Hongshan',
  },
  {
    code: '150403',
    name: '元宝山区',
    cityCode: '1504',
    enName: 'Yuanbaoshan',
  },
  {
    code: '150404',
    name: '松山区',
    cityCode: '1504',
    enName: 'Songshan',
  },
  {
    code: '150421',
    name: '阿鲁科尔沁旗',
    cityCode: '1504',
    enName: 'Ar Horqin Banner',
  },
  {
    code: '150422',
    name: '巴林左旗',
    cityCode: '1504',
    enName: 'Balinzuo Banner',
  },
  {
    code: '150423',
    name: '巴林右旗',
    cityCode: '1504',
    enName: 'Balinyou Banner',
  },
  { code: '150424', name: '林西县', cityCode: '1504', enName: 'Linxi' },
  {
    code: '150425',
    name: '克什克腾旗',
    cityCode: '1504',
    enName: 'Keshiketeng Banner',
  },
  {
    code: '150426',
    name: '翁牛特旗',
    cityCode: '1504',
    enName: 'Wengniute Banner',
  },
  {
    code: '150428',
    name: '喀喇沁旗',
    cityCode: '1504',
    enName: 'Kalaqin Banner',
  },
  {
    code: '150429',
    name: '宁城县',
    cityCode: '1504',
    enName: 'Ningcheng',
  },
  {
    code: '150430',
    name: '敖汉旗',
    cityCode: '1504',
    enName: 'Aohan Banner',
  },
  {
    code: '150502',
    name: '科尔沁区',
    cityCode: '1505',
    enName: 'Keerqin',
  },
  {
    code: '150521',
    name: '科尔沁左翼中旗',
    cityCode: '1505',
    enName: 'Horqin East Middle County',
  },
  {
    code: '150522',
    name: '科尔沁左翼后旗',
    cityCode: '1505',
    enName: 'Horqin Northeast County',
  },
  { code: '150523', name: '开鲁县', cityCode: '1505', enName: 'Kailu' },
  {
    code: '150524',
    name: '库伦旗',
    cityCode: '1505',
    enName: 'Kulunqi',
  },
  {
    code: '150525',
    name: '奈曼旗',
    cityCode: '1505',
    enName: 'Naiman Banner',
  },
  {
    code: '150526',
    name: '扎鲁特旗',
    cityCode: '1505',
    enName: 'Jarud Banner',
  },
  {
    code: '150581',
    name: '霍林郭勒市',
    cityCode: '1505',
    enName: 'Huolinguole',
  },
  {
    code: '150602',
    name: '东胜区',
    cityCode: '1506',
    enName: 'Dongsheng',
  },
  {
    code: '150603',
    name: '康巴什区',
    cityCode: '1506',
    enName: 'Kangbashi',
  },
  {
    code: '150621',
    name: '达拉特旗',
    cityCode: '1506',
    enName: 'Dalad Banner',
  },
  {
    code: '150622',
    name: '准格尔旗',
    cityCode: '1506',
    enName: 'Jungar Banner',
  },
  {
    code: '150623',
    name: '鄂托克前旗',
    cityCode: '1506',
    enName: 'Otog Front Banner',
  },
  {
    code: '150624',
    name: '鄂托克旗',
    cityCode: '1506',
    enName: 'Otog Banner',
  },
  {
    code: '150625',
    name: '杭锦旗',
    cityCode: '1506',
    enName: 'Hangjin Banner',
  },
  {
    code: '150626',
    name: '乌审旗',
    cityCode: '1506',
    enName: 'Uxin Banner',
  },
  {
    code: '150627',
    name: '伊金霍洛旗',
    cityCode: '1506',
    enName: 'Ejin Horo Banner',
  },
  {
    code: '150702',
    name: '海拉尔区',
    cityCode: '1507',
    enName: 'Hailaer',
  },
  {
    code: '150703',
    name: '扎赉诺尔区',
    cityCode: '1507',
    enName: 'Zhalainuoer',
  },
  {
    code: '150721',
    name: '阿荣旗',
    cityCode: '1507',
    enName: 'Arun Banner',
  },
  {
    code: '150722',
    name: '莫力达瓦达斡尔族自治旗',
    cityCode: '1507',
    enName: 'Daur Autonomous Banner of Morin Dawa',
  },
  {
    code: '150723',
    name: '鄂伦春自治旗',
    cityCode: '1507',
    enName: 'Oroqen Autonomous Banner',
  },
  {
    code: '150724',
    name: '鄂温克族自治旗',
    cityCode: '1507',
    enName: 'Ewenki Autonomous Banner',
  },
  {
    code: '150725',
    name: '陈巴尔虎旗',
    cityCode: '1507',
    enName: 'Prairie Chenbarhu banner',
  },
  {
    code: '150726',
    name: '新巴尔虎左旗',
    cityCode: '1507',
    enName: 'New Barag East County',
  },
  {
    code: '150727',
    name: '新巴尔虎右旗',
    cityCode: '1507',
    enName: 'New Barag West County',
  },
  {
    code: '150781',
    name: '满洲里市',
    cityCode: '1507',
    enName: 'Manzhouli',
  },
  {
    code: '150782',
    name: '牙克石市',
    cityCode: '1507',
    enName: 'Yake',
  },
  {
    code: '150783',
    name: '扎兰屯市',
    cityCode: '1507',
    enName: 'Zhalantun',
  },
  {
    code: '150784',
    name: '额尔古纳市',
    cityCode: '1507',
    enName: 'Eerguna',
  },
  { code: '150785', name: '根河市', cityCode: '1507', enName: 'Genhe' },
  { code: '150802', name: '临河区', cityCode: '1508', enName: 'Linhe' },
  {
    code: '150821',
    name: '五原县',
    cityCode: '1508',
    enName: 'Wuyuan',
  },
  {
    code: '150822',
    name: '磴口县',
    cityCode: '1508',
    enName: 'Dengkou',
  },
  {
    code: '150823',
    name: '乌拉特前旗',
    cityCode: '1508',
    enName: 'Urad Front Banner',
  },
  {
    code: '150824',
    name: '乌拉特中旗',
    cityCode: '1508',
    enName: 'Urad Middle Banner',
  },
  {
    code: '150825',
    name: '乌拉特后旗',
    cityCode: '1508',
    enName: 'Urat Rear Banner',
  },
  {
    code: '150826',
    name: '杭锦后旗',
    cityCode: '1508',
    enName: 'Hangjinhou Banner',
  },
  {
    code: '150902',
    name: '集宁区',
    cityCode: '1509',
    enName: 'Jining',
  },
  {
    code: '150921',
    name: '卓资县',
    cityCode: '1509',
    enName: 'Zhuozi',
  },
  { code: '150922', name: '化德县', cityCode: '1509', enName: 'Huade' },
  {
    code: '150923',
    name: '商都县',
    cityCode: '1509',
    enName: 'Shangdou',
  },
  {
    code: '150924',
    name: '兴和县',
    cityCode: '1509',
    enName: 'Xinghe',
  },
  {
    code: '150925',
    name: '凉城县',
    cityCode: '1509',
    enName: 'Liangcheng',
  },
  {
    code: '150926',
    name: '察哈尔右翼前旗',
    cityCode: '1509',
    enName: 'Chahar Right Front Banner',
  },
  {
    code: '150927',
    name: '察哈尔右翼中旗',
    cityCode: '1509',
    enName: 'Chahar Right Middle Banner',
  },
  {
    code: '150928',
    name: '察哈尔右翼后旗',
    cityCode: '1509',
    enName: 'Chahar Right Back Banner',
  },
  {
    code: '150929',
    name: '四子王旗',
    cityCode: '1509',
    enName: 'Siziwang Banner',
  },
  {
    code: '150981',
    name: '丰镇市',
    cityCode: '1509',
    enName: 'Fengzhen',
  },
  {
    code: '152201',
    name: '乌兰浩特市',
    cityCode: '1522',
    enName: 'Wulanhaote',
  },
  {
    code: '152202',
    name: '阿尔山市',
    cityCode: '1522',
    enName: 'Aershan',
  },
  {
    code: '152221',
    name: '科尔沁右翼前旗',
    cityCode: '1522',
    enName: 'Horqin Right Wing Front Banner',
  },
  {
    code: '152222',
    name: '科尔沁右翼中旗',
    cityCode: '1522',
    enName: 'Horqin Right Wing Middle Banner',
  },
  {
    code: '152223',
    name: '扎赉特旗',
    cityCode: '1522',
    enName: 'Jalaid Banner',
  },
  {
    code: '152224',
    name: '突泉县',
    cityCode: '1522',
    enName: 'Tuquan',
  },
  {
    code: '152501',
    name: '二连浩特市',
    cityCode: '1525',
    enName: 'Erlianhaote',
  },
  {
    code: '152502',
    name: '锡林浩特市',
    cityCode: '1525',
    enName: 'Xilinhaote',
  },
  {
    code: '152522',
    name: '阿巴嘎旗',
    cityCode: '1525',
    enName: 'Abaga Banner',
  },
  {
    code: '152523',
    name: '苏尼特左旗',
    cityCode: '1525',
    enName: 'Sonid Left Banner',
  },
  {
    code: '152524',
    name: '苏尼特右旗',
    cityCode: '1525',
    enName: 'Sonid Right Banner',
  },
  {
    code: '152525',
    name: '东乌珠穆沁旗',
    cityCode: '1525',
    enName: 'East Ujimqin Banner',
  },
  {
    code: '152526',
    name: '西乌珠穆沁旗',
    cityCode: '1525',
    enName: 'West Ujimqin Banner',
  },
  {
    code: '152527',
    name: '太仆寺旗',
    cityCode: '1525',
    enName: 'Taibus Banner',
  },
  {
    code: '152528',
    name: '镶黄旗',
    cityCode: '1525',
    enName: 'Xianghuang Banner',
  },
  {
    code: '152529',
    name: '正镶白旗',
    cityCode: '1525',
    enName: 'Zhenglan Banner',
  },
  {
    code: '152530',
    name: '正蓝旗',
    cityCode: '1525',
    enName: 'Zhenglanqi',
  },
  {
    code: '152531',
    name: '多伦县',
    cityCode: '1525',
    enName: 'Duolun',
  },
  {
    code: '152921',
    name: '阿拉善左旗',
    cityCode: '1529',
    enName: 'Alxa East County',
  },
  {
    code: '152922',
    name: '阿拉善右旗',
    cityCode: '1529',
    enName: 'Alxa West County',
  },
  {
    code: '152923',
    name: '额济纳旗',
    cityCode: '1529',
    enName: 'Ejina Banner',
  },
  {
    code: '210102',
    name: '和平区',
    cityCode: '2101',
    enName: 'Heping',
  },
  {
    code: '210103',
    name: '沈河区',
    cityCode: '2101',
    enName: 'Shenhe',
  },
  {
    code: '210104',
    name: '大东区',
    cityCode: '2101',
    enName: 'Dadong',
  },
  {
    code: '210105',
    name: '皇姑区',
    cityCode: '2101',
    enName: 'Huanggu',
  },
  { code: '210106', name: '铁西区', cityCode: '2101', enName: 'Tiexi' },
  {
    code: '210111',
    name: '苏家屯区',
    cityCode: '2101',
    enName: 'Sujiatun',
  },
  {
    code: '210112',
    name: '浑南区',
    cityCode: '2101',
    enName: 'Hunnan',
  },
  {
    code: '210113',
    name: '沈北新区',
    cityCode: '2101',
    enName: 'Shenbeixin',
  },
  {
    code: '210114',
    name: '于洪区',
    cityCode: '2101',
    enName: 'Yuhong',
  },
  {
    code: '210115',
    name: '辽中区',
    cityCode: '2101',
    enName: 'Liaozhong',
  },
  {
    code: '210123',
    name: '康平县',
    cityCode: '2101',
    enName: 'Kangping',
  },
  { code: '210124', name: '法库县', cityCode: '2101', enName: 'Faku' },
  {
    code: '210181',
    name: '新民市',
    cityCode: '2101',
    enName: 'Xinmin',
  },
  {
    code: '210202',
    name: '中山区',
    cityCode: '2102',
    enName: 'Zhongshan',
  },
  {
    code: '210203',
    name: '西岗区',
    cityCode: '2102',
    enName: 'Xigang',
  },
  {
    code: '210204',
    name: '沙河口区',
    cityCode: '2102',
    enName: 'Shahekou',
  },
  {
    code: '210211',
    name: '甘井子区',
    cityCode: '2102',
    enName: 'Ganjingzi',
  },
  {
    code: '210212',
    name: '旅顺口区',
    cityCode: '2102',
    enName: 'Lüshunkou',
  },
  {
    code: '210213',
    name: '金州区',
    cityCode: '2102',
    enName: 'Jinzhou',
  },
  {
    code: '210214',
    name: '普兰店区',
    cityCode: '2102',
    enName: 'Pulandian',
  },
  {
    code: '210224',
    name: '长海县',
    cityCode: '2102',
    enName: 'Changhai',
  },
  {
    code: '210281',
    name: '瓦房店市',
    cityCode: '2102',
    enName: 'Wafangdian',
  },
  {
    code: '210283',
    name: '庄河市',
    cityCode: '2102',
    enName: 'Zhuanghe',
  },
  {
    code: '210302',
    name: '铁东区',
    cityCode: '2103',
    enName: 'Tiedong',
  },
  { code: '210303', name: '铁西区', cityCode: '2103', enName: 'Tiexi' },
  {
    code: '210304',
    name: '立山区',
    cityCode: '2103',
    enName: 'Lishan',
  },
  {
    code: '210311',
    name: '千山区',
    cityCode: '2103',
    enName: 'Qianshan',
  },
  { code: '210321', name: '台安县', cityCode: '2103', enName: 'Taian' },
  {
    code: '210323',
    name: '岫岩满族自治县',
    cityCode: '2103',
    enName: 'Xiuyan Manchu Autonomous County',
  },
  {
    code: '210381',
    name: '海城市',
    cityCode: '2103',
    enName: 'Haicheng',
  },
  { code: '210402', name: '新抚区', cityCode: '2104', enName: 'Xinfu' },
  {
    code: '210403',
    name: '东洲区',
    cityCode: '2104',
    enName: 'Dongzhou',
  },
  {
    code: '210404',
    name: '望花区',
    cityCode: '2104',
    enName: 'Wanghua',
  },
  {
    code: '210411',
    name: '顺城区',
    cityCode: '2104',
    enName: 'Shuncheng',
  },
  {
    code: '210421',
    name: '抚顺县',
    cityCode: '2104',
    enName: 'Fushun',
  },
  {
    code: '210422',
    name: '新宾满族自治县',
    cityCode: '2104',
    enName: 'Xinbin Manchu Autonomous County',
  },
  {
    code: '210423',
    name: '清原满族自治县',
    cityCode: '2104',
    enName: 'Qingyuan Manchu Autonomous County',
  },
  {
    code: '210502',
    name: '平山区',
    cityCode: '2105',
    enName: 'Pingshan',
  },
  { code: '210503', name: '溪湖区', cityCode: '2105', enName: 'Xihu' },
  {
    code: '210504',
    name: '明山区',
    cityCode: '2105',
    enName: 'Mingshan',
  },
  {
    code: '210505',
    name: '南芬区',
    cityCode: '2105',
    enName: 'Nanfen',
  },
  {
    code: '210521',
    name: '本溪满族自治县',
    cityCode: '2105',
    enName: 'Benxi Manchu Autonomous County',
  },
  {
    code: '210522',
    name: '桓仁满族自治县',
    cityCode: '2105',
    enName: 'Huanren Manchu Autonomous County',
  },
  {
    code: '210602',
    name: '元宝区',
    cityCode: '2106',
    enName: 'Yuanbao',
  },
  {
    code: '210603',
    name: '振兴区',
    cityCode: '2106',
    enName: 'Zhenxing',
  },
  {
    code: '210604',
    name: '振安区',
    cityCode: '2106',
    enName: 'Zhenan',
  },
  {
    code: '210624',
    name: '宽甸满族自治县',
    cityCode: '2106',
    enName: 'Kuandian Manchu Autonomous County',
  },
  {
    code: '210681',
    name: '东港市',
    cityCode: '2106',
    enName: 'Donggang',
  },
  {
    code: '210682',
    name: '凤城市',
    cityCode: '2106',
    enName: 'Fengcheng',
  },
  { code: '210702', name: '古塔区', cityCode: '2107', enName: 'Guta' },
  {
    code: '210703',
    name: '凌河区',
    cityCode: '2107',
    enName: 'Linghe',
  },
  { code: '210711', name: '太和区', cityCode: '2107', enName: 'Taihe' },
  {
    code: '210726',
    name: '黑山县',
    cityCode: '2107',
    enName: 'Heishan',
  },
  { code: '210727', name: '义县', cityCode: '2107', enName: 'Yi' },
  {
    code: '210781',
    name: '凌海市',
    cityCode: '2107',
    enName: 'Linghai',
  },
  {
    code: '210782',
    name: '北镇市',
    cityCode: '2107',
    enName: 'Beizhen',
  },
  {
    code: '210802',
    name: '站前区',
    cityCode: '2108',
    enName: 'Zhanqian',
  },
  { code: '210803', name: '西市区', cityCode: '2108', enName: 'Xishi' },
  {
    code: '210804',
    name: '鲅鱼圈区',
    cityCode: '2108',
    enName: 'Bayuanqu',
  },
  {
    code: '210811',
    name: '老边区',
    cityCode: '2108',
    enName: 'Laobian',
  },
  {
    code: '210881',
    name: '盖州市',
    cityCode: '2108',
    enName: 'Gaizhou',
  },
  {
    code: '210882',
    name: '大石桥市',
    cityCode: '2108',
    enName: 'Dashiqiao',
  },
  {
    code: '210902',
    name: '海州区',
    cityCode: '2109',
    enName: 'Haizhou',
  },
  {
    code: '210903',
    name: '新邱区',
    cityCode: '2109',
    enName: 'Xinqiu',
  },
  {
    code: '210904',
    name: '太平区',
    cityCode: '2109',
    enName: 'Taiping',
  },
  {
    code: '210905',
    name: '清河门区',
    cityCode: '2109',
    enName: 'Qinghemen',
  },
  { code: '210911', name: '细河区', cityCode: '2109', enName: 'Xihe' },
  {
    code: '210921',
    name: '阜新蒙古族自治县',
    cityCode: '2109',
    enName: 'Fuxin Mongolian Autonomous County',
  },
  {
    code: '210922',
    name: '彰武县',
    cityCode: '2109',
    enName: 'Zhangwu',
  },
  { code: '211002', name: '白塔区', cityCode: '2110', enName: 'Baita' },
  {
    code: '211003',
    name: '文圣区',
    cityCode: '2110',
    enName: 'Wensheng',
  },
  {
    code: '211004',
    name: '宏伟区',
    cityCode: '2110',
    enName: 'Hongwei',
  },
  {
    code: '211005',
    name: '弓长岭区',
    cityCode: '2110',
    enName: 'Gongchangling',
  },
  {
    code: '211011',
    name: '太子河区',
    cityCode: '2110',
    enName: 'Taizihe',
  },
  {
    code: '211021',
    name: '辽阳县',
    cityCode: '2110',
    enName: 'Liaoyang',
  },
  {
    code: '211081',
    name: '灯塔市',
    cityCode: '2110',
    enName: 'Dengta',
  },
  {
    code: '211102',
    name: '双台子区',
    cityCode: '2111',
    enName: 'Shuangtaizi',
  },
  {
    code: '211103',
    name: '兴隆台区',
    cityCode: '2111',
    enName: 'Xinglongtai',
  },
  { code: '211104', name: '大洼区', cityCode: '2111', enName: 'Dawa' },
  {
    code: '211122',
    name: '盘山县',
    cityCode: '2111',
    enName: 'Panshan',
  },
  {
    code: '211202',
    name: '银州区',
    cityCode: '2112',
    enName: 'Yinzhou',
  },
  {
    code: '211204',
    name: '清河区',
    cityCode: '2112',
    enName: 'Qinghe',
  },
  {
    code: '211221',
    name: '铁岭县',
    cityCode: '2112',
    enName: 'Tieling',
  },
  {
    code: '211223',
    name: '西丰县',
    cityCode: '2112',
    enName: 'Xifeng',
  },
  {
    code: '211224',
    name: '昌图县',
    cityCode: '2112',
    enName: 'Changtu',
  },
  {
    code: '211281',
    name: '调兵山市',
    cityCode: '2112',
    enName: 'Tiaobingshan',
  },
  {
    code: '211282',
    name: '开原市',
    cityCode: '2112',
    enName: 'Kaiyuan',
  },
  {
    code: '211302',
    name: '双塔区',
    cityCode: '2113',
    enName: 'Shuangta',
  },
  {
    code: '211303',
    name: '龙城区',
    cityCode: '2113',
    enName: 'Longcheng',
  },
  {
    code: '211321',
    name: '朝阳县',
    cityCode: '2113',
    enName: 'Chaoyang',
  },
  {
    code: '211322',
    name: '建平县',
    cityCode: '2113',
    enName: 'Jianping',
  },
  {
    code: '211324',
    name: '喀喇沁左翼蒙古族自治县',
    cityCode: '2113',
    enName: 'Harqin Left Wing Mongolian Autonomous County',
  },
  {
    code: '211381',
    name: '北票市',
    cityCode: '2113',
    enName: 'Beipiao',
  },
  {
    code: '211382',
    name: '凌源市',
    cityCode: '2113',
    enName: 'Lingyuan',
  },
  {
    code: '211402',
    name: '连山区',
    cityCode: '2114',
    enName: 'Lianshan',
  },
  {
    code: '211403',
    name: '龙港区',
    cityCode: '2114',
    enName: 'Longgang',
  },
  {
    code: '211404',
    name: '南票区',
    cityCode: '2114',
    enName: 'Nanpiao',
  },
  {
    code: '211421',
    name: '绥中县',
    cityCode: '2114',
    enName: 'Suizhong',
  },
  {
    code: '211422',
    name: '建昌县',
    cityCode: '2114',
    enName: 'Jianchang',
  },
  {
    code: '211481',
    name: '兴城市',
    cityCode: '2114',
    enName: 'Xingcheng',
  },
  {
    code: '220102',
    name: '南关区',
    cityCode: '2201',
    enName: 'Nanguan',
  },
  {
    code: '220103',
    name: '宽城区',
    cityCode: '2201',
    enName: 'Kuancheng',
  },
  {
    code: '220104',
    name: '朝阳区',
    cityCode: '2201',
    enName: 'Chaoyang',
  },
  { code: '220105', name: '二道区', cityCode: '2201', enName: 'Erdao' },
  {
    code: '220106',
    name: '绿园区',
    cityCode: '2201',
    enName: 'Lüyuan',
  },
  {
    code: '220112',
    name: '双阳区',
    cityCode: '2201',
    enName: 'Shuangyang',
  },
  {
    code: '220113',
    name: '九台区',
    cityCode: '2201',
    enName: 'Jiutai',
  },
  {
    code: '220122',
    name: '农安县',
    cityCode: '2201',
    enName: 'Nongan',
  },
  { code: '220182', name: '榆树市', cityCode: '2201', enName: 'Yushu' },
  { code: '220183', name: '德惠市', cityCode: '2201', enName: 'Dehui' },
  {
    code: '220202',
    name: '昌邑区',
    cityCode: '2202',
    enName: 'Changyi',
  },
  {
    code: '220203',
    name: '龙潭区',
    cityCode: '2202',
    enName: 'Longtan',
  },
  {
    code: '220204',
    name: '船营区',
    cityCode: '2202',
    enName: 'Chuanying',
  },
  {
    code: '220211',
    name: '丰满区',
    cityCode: '2202',
    enName: 'Fengman',
  },
  {
    code: '220221',
    name: '永吉县',
    cityCode: '2202',
    enName: 'Yongji',
  },
  {
    code: '220281',
    name: '蛟河市',
    cityCode: '2202',
    enName: 'Jiaohe',
  },
  {
    code: '220282',
    name: '桦甸市',
    cityCode: '2202',
    enName: 'Huadian',
  },
  {
    code: '220283',
    name: '舒兰市',
    cityCode: '2202',
    enName: 'Shulan',
  },
  {
    code: '220284',
    name: '磐石市',
    cityCode: '2202',
    enName: 'Panshi',
  },
  { code: '220302', name: '铁西区', cityCode: '2203', enName: 'Tiexi' },
  {
    code: '220303',
    name: '铁东区',
    cityCode: '2203',
    enName: 'Tiedong',
  },
  { code: '220322', name: '梨树县', cityCode: '2203', enName: 'Lishu' },
  {
    code: '220323',
    name: '伊通满族自治县',
    cityCode: '2203',
    enName: 'Yitong Manchu Autonomous County',
  },
  {
    code: '220381',
    name: '公主岭市',
    cityCode: '2203',
    enName: 'Gongzhuling',
  },
  {
    code: '220382',
    name: '双辽市',
    cityCode: '2203',
    enName: 'Shuangliao',
  },
  {
    code: '220402',
    name: '龙山区',
    cityCode: '2204',
    enName: 'Longshan',
  },
  { code: '220403', name: '西安区', cityCode: '2204', enName: 'Xian' },
  {
    code: '220421',
    name: '东丰县',
    cityCode: '2204',
    enName: 'Dongfeng',
  },
  {
    code: '220422',
    name: '东辽县',
    cityCode: '2204',
    enName: 'Dongliao',
  },
  {
    code: '220502',
    name: '东昌区',
    cityCode: '2205',
    enName: 'Dongchang',
  },
  {
    code: '220503',
    name: '二道江区',
    cityCode: '2205',
    enName: 'Erdaojiang',
  },
  {
    code: '220521',
    name: '通化县',
    cityCode: '2205',
    enName: 'Tonghua',
  },
  {
    code: '220523',
    name: '辉南县',
    cityCode: '2205',
    enName: 'Huinan',
  },
  { code: '220524', name: '柳河县', cityCode: '2205', enName: 'Liuhe' },
  {
    code: '220581',
    name: '梅河口市',
    cityCode: '2205',
    enName: 'Meihekou',
  },
  { code: '220582', name: '集安市', cityCode: '2205', enName: 'Jian' },
  {
    code: '220602',
    name: '浑江区',
    cityCode: '2206',
    enName: 'Hunjiang',
  },
  {
    code: '220605',
    name: '江源区',
    cityCode: '2206',
    enName: 'Jiangyuan',
  },
  {
    code: '220621',
    name: '抚松县',
    cityCode: '2206',
    enName: 'Fusong',
  },
  {
    code: '220622',
    name: '靖宇县',
    cityCode: '2206',
    enName: 'Jingyu',
  },
  {
    code: '220623',
    name: '长白朝鲜族自治县',
    cityCode: '2206',
    enName: 'Changbai Korean Autonomous County',
  },
  {
    code: '220681',
    name: '临江市',
    cityCode: '2206',
    enName: 'Linjiang',
  },
  {
    code: '220702',
    name: '宁江区',
    cityCode: '2207',
    enName: 'Ningjiang',
  },
  {
    code: '220721',
    name: '前郭尔罗斯蒙古族自治县',
    cityCode: '2207',
    enName: 'Mongolian Autonomous County of Qian Gorlos',
  },
  {
    code: '220722',
    name: '长岭县',
    cityCode: '2207',
    enName: 'Changling',
  },
  {
    code: '220723',
    name: '乾安县',
    cityCode: '2207',
    enName: 'Qianan',
  },
  { code: '220781', name: '扶余市', cityCode: '2207', enName: 'Fuyu' },
  {
    code: '220802',
    name: '洮北区',
    cityCode: '2208',
    enName: 'Taobei',
  },
  {
    code: '220821',
    name: '镇赉县',
    cityCode: '2208',
    enName: 'Zhenlai',
  },
  {
    code: '220822',
    name: '通榆县',
    cityCode: '2208',
    enName: 'Tongyu',
  },
  {
    code: '220881',
    name: '洮南市',
    cityCode: '2208',
    enName: 'Taonan',
  },
  { code: '220882', name: '大安市', cityCode: '2208', enName: 'Daan' },
  { code: '222401', name: '延吉市', cityCode: '2224', enName: 'Yanji' },
  { code: '222402', name: '图们市', cityCode: '2224', enName: 'Tumen' },
  {
    code: '222403',
    name: '敦化市',
    cityCode: '2224',
    enName: 'Dunhua',
  },
  {
    code: '222404',
    name: '珲春市',
    cityCode: '2224',
    enName: 'Huichun',
  },
  {
    code: '222405',
    name: '龙井市',
    cityCode: '2224',
    enName: 'Longjing',
  },
  {
    code: '222406',
    name: '和龙市',
    cityCode: '2224',
    enName: 'Helong',
  },
  {
    code: '222424',
    name: '汪清县',
    cityCode: '2224',
    enName: 'Wangqing',
  },
  { code: '222426', name: '安图县', cityCode: '2224', enName: 'Antu' },
  { code: '230102', name: '道里区', cityCode: '2301', enName: 'Daoli' },
  {
    code: '230103',
    name: '南岗区',
    cityCode: '2301',
    enName: 'Nangang',
  },
  {
    code: '230104',
    name: '道外区',
    cityCode: '2301',
    enName: 'Daowai',
  },
  {
    code: '230108',
    name: '平房区',
    cityCode: '2301',
    enName: 'Pingfang',
  },
  {
    code: '230109',
    name: '松北区',
    cityCode: '2301',
    enName: 'Songbei',
  },
  {
    code: '230110',
    name: '香坊区',
    cityCode: '2301',
    enName: 'Xiangfang',
  },
  { code: '230111', name: '呼兰区', cityCode: '2301', enName: 'Hulan' },
  {
    code: '230112',
    name: '阿城区',
    cityCode: '2301',
    enName: 'Acheng',
  },
  {
    code: '230113',
    name: '双城区',
    cityCode: '2301',
    enName: 'Shuangcheng',
  },
  { code: '230123', name: '依兰县', cityCode: '2301', enName: 'Yilan' },
  {
    code: '230124',
    name: '方正县',
    cityCode: '2301',
    enName: 'Fangzheng',
  },
  { code: '230125', name: '宾县', cityCode: '2301', enName: 'Bin' },
  { code: '230126', name: '巴彦县', cityCode: '2301', enName: 'Bayan' },
  { code: '230127', name: '木兰县', cityCode: '2301', enName: 'Mulan' },
  {
    code: '230128',
    name: '通河县',
    cityCode: '2301',
    enName: 'Tonghe',
  },
  {
    code: '230129',
    name: '延寿县',
    cityCode: '2301',
    enName: 'Yanshou',
  },
  {
    code: '230183',
    name: '尚志市',
    cityCode: '2301',
    enName: 'Shangzhi',
  },
  {
    code: '230184',
    name: '五常市',
    cityCode: '2301',
    enName: 'Wuchang',
  },
  {
    code: '230202',
    name: '龙沙区',
    cityCode: '2302',
    enName: 'Longsha',
  },
  {
    code: '230203',
    name: '建华区',
    cityCode: '2302',
    enName: 'Jianhua',
  },
  {
    code: '230204',
    name: '铁锋区',
    cityCode: '2302',
    enName: 'Tiefeng',
  },
  {
    code: '230205',
    name: '昂昂溪区',
    cityCode: '2302',
    enName: 'Angangxi',
  },
  {
    code: '230206',
    name: '富拉尔基区',
    cityCode: '2302',
    enName: 'Fulaerji',
  },
  {
    code: '230207',
    name: '碾子山区',
    cityCode: '2302',
    enName: 'Nianzishan',
  },
  {
    code: '230208',
    name: '梅里斯达斡尔族区',
    cityCode: '2302',
    enName: 'Meilisi Daur Area',
  },
  {
    code: '230221',
    name: '龙江县',
    cityCode: '2302',
    enName: 'Longjiang',
  },
  { code: '230223', name: '依安县', cityCode: '2302', enName: 'Yian' },
  {
    code: '230224',
    name: '泰来县',
    cityCode: '2302',
    enName: 'Tailai',
  },
  {
    code: '230225',
    name: '甘南县',
    cityCode: '2302',
    enName: 'Gannan',
  },
  { code: '230227', name: '富裕县', cityCode: '2302', enName: 'Fuyu' },
  {
    code: '230229',
    name: '克山县',
    cityCode: '2302',
    enName: 'Keshan',
  },
  {
    code: '230230',
    name: '克东县',
    cityCode: '2302',
    enName: 'Kedong',
  },
  { code: '230231', name: '拜泉县', cityCode: '2302', enName: 'Baian' },
  { code: '230281', name: '讷河市', cityCode: '2302', enName: 'Nehe' },
  {
    code: '230302',
    name: '鸡冠区',
    cityCode: '2303',
    enName: 'Jiguan',
  },
  {
    code: '230303',
    name: '恒山区',
    cityCode: '2303',
    enName: 'Hengshan',
  },
  { code: '230304', name: '滴道区', cityCode: '2303', enName: 'Didao' },
  { code: '230305', name: '梨树区', cityCode: '2303', enName: 'Lishu' },
  {
    code: '230306',
    name: '城子河区',
    cityCode: '2303',
    enName: 'Chengzihe',
  },
  {
    code: '230307',
    name: '麻山区',
    cityCode: '2303',
    enName: 'Mashan',
  },
  {
    code: '230321',
    name: '鸡东县',
    cityCode: '2303',
    enName: 'Jidong',
  },
  { code: '230381', name: '虎林市', cityCode: '2303', enName: 'Hulin' },
  {
    code: '230382',
    name: '密山市',
    cityCode: '2303',
    enName: 'Mishan',
  },
  {
    code: '230402',
    name: '向阳区',
    cityCode: '2304',
    enName: 'Xiangyang',
  },
  {
    code: '230403',
    name: '工农区',
    cityCode: '2304',
    enName: 'Gongnong',
  },
  {
    code: '230404',
    name: '南山区',
    cityCode: '2304',
    enName: 'Nanshan',
  },
  {
    code: '230405',
    name: '兴安区',
    cityCode: '2304',
    enName: 'Xingan',
  },
  {
    code: '230406',
    name: '东山区',
    cityCode: '2304',
    enName: 'Dongshan',
  },
  {
    code: '230407',
    name: '兴山区',
    cityCode: '2304',
    enName: 'Xingshan',
  },
  {
    code: '230421',
    name: '萝北县',
    cityCode: '2304',
    enName: 'Luobei',
  },
  {
    code: '230422',
    name: '绥滨县',
    cityCode: '2304',
    enName: 'Suibin',
  },
  {
    code: '230502',
    name: '尖山区',
    cityCode: '2305',
    enName: 'Jianshan',
  },
  {
    code: '230503',
    name: '岭东区',
    cityCode: '2305',
    enName: 'Lingdong',
  },
  {
    code: '230505',
    name: '四方台区',
    cityCode: '2305',
    enName: 'Sifangtai',
  },
  {
    code: '230506',
    name: '宝山区',
    cityCode: '2305',
    enName: 'Baoshan',
  },
  {
    code: '230521',
    name: '集贤县',
    cityCode: '2305',
    enName: 'Jixian',
  },
  { code: '230522', name: '友谊县', cityCode: '2305', enName: 'Youyi' },
  {
    code: '230523',
    name: '宝清县',
    cityCode: '2305',
    enName: 'Baoqing',
  },
  { code: '230524', name: '饶河县', cityCode: '2305', enName: 'Raohe' },
  {
    code: '230602',
    name: '萨尔图区',
    cityCode: '2306',
    enName: 'Saertu',
  },
  {
    code: '230603',
    name: '龙凤区',
    cityCode: '2306',
    enName: 'Longfeng',
  },
  {
    code: '230604',
    name: '让胡路区',
    cityCode: '2306',
    enName: 'Ranghulu',
  },
  {
    code: '230605',
    name: '红岗区',
    cityCode: '2306',
    enName: 'Honggang',
  },
  {
    code: '230606',
    name: '大同区',
    cityCode: '2306',
    enName: 'Datong',
  },
  {
    code: '230621',
    name: '肇州县',
    cityCode: '2306',
    enName: 'Zhaozhou',
  },
  {
    code: '230622',
    name: '肇源县',
    cityCode: '2306',
    enName: 'Zhaoyuan',
  },
  {
    code: '230623',
    name: '林甸县',
    cityCode: '2306',
    enName: 'Lindian',
  },
  {
    code: '230624',
    name: '杜尔伯特蒙古族自治县',
    cityCode: '2306',
    enName: 'Durbert Mongolian Autonomous County',
  },
  {
    code: '230702',
    name: '伊春区',
    cityCode: '2307',
    enName: 'Yichun',
  },
  {
    code: '230703',
    name: '南岔区',
    cityCode: '2307',
    enName: 'Nancha',
  },
  {
    code: '230704',
    name: '友好区',
    cityCode: '2307',
    enName: 'Youhao',
  },
  { code: '230705', name: '西林区', cityCode: '2307', enName: 'Xilin' },
  {
    code: '230706',
    name: '翠峦区',
    cityCode: '2307',
    enName: 'Cuiluan',
  },
  {
    code: '230707',
    name: '新青区',
    cityCode: '2307',
    enName: 'Xinqing',
  },
  { code: '230708', name: '美溪区', cityCode: '2307', enName: 'Meixi' },
  {
    code: '230709',
    name: '金山屯区',
    cityCode: '2307',
    enName: 'Jinshantun',
  },
  {
    code: '230710',
    name: '五营区',
    cityCode: '2307',
    enName: 'Wuying',
  },
  {
    code: '230711',
    name: '乌马河区',
    cityCode: '2307',
    enName: 'Wumahe',
  },
  {
    code: '230712',
    name: '汤旺河区',
    cityCode: '2307',
    enName: 'Tangwanghe',
  },
  {
    code: '230713',
    name: '带岭区',
    cityCode: '2307',
    enName: 'Dailing',
  },
  {
    code: '230714',
    name: '乌伊岭区',
    cityCode: '2307',
    enName: 'Wuyiling',
  },
  {
    code: '230715',
    name: '红星区',
    cityCode: '2307',
    enName: 'Hongxing',
  },
  {
    code: '230716',
    name: '上甘岭区',
    cityCode: '2307',
    enName: 'Shangganling',
  },
  {
    code: '230722',
    name: '嘉荫县',
    cityCode: '2307',
    enName: 'Jiayin',
  },
  { code: '230781', name: '铁力市', cityCode: '2307', enName: 'Tieli' },
  {
    code: '230803',
    name: '向阳区',
    cityCode: '2308',
    enName: 'Xiangyang',
  },
  {
    code: '230804',
    name: '前进区',
    cityCode: '2308',
    enName: 'Qianjin',
  },
  {
    code: '230805',
    name: '东风区',
    cityCode: '2308',
    enName: 'Dongfeng',
  },
  { code: '230811', name: '郊区', cityCode: '2308', enName: 'Suburbs' },
  {
    code: '230822',
    name: '桦南县',
    cityCode: '2308',
    enName: 'Huanan',
  },
  {
    code: '230826',
    name: '桦川县',
    cityCode: '2308',
    enName: 'Huachuan',
  },
  {
    code: '230828',
    name: '汤原县',
    cityCode: '2308',
    enName: 'Tangyuan',
  },
  {
    code: '230881',
    name: '同江市',
    cityCode: '2308',
    enName: 'Tongjiang',
  },
  { code: '230882', name: '富锦市', cityCode: '2308', enName: 'Fujin' },
  {
    code: '230883',
    name: '抚远市',
    cityCode: '2308',
    enName: 'Fuyuan',
  },
  {
    code: '230902',
    name: '新兴区',
    cityCode: '2309',
    enName: 'Xinxing',
  },
  {
    code: '230903',
    name: '桃山区',
    cityCode: '2309',
    enName: 'Taoshan',
  },
  {
    code: '230904',
    name: '茄子河区',
    cityCode: '2309',
    enName: 'Qiezihe',
  },
  { code: '230921', name: '勃利县', cityCode: '2309', enName: 'Boli' },
  {
    code: '231002',
    name: '东安区',
    cityCode: '2310',
    enName: 'Dongan',
  },
  {
    code: '231003',
    name: '阳明区',
    cityCode: '2310',
    enName: 'Yangming',
  },
  { code: '231004', name: '爱民区', cityCode: '2310', enName: 'Aimin' },
  { code: '231005', name: '西安区', cityCode: '2310', enName: 'Xian' },
  {
    code: '231025',
    name: '林口县',
    cityCode: '2310',
    enName: 'Linkou',
  },
  {
    code: '231081',
    name: '绥芬河市',
    cityCode: '2310',
    enName: 'Suifenhe',
  },
  {
    code: '231083',
    name: '海林市',
    cityCode: '2310',
    enName: 'Hailin',
  },
  {
    code: '231084',
    name: '宁安市',
    cityCode: '2310',
    enName: 'Ningan',
  },
  {
    code: '231085',
    name: '穆棱市',
    cityCode: '2310',
    enName: 'Muleng',
  },
  {
    code: '231086',
    name: '东宁市',
    cityCode: '2310',
    enName: 'Dongning',
  },
  { code: '231102', name: '爱辉区', cityCode: '2311', enName: 'Aihui' },
  {
    code: '231121',
    name: '嫩江县',
    cityCode: '2311',
    enName: 'Nenjiang',
  },
  { code: '231123', name: '逊克县', cityCode: '2311', enName: 'Xunke' },
  { code: '231124', name: '孙吴县', cityCode: '2311', enName: 'Sunwu' },
  { code: '231181', name: '北安市', cityCode: '2311', enName: 'Beian' },
  {
    code: '231182',
    name: '五大连池市',
    cityCode: '2311',
    enName: 'Wudalianchi',
  },
  {
    code: '231202',
    name: '北林区',
    cityCode: '2312',
    enName: 'Beilin',
  },
  {
    code: '231221',
    name: '望奎县',
    cityCode: '2312',
    enName: 'Wangkui',
  },
  { code: '231222', name: '兰西县', cityCode: '2312', enName: 'Lanxi' },
  {
    code: '231223',
    name: '青冈县',
    cityCode: '2312',
    enName: 'Qinggang',
  },
  {
    code: '231224',
    name: '庆安县',
    cityCode: '2312',
    enName: 'Qingan',
  },
  {
    code: '231225',
    name: '明水县',
    cityCode: '2312',
    enName: 'Mingshui',
  },
  {
    code: '231226',
    name: '绥棱县',
    cityCode: '2312',
    enName: 'Suileng',
  },
  { code: '231281', name: '安达市', cityCode: '2312', enName: 'Anda' },
  {
    code: '231282',
    name: '肇东市',
    cityCode: '2312',
    enName: 'Zhaodong',
  },
  {
    code: '231283',
    name: '海伦市',
    cityCode: '2312',
    enName: 'Hailun',
  },
  { code: '232721', name: '呼玛县', cityCode: '2327', enName: 'Huma' },
  { code: '232722', name: '塔河县', cityCode: '2327', enName: 'Tahe' },
  { code: '232723', name: '漠河县', cityCode: '2327', enName: 'Mohe' },
  {
    code: '310101',
    name: '黄浦区',
    cityCode: '3101',
    enName: 'Huangpu',
  },
  { code: '310104', name: '徐汇区', cityCode: '3101', enName: 'Xuhui' },
  {
    code: '310105',
    name: '长宁区',
    cityCode: '3101',
    enName: 'Changning',
  },
  {
    code: '310106',
    name: '静安区',
    cityCode: '3101',
    enName: "Jing'an",
  },
  { code: '310107', name: '普陀区', cityCode: '3101', enName: 'Putuo' },
  {
    code: '310110',
    name: '虹口区',
    cityCode: '3101',
    enName: 'Hongkou',
  },
  {
    code: '310112',
    name: '杨浦区',
    cityCode: '3101',
    enName: 'Yangpu',
  },
  {
    code: '310113',
    name: '闵行区',
    cityCode: '3101',
    enName: 'Minhang',
  },
  {
    code: '310225',
    name: '浦东新区',
    cityCode: '3101',
    enName: 'Pudong',
  },
  {
    code: '310116',
    name: '宝山区',
    cityCode: '3101',
    enName: 'Baoshan',
  },
  {
    code: '310114',
    name: '嘉定区',
    cityCode: '3101',
    enName: 'Jiading',
  },
  {
    code: '310115',
    name: '金山区',
    cityCode: '3101',
    enName: 'Jinshan',
  },
  {
    code: '310117',
    name: '松江区',
    cityCode: '3101',
    enName: 'Songjiang',
  },
  {
    code: '310118',
    name: '青浦区',
    cityCode: '3101',
    enName: 'Qingpu',
  },
  {
    code: '310120',
    name: '奉贤区',
    cityCode: '3101',
    enName: 'Fengxian',
  },
  {
    code: '310230',
    name: '崇明区',
    cityCode: '3101',
    enName: 'Chongming',
  },
  {
    code: '320102',
    name: '玄武区',
    cityCode: '3201',
    enName: 'Xuanwu',
  },
  {
    code: '320104',
    name: '秦淮区',
    cityCode: '3201',
    enName: 'Qinhuai',
  },
  {
    code: '320105',
    name: '建邺区',
    cityCode: '3201',
    enName: 'Jianye',
  },
  { code: '320106', name: '鼓楼区', cityCode: '3201', enName: 'Gulou' },
  { code: '320111', name: '浦口区', cityCode: '3201', enName: 'Pukou' },
  { code: '320113', name: '栖霞区', cityCode: '3201', enName: 'Qixia' },
  {
    code: '320114',
    name: '雨花台区',
    cityCode: '3201',
    enName: 'Yuhuatai',
  },
  {
    code: '320115',
    name: '江宁区',
    cityCode: '3201',
    enName: 'Jiangning',
  },
  { code: '320116', name: '六合区', cityCode: '3201', enName: 'Liuhe' },
  {
    code: '320117',
    name: '溧水区',
    cityCode: '3201',
    enName: 'Lishui',
  },
  {
    code: '320118',
    name: '高淳区',
    cityCode: '3201',
    enName: 'Gaochun',
  },
  {
    code: '320205',
    name: '锡山区',
    cityCode: '3202',
    enName: 'Xishan',
  },
  {
    code: '320206',
    name: '惠山区',
    cityCode: '3202',
    enName: 'Huishan',
  },
  { code: '320211', name: '滨湖区', cityCode: '3202', enName: 'Binhu' },
  {
    code: '320213',
    name: '梁溪区',
    cityCode: '3202',
    enName: 'Liangxi',
  },
  { code: '320214', name: '新吴区', cityCode: '3202', enName: 'Xinwu' },
  {
    code: '320281',
    name: '江阴市',
    cityCode: '3202',
    enName: 'Jiangyin',
  },
  {
    code: '320282',
    name: '宜兴市',
    cityCode: '3202',
    enName: 'Yixing',
  },
  { code: '320302', name: '鼓楼区', cityCode: '3203', enName: 'Gulou' },
  {
    code: '320303',
    name: '云龙区',
    cityCode: '3203',
    enName: 'Yunlong',
  },
  {
    code: '320305',
    name: '贾汪区',
    cityCode: '3203',
    enName: 'Jiawang',
  },
  {
    code: '320311',
    name: '泉山区',
    cityCode: '3203',
    enName: 'Quanshan',
  },
  {
    code: '320312',
    name: '铜山区',
    cityCode: '3203',
    enName: 'Tongshan',
  },
  { code: '320321', name: '丰县', cityCode: '3203', enName: 'Feng' },
  { code: '320322', name: '沛县', cityCode: '3203', enName: 'Pei' },
  {
    code: '320324',
    name: '睢宁县',
    cityCode: '3203',
    enName: 'Suining',
  },
  { code: '320381', name: '新沂市', cityCode: '3203', enName: 'Xinyi' },
  {
    code: '320382',
    name: '邳州市',
    cityCode: '3203',
    enName: 'Pizhou',
  },
  {
    code: '320402',
    name: '天宁区',
    cityCode: '3204',
    enName: 'Tianning',
  },
  {
    code: '320404',
    name: '钟楼区',
    cityCode: '3204',
    enName: 'Zhonglou',
  },
  {
    code: '320411',
    name: '新北区',
    cityCode: '3204',
    enName: 'Xinbei',
  },
  { code: '320412', name: '武进区', cityCode: '3204', enName: 'Wujin' },
  {
    code: '320413',
    name: '金坛区',
    cityCode: '3204',
    enName: 'Jintan',
  },
  {
    code: '320481',
    name: '溧阳市',
    cityCode: '3204',
    enName: 'Liyang',
  },
  { code: '320505', name: '虎丘区', cityCode: '3205', enName: 'Huqiu' },
  {
    code: '320506',
    name: '吴中区',
    cityCode: '3205',
    enName: 'Wuzhong',
  },
  {
    code: '320507',
    name: '相城区',
    cityCode: '3205',
    enName: 'Xiangcheng',
  },
  { code: '320508', name: '姑苏区', cityCode: '3205', enName: 'Gusu' },
  {
    code: '320509',
    name: '吴江区',
    cityCode: '3205',
    enName: 'Wujiang',
  },
  {
    code: '320581',
    name: '常熟市',
    cityCode: '3205',
    enName: 'Changshu',
  },
  {
    code: '320582',
    name: '张家港市',
    cityCode: '3205',
    enName: 'Zhangjiagang',
  },
  {
    code: '320583',
    name: '昆山市',
    cityCode: '3205',
    enName: 'Kunshan',
  },
  {
    code: '320585',
    name: '太仓市',
    cityCode: '3205',
    enName: 'Taicang',
  },
  {
    code: '320602',
    name: '崇川区',
    cityCode: '3206',
    enName: 'Chongchuan',
  },
  {
    code: '320611',
    name: '港闸区',
    cityCode: '3206',
    enName: 'Gangzha',
  },
  {
    code: '320612',
    name: '通州区',
    cityCode: '3206',
    enName: 'Tongzhou',
  },
  { code: '320621', name: '海安县', cityCode: '3206', enName: 'Haian' },
  {
    code: '320623',
    name: '如东县',
    cityCode: '3206',
    enName: 'Rudong',
  },
  {
    code: '320681',
    name: '启东市',
    cityCode: '3206',
    enName: 'Qidong',
  },
  { code: '320682', name: '如皋市', cityCode: '3206', enName: 'Rugao' },
  {
    code: '320684',
    name: '海门市',
    cityCode: '3206',
    enName: 'Haimen',
  },
  {
    code: '320703',
    name: '连云区',
    cityCode: '3207',
    enName: 'Lianyun',
  },
  {
    code: '320706',
    name: '海州区',
    cityCode: '3207',
    enName: 'Haizhou',
  },
  { code: '320707', name: '赣榆区', cityCode: '3207', enName: 'Ganyu' },
  {
    code: '320722',
    name: '东海县',
    cityCode: '3207',
    enName: 'Donghai',
  },
  {
    code: '320723',
    name: '灌云县',
    cityCode: '3207',
    enName: 'Guanyun',
  },
  {
    code: '320724',
    name: '灌南县',
    cityCode: '3207',
    enName: 'Guannan',
  },
  {
    code: '320803',
    name: '淮安区',
    cityCode: '3208',
    enName: 'Huaian',
  },
  {
    code: '320804',
    name: '淮阴区',
    cityCode: '3208',
    enName: 'Huaiyin',
  },
  {
    code: '320812',
    name: '清江浦区',
    cityCode: '3208',
    enName: 'Qingjiangpu',
  },
  {
    code: '320813',
    name: '洪泽区',
    cityCode: '3208',
    enName: 'Hongze',
  },
  {
    code: '320826',
    name: '涟水县',
    cityCode: '3208',
    enName: 'Lianshui',
  },
  { code: '320830', name: '盱眙县', cityCode: '3208', enName: 'Xuyi' },
  { code: '320831', name: '金湖县', cityCode: '3208', enName: 'Jinhu' },
  {
    code: '320902',
    name: '亭湖区',
    cityCode: '3209',
    enName: 'Tinghu',
  },
  {
    code: '320903',
    name: '盐都区',
    cityCode: '3209',
    enName: 'Yandou',
  },
  {
    code: '320904',
    name: '大丰区',
    cityCode: '3209',
    enName: 'Dafeng',
  },
  {
    code: '320921',
    name: '响水县',
    cityCode: '3209',
    enName: 'Xiangshui',
  },
  {
    code: '320922',
    name: '滨海县',
    cityCode: '3209',
    enName: 'Binhai',
  },
  {
    code: '320923',
    name: '阜宁县',
    cityCode: '3209',
    enName: 'Funing',
  },
  {
    code: '320924',
    name: '射阳县',
    cityCode: '3209',
    enName: 'Sheyang',
  },
  {
    code: '320925',
    name: '建湖县',
    cityCode: '3209',
    enName: 'Jianhu',
  },
  {
    code: '320981',
    name: '东台市',
    cityCode: '3209',
    enName: 'Dongtai',
  },
  {
    code: '321002',
    name: '广陵区',
    cityCode: '3210',
    enName: 'Guangling',
  },
  {
    code: '321003',
    name: '邗江区',
    cityCode: '3210',
    enName: 'Hanjiang',
  },
  {
    code: '321012',
    name: '江都区',
    cityCode: '3210',
    enName: 'Jiangdou',
  },
  {
    code: '321023',
    name: '宝应县',
    cityCode: '3210',
    enName: 'Baoying',
  },
  {
    code: '321081',
    name: '仪征市',
    cityCode: '3210',
    enName: 'Yizheng',
  },
  {
    code: '321084',
    name: '高邮市',
    cityCode: '3210',
    enName: 'Gaoyou',
  },
  {
    code: '321102',
    name: '京口区',
    cityCode: '3211',
    enName: 'Jingkou',
  },
  {
    code: '321111',
    name: '润州区',
    cityCode: '3211',
    enName: 'Runzhou',
  },
  { code: '321112', name: '丹徒区', cityCode: '3211', enName: 'Dantu' },
  {
    code: '321181',
    name: '丹阳市',
    cityCode: '3211',
    enName: 'Danyang',
  },
  {
    code: '321182',
    name: '扬中市',
    cityCode: '3211',
    enName: 'Yangzhong',
  },
  {
    code: '321183',
    name: '句容市',
    cityCode: '3211',
    enName: 'Jurong',
  },
  {
    code: '321202',
    name: '海陵区',
    cityCode: '3212',
    enName: 'Hailing',
  },
  {
    code: '321203',
    name: '高港区',
    cityCode: '3212',
    enName: 'Gaogang',
  },
  {
    code: '321204',
    name: '姜堰区',
    cityCode: '3212',
    enName: 'Jiangyan',
  },
  {
    code: '321281',
    name: '兴化市',
    cityCode: '3212',
    enName: 'Xinghua',
  },
  {
    code: '321282',
    name: '靖江市',
    cityCode: '3212',
    enName: 'Jingjiang',
  },
  {
    code: '321283',
    name: '泰兴市',
    cityCode: '3212',
    enName: 'Taixing',
  },
  {
    code: '321302',
    name: '宿城区',
    cityCode: '3213',
    enName: 'Sucheng',
  },
  { code: '321311', name: '宿豫区', cityCode: '3213', enName: 'Suyu' },
  {
    code: '321322',
    name: '沭阳县',
    cityCode: '3213',
    enName: 'Shuyang',
  },
  {
    code: '321323',
    name: '泗阳县',
    cityCode: '3213',
    enName: 'Siyang',
  },
  {
    code: '321324',
    name: '泗洪县',
    cityCode: '3213',
    enName: 'Sihong',
  },
  {
    code: '330102',
    name: '上城区',
    cityCode: '3301',
    enName: 'Shangcheng',
  },
  {
    code: '330103',
    name: '下城区',
    cityCode: '3301',
    enName: 'Xiacheng',
  },
  {
    code: '330104',
    name: '江干区',
    cityCode: '3301',
    enName: 'Jianggan',
  },
  {
    code: '330105',
    name: '拱墅区',
    cityCode: '3301',
    enName: 'Gongshu',
  },
  { code: '330106', name: '西湖区', cityCode: '3301', enName: 'Xihu' },
  {
    code: '330108',
    name: '滨江区',
    cityCode: '3301',
    enName: 'Binjiang',
  },
  {
    code: '330109',
    name: '萧山区',
    cityCode: '3301',
    enName: 'Xiaoshan',
  },
  {
    code: '330110',
    name: '余杭区',
    cityCode: '3301',
    enName: 'Yuhang',
  },
  {
    code: '330111',
    name: '富阳区',
    cityCode: '3301',
    enName: 'Fuyang',
  },
  {
    code: '330122',
    name: '桐庐县',
    cityCode: '3301',
    enName: 'Tonglu',
  },
  {
    code: '330127',
    name: '淳安县',
    cityCode: '3301',
    enName: 'Chunan',
  },
  {
    code: '330182',
    name: '建德市',
    cityCode: '3301',
    enName: 'Jiande',
  },
  { code: '330185', name: '临安市', cityCode: '3301', enName: 'Linan' },
  {
    code: '330203',
    name: '海曙区',
    cityCode: '3302',
    enName: 'Haishu',
  },
  {
    code: '330204',
    name: '江东区',
    cityCode: '3302',
    enName: 'Jiangdong',
  },
  {
    code: '330205',
    name: '江北区',
    cityCode: '3302',
    enName: 'Jiangbei',
  },
  {
    code: '330206',
    name: '北仑区',
    cityCode: '3302',
    enName: 'Beilun',
  },
  {
    code: '330211',
    name: '镇海区',
    cityCode: '3302',
    enName: 'Zhenhai',
  },
  {
    code: '330212',
    name: '鄞州区',
    cityCode: '3302',
    enName: 'Yinzhou',
  },
  {
    code: '330225',
    name: '象山县',
    cityCode: '3302',
    enName: 'Xiangshan',
  },
  {
    code: '330226',
    name: '宁海县',
    cityCode: '3302',
    enName: 'Ninghai',
  },
  { code: '330281', name: '余姚市', cityCode: '3302', enName: 'Yuyao' },
  { code: '330282', name: '慈溪市', cityCode: '3302', enName: 'Cixi' },
  {
    code: '330283',
    name: '奉化市',
    cityCode: '3302',
    enName: 'Fenghua',
  },
  {
    code: '330302',
    name: '鹿城区',
    cityCode: '3303',
    enName: 'Lucheng',
  },
  {
    code: '330303',
    name: '龙湾区',
    cityCode: '3303',
    enName: 'Longwan',
  },
  { code: '330304', name: '瓯海区', cityCode: '3303', enName: 'Ouhai' },
  {
    code: '330305',
    name: '洞头区',
    cityCode: '3303',
    enName: 'Dongtou',
  },
  {
    code: '330324',
    name: '永嘉县',
    cityCode: '3303',
    enName: 'Yongjia',
  },
  {
    code: '330326',
    name: '平阳县',
    cityCode: '3303',
    enName: 'Pingyang',
  },
  {
    code: '330327',
    name: '苍南县',
    cityCode: '3303',
    enName: 'Cangnan',
  },
  {
    code: '330328',
    name: '文成县',
    cityCode: '3303',
    enName: 'Wencheng',
  },
  {
    code: '330329',
    name: '泰顺县',
    cityCode: '3303',
    enName: 'Taishun',
  },
  { code: '330381', name: '瑞安市', cityCode: '3303', enName: 'Ruian' },
  {
    code: '330382',
    name: '乐清市',
    cityCode: '3303',
    enName: 'Leqing',
  },
  { code: '330402', name: '南湖区', cityCode: '3304', enName: 'Nanhu' },
  {
    code: '330411',
    name: '秀洲区',
    cityCode: '3304',
    enName: 'Xiuzhou',
  },
  {
    code: '330421',
    name: '嘉善县',
    cityCode: '3304',
    enName: 'Jiashan',
  },
  {
    code: '330424',
    name: '海盐县',
    cityCode: '3304',
    enName: 'Haiyan',
  },
  {
    code: '330481',
    name: '海宁市',
    cityCode: '3304',
    enName: 'Haining',
  },
  {
    code: '330482',
    name: '平湖市',
    cityCode: '3304',
    enName: 'Pinghu',
  },
  { code: '330483', name: '桐乡市', cityCode: '3304', enName: 'Tongg' },
  {
    code: '330502',
    name: '吴兴区',
    cityCode: '3305',
    enName: 'Wuxing',
  },
  {
    code: '330503',
    name: '南浔区',
    cityCode: '3305',
    enName: 'Nanxun',
  },
  {
    code: '330521',
    name: '德清县',
    cityCode: '3305',
    enName: 'Deqing',
  },
  {
    code: '330522',
    name: '长兴县',
    cityCode: '3305',
    enName: 'Changxing',
  },
  { code: '330523', name: '安吉县', cityCode: '3305', enName: 'Anji' },
  {
    code: '330602',
    name: '越城区',
    cityCode: '3306',
    enName: 'Yuecheng',
  },
  {
    code: '330603',
    name: '柯桥区',
    cityCode: '3306',
    enName: 'Keqiao',
  },
  {
    code: '330604',
    name: '上虞区',
    cityCode: '3306',
    enName: 'Shangyu',
  },
  {
    code: '330624',
    name: '新昌县',
    cityCode: '3306',
    enName: 'Xinchang',
  },
  { code: '330681', name: '诸暨市', cityCode: '3306', enName: 'Zhuji' },
  {
    code: '330683',
    name: '嵊州市',
    cityCode: '3306',
    enName: 'Shengzhou',
  },
  {
    code: '330702',
    name: '婺城区',
    cityCode: '3307',
    enName: 'Wucheng',
  },
  {
    code: '330703',
    name: '金东区',
    cityCode: '3307',
    enName: 'Jindong',
  },
  { code: '330723', name: '武义县', cityCode: '3307', enName: 'Wuyi' },
  {
    code: '330726',
    name: '浦江县',
    cityCode: '3307',
    enName: 'Pujiang',
  },
  { code: '330727', name: '磐安县', cityCode: '3307', enName: 'Panan' },
  { code: '330781', name: '兰溪市', cityCode: '3307', enName: 'Lanxi' },
  { code: '330782', name: '义乌市', cityCode: '3307', enName: 'Yiwu' },
  {
    code: '330783',
    name: '东阳市',
    cityCode: '3307',
    enName: 'Dongyang',
  },
  {
    code: '330784',
    name: '永康市',
    cityCode: '3307',
    enName: 'Yongkang',
  },
  {
    code: '330802',
    name: '柯城区',
    cityCode: '3308',
    enName: 'Kecheng',
  },
  {
    code: '330803',
    name: '衢江区',
    cityCode: '3308',
    enName: 'Qujiang',
  },
  {
    code: '330822',
    name: '常山县',
    cityCode: '3308',
    enName: 'Changshan',
  },
  {
    code: '330824',
    name: '开化县',
    cityCode: '3308',
    enName: 'Kaihua',
  },
  {
    code: '330825',
    name: '龙游县',
    cityCode: '3308',
    enName: 'Longyou',
  },
  {
    code: '330881',
    name: '江山市',
    cityCode: '3308',
    enName: 'Jiangshan',
  },
  {
    code: '330902',
    name: '定海区',
    cityCode: '3309',
    enName: 'Dinghai',
  },
  { code: '330903', name: '普陀区', cityCode: '3309', enName: 'Putuo' },
  {
    code: '330921',
    name: '岱山县',
    cityCode: '3309',
    enName: 'Daishan',
  },
  {
    code: '330922',
    name: '嵊泗县',
    cityCode: '3309',
    enName: 'Shengsi',
  },
  {
    code: '331002',
    name: '椒江区',
    cityCode: '3310',
    enName: 'Jiaojiang',
  },
  {
    code: '331003',
    name: '黄岩区',
    cityCode: '3310',
    enName: 'Huangyan',
  },
  {
    code: '331004',
    name: '路桥区',
    cityCode: '3310',
    enName: 'Luqiao',
  },
  {
    code: '331021',
    name: '玉环县',
    cityCode: '3310',
    enName: 'Yuhuan',
  },
  {
    code: '331022',
    name: '三门县',
    cityCode: '3310',
    enName: 'Sanmen',
  },
  {
    code: '331023',
    name: '天台县',
    cityCode: '3310',
    enName: 'Tiantai',
  },
  {
    code: '331024',
    name: '仙居县',
    cityCode: '3310',
    enName: 'Xianju',
  },
  {
    code: '331081',
    name: '温岭市',
    cityCode: '3310',
    enName: 'Wenling',
  },
  {
    code: '331082',
    name: '临海市',
    cityCode: '3310',
    enName: 'Linhai',
  },
  {
    code: '331102',
    name: '莲都区',
    cityCode: '3311',
    enName: 'Liandou',
  },
  {
    code: '331121',
    name: '青田县',
    cityCode: '3311',
    enName: 'Qingtian',
  },
  {
    code: '331122',
    name: '缙云县',
    cityCode: '3311',
    enName: 'Jinyun',
  },
  {
    code: '331123',
    name: '遂昌县',
    cityCode: '3311',
    enName: 'Suichang',
  },
  {
    code: '331124',
    name: '松阳县',
    cityCode: '3311',
    enName: 'Songyang',
  },
  { code: '331125', name: '云和县', cityCode: '3311', enName: 'Yunhe' },
  {
    code: '331126',
    name: '庆元县',
    cityCode: '3311',
    enName: 'Qingyuan',
  },
  {
    code: '331127',
    name: '景宁畲族自治县',
    cityCode: '3311',
    enName: 'Jingning She Autonomous County',
  },
  {
    code: '331181',
    name: '龙泉市',
    cityCode: '3311',
    enName: 'Longan',
  },
  {
    code: '340102',
    name: '瑶海区',
    cityCode: '3401',
    enName: 'Yaohai',
  },
  {
    code: '340103',
    name: '庐阳区',
    cityCode: '3401',
    enName: 'Luyang',
  },
  {
    code: '340104',
    name: '蜀山区',
    cityCode: '3401',
    enName: 'Shushan',
  },
  { code: '340111', name: '包河区', cityCode: '3401', enName: 'Baohe' },
  {
    code: '340121',
    name: '长丰县',
    cityCode: '3401',
    enName: 'Changfeng',
  },
  {
    code: '340122',
    name: '肥东县',
    cityCode: '3401',
    enName: 'Feidong',
  },
  { code: '340123', name: '肥西县', cityCode: '3401', enName: 'Feixi' },
  {
    code: '340124',
    name: '庐江县',
    cityCode: '3401',
    enName: 'Lujiang',
  },
  {
    code: '340181',
    name: '巢湖市',
    cityCode: '3401',
    enName: 'Chaohu',
  },
  {
    code: '340202',
    name: '镜湖区',
    cityCode: '3402',
    enName: 'Jinghu',
  },
  {
    code: '340203',
    name: '弋江区',
    cityCode: '3402',
    enName: 'Yijiang',
  },
  {
    code: '340207',
    name: '鸠江区',
    cityCode: '3402',
    enName: 'Jiujiang',
  },
  {
    code: '340208',
    name: '三山区',
    cityCode: '3402',
    enName: 'Sanshan',
  },
  { code: '340221', name: '芜湖县', cityCode: '3402', enName: 'Wuhu' },
  {
    code: '340222',
    name: '繁昌县',
    cityCode: '3402',
    enName: 'Fanchang',
  },
  {
    code: '340223',
    name: '南陵县',
    cityCode: '3402',
    enName: 'Nanling',
  },
  { code: '340225', name: '无为县', cityCode: '3402', enName: 'Wuwei' },
  {
    code: '340302',
    name: '龙子湖区',
    cityCode: '3403',
    enName: 'Longzihu',
  },
  {
    code: '340303',
    name: '蚌山区',
    cityCode: '3403',
    enName: 'Bangshan',
  },
  { code: '340304', name: '禹会区', cityCode: '3403', enName: 'Yuhui' },
  {
    code: '340311',
    name: '淮上区',
    cityCode: '3403',
    enName: 'Huaishang',
  },
  {
    code: '340321',
    name: '怀远县',
    cityCode: '3403',
    enName: 'Huaiyuan',
  },
  { code: '340322', name: '五河县', cityCode: '3403', enName: 'Wuhe' },
  {
    code: '340323',
    name: '固镇县',
    cityCode: '3403',
    enName: 'Guzhen',
  },
  {
    code: '340402',
    name: '大通区',
    cityCode: '3404',
    enName: 'Datong',
  },
  {
    code: '340403',
    name: '田家庵区',
    cityCode: '3404',
    enName: 'Tianjiaan',
  },
  {
    code: '340404',
    name: '谢家集区',
    cityCode: '3404',
    enName: 'Xiejiaji',
  },
  {
    code: '340405',
    name: '八公山区',
    cityCode: '3404',
    enName: 'Bagongshan',
  },
  { code: '340406', name: '潘集区', cityCode: '3404', enName: 'Panji' },
  {
    code: '340421',
    name: '凤台县',
    cityCode: '3404',
    enName: 'Fengtai',
  },
  { code: '340422', name: '寿县', cityCode: '3404', enName: 'Shou' },
  {
    code: '340503',
    name: '花山区',
    cityCode: '3405',
    enName: 'Huashan',
  },
  {
    code: '340504',
    name: '雨山区',
    cityCode: '3405',
    enName: 'Yushan',
  },
  {
    code: '340506',
    name: '博望区',
    cityCode: '3405',
    enName: 'Bowang',
  },
  {
    code: '340521',
    name: '当涂县',
    cityCode: '3405',
    enName: 'Dangtu',
  },
  {
    code: '340522',
    name: '含山县',
    cityCode: '3405',
    enName: 'Hanshan',
  },
  { code: '340523', name: '和县', cityCode: '3405', enName: 'He' },
  { code: '340602', name: '杜集区', cityCode: '3406', enName: 'Duji' },
  {
    code: '340603',
    name: '相山区',
    cityCode: '3406',
    enName: 'Xiangshan',
  },
  {
    code: '340604',
    name: '烈山区',
    cityCode: '3406',
    enName: 'Lieshan',
  },
  { code: '340621', name: '濉溪县', cityCode: '3406', enName: 'Suixi' },
  {
    code: '340705',
    name: '铜官区',
    cityCode: '3407',
    enName: 'Tongguan',
  },
  { code: '340706', name: '义安区', cityCode: '3407', enName: 'Yian' },
  { code: '340711', name: '郊区', cityCode: '3407', enName: 'Jiao' },
  {
    code: '340722',
    name: '枞阳县',
    cityCode: '3407',
    enName: 'Zongyang',
  },
  {
    code: '340802',
    name: '迎江区',
    cityCode: '3408',
    enName: 'Yingjiang',
  },
  {
    code: '340803',
    name: '大观区',
    cityCode: '3408',
    enName: 'Daguan',
  },
  { code: '340811', name: '宜秀区', cityCode: '3408', enName: 'Yixiu' },
  {
    code: '340822',
    name: '怀宁县',
    cityCode: '3408',
    enName: 'Huaining',
  },
  {
    code: '340824',
    name: '潜山县',
    cityCode: '3408',
    enName: 'Qianshan',
  },
  { code: '340825', name: '太湖县', cityCode: '3408', enName: 'Taihu' },
  {
    code: '340826',
    name: '宿松县',
    cityCode: '3408',
    enName: 'Susong',
  },
  {
    code: '340827',
    name: '望江县',
    cityCode: '3408',
    enName: 'Wangjiang',
  },
  { code: '340828', name: '岳西县', cityCode: '3408', enName: 'Yuexi' },
  {
    code: '340881',
    name: '桐城市',
    cityCode: '3408',
    enName: 'Tongcheng',
  },
  { code: '341002', name: '屯溪区', cityCode: '3410', enName: 'Tunxi' },
  {
    code: '341003',
    name: '黄山区',
    cityCode: '3410',
    enName: 'Huangshan',
  },
  {
    code: '341004',
    name: '徽州区',
    cityCode: '3410',
    enName: 'Huizhou',
  },
  { code: '341021', name: '歙县', cityCode: '3410', enName: 'Xi' },
  {
    code: '341022',
    name: '休宁县',
    cityCode: '3410',
    enName: 'Xiuning',
  },
  { code: '341023', name: '黟县', cityCode: '3410', enName: 'Yi' },
  { code: '341024', name: '祁门县', cityCode: '3410', enName: 'Qimen' },
  {
    code: '341102',
    name: '琅琊区',
    cityCode: '3411',
    enName: 'Langya',
  },
  {
    code: '341103',
    name: '南谯区',
    cityCode: '3411',
    enName: 'Nanqiao',
  },
  { code: '341122', name: '来安县', cityCode: '3411', enName: 'Laian' },
  {
    code: '341124',
    name: '全椒县',
    cityCode: '3411',
    enName: 'Quanjiao',
  },
  {
    code: '341125',
    name: '定远县',
    cityCode: '3411',
    enName: 'Dingyuan',
  },
  {
    code: '341126',
    name: '凤阳县',
    cityCode: '3411',
    enName: 'Fengyang',
  },
  {
    code: '341181',
    name: '天长市',
    cityCode: '3411',
    enName: 'Tianchang',
  },
  {
    code: '341182',
    name: '明光市',
    cityCode: '3411',
    enName: 'Mingguang',
  },
  {
    code: '341202',
    name: '颍州区',
    cityCode: '3412',
    enName: 'Yingzhou',
  },
  {
    code: '341203',
    name: '颍东区',
    cityCode: '3412',
    enName: 'Yingdong',
  },
  {
    code: '341204',
    name: '颍泉区',
    cityCode: '3412',
    enName: 'Yinganqu',
  },
  { code: '341221', name: '临泉县', cityCode: '3412', enName: 'Linan' },
  { code: '341222', name: '太和县', cityCode: '3412', enName: 'Taihe' },
  { code: '341225', name: '阜南县', cityCode: '3412', enName: 'Funan' },
  {
    code: '341226',
    name: '颍上县',
    cityCode: '3412',
    enName: 'Yingshang',
  },
  {
    code: '341282',
    name: '界首市',
    cityCode: '3412',
    enName: 'Jieshou',
  },
  {
    code: '341302',
    name: '埇桥区',
    cityCode: '3413',
    enName: 'Yongqiao',
  },
  {
    code: '341321',
    name: '砀山县',
    cityCode: '3413',
    enName: 'Dangshan',
  },
  { code: '341322', name: '萧县', cityCode: '3413', enName: 'Xiao' },
  {
    code: '341323',
    name: '灵璧县',
    cityCode: '3413',
    enName: 'Lingbi',
  },
  { code: '341324', name: '泗县', cityCode: '3413', enName: 'Si' },
  { code: '341502', name: '金安区', cityCode: '3415', enName: 'Jinan' },
  { code: '341503', name: '裕安区', cityCode: '3415', enName: 'Yuan' },
  { code: '341504', name: '叶集区', cityCode: '3415', enName: 'Yeji' },
  {
    code: '341522',
    name: '霍邱县',
    cityCode: '3415',
    enName: 'Huoqiu',
  },
  {
    code: '341523',
    name: '舒城县',
    cityCode: '3415',
    enName: 'Shucheng',
  },
  {
    code: '341524',
    name: '金寨县',
    cityCode: '3415',
    enName: 'Jinzhai',
  },
  {
    code: '341525',
    name: '霍山县',
    cityCode: '3415',
    enName: 'Huoshan',
  },
  {
    code: '341602',
    name: '谯城区',
    cityCode: '3416',
    enName: 'Qiaocheng',
  },
  {
    code: '341621',
    name: '涡阳县',
    cityCode: '3416',
    enName: 'Woyang',
  },
  {
    code: '341622',
    name: '蒙城县',
    cityCode: '3416',
    enName: 'Mengcheng',
  },
  { code: '341623', name: '利辛县', cityCode: '3416', enName: 'Lixin' },
  {
    code: '341702',
    name: '贵池区',
    cityCode: '3417',
    enName: 'Guichi',
  },
  {
    code: '341721',
    name: '东至县',
    cityCode: '3417',
    enName: 'Dongzhi',
  },
  {
    code: '341722',
    name: '石台县',
    cityCode: '3417',
    enName: 'Shitai',
  },
  {
    code: '341723',
    name: '青阳县',
    cityCode: '3417',
    enName: 'Qingyang',
  },
  {
    code: '341802',
    name: '宣州区',
    cityCode: '3418',
    enName: 'Xuanzhou',
  },
  {
    code: '341821',
    name: '郎溪县',
    cityCode: '3418',
    enName: 'Langxi',
  },
  {
    code: '341822',
    name: '广德县',
    cityCode: '3418',
    enName: 'Guangde',
  },
  { code: '341823', name: '泾县', cityCode: '3418', enName: 'Jing' },
  { code: '341824', name: '绩溪县', cityCode: '3418', enName: 'Jixi' },
  {
    code: '341825',
    name: '旌德县',
    cityCode: '3418',
    enName: 'Jingde',
  },
  {
    code: '341881',
    name: '宁国市',
    cityCode: '3418',
    enName: 'Ningguo',
  },
  { code: '350102', name: '鼓楼区', cityCode: '3501', enName: 'Gulou' },
  {
    code: '350103',
    name: '台江区',
    cityCode: '3501',
    enName: 'Taijiang',
  },
  {
    code: '350104',
    name: '仓山区',
    cityCode: '3501',
    enName: 'Cangshan',
  },
  { code: '350105', name: '马尾区', cityCode: '3501', enName: 'Mawei' },
  { code: '350111', name: '晋安区', cityCode: '3501', enName: 'Jinan' },
  {
    code: '350121',
    name: '闽侯县',
    cityCode: '3501',
    enName: 'Minhou',
  },
  {
    code: '350122',
    name: '连江县',
    cityCode: '3501',
    enName: 'Lianjiang',
  },
  {
    code: '350123',
    name: '罗源县',
    cityCode: '3501',
    enName: 'Luoyuan',
  },
  {
    code: '350124',
    name: '闽清县',
    cityCode: '3501',
    enName: 'Minqing',
  },
  {
    code: '350125',
    name: '永泰县',
    cityCode: '3501',
    enName: 'Yongtai',
  },
  {
    code: '350128',
    name: '平潭县',
    cityCode: '3501',
    enName: 'Pingtan',
  },
  {
    code: '350181',
    name: '福清市',
    cityCode: '3501',
    enName: 'Fuqing',
  },
  {
    code: '350182',
    name: '长乐市',
    cityCode: '3501',
    enName: 'Changle',
  },
  {
    code: '350203',
    name: '思明区',
    cityCode: '3502',
    enName: 'Siming',
  },
  {
    code: '350205',
    name: '海沧区',
    cityCode: '3502',
    enName: 'Haicang',
  },
  { code: '350206', name: '湖里区', cityCode: '3502', enName: 'Huli' },
  { code: '350211', name: '集美区', cityCode: '3502', enName: 'Jimei' },
  {
    code: '350212',
    name: '同安区',
    cityCode: '3502',
    enName: 'Tongan',
  },
  {
    code: '350213',
    name: '翔安区',
    cityCode: '3502',
    enName: 'Xiangan',
  },
  {
    code: '350302',
    name: '城厢区',
    cityCode: '3503',
    enName: 'Chengg',
  },
  {
    code: '350303',
    name: '涵江区',
    cityCode: '3503',
    enName: 'Hanjiang',
  },
  {
    code: '350304',
    name: '荔城区',
    cityCode: '3503',
    enName: 'Licheng',
  },
  { code: '350305', name: '秀屿区', cityCode: '3503', enName: 'Xiuyu' },
  {
    code: '350322',
    name: '仙游县',
    cityCode: '3503',
    enName: 'Xianyou',
  },
  {
    code: '350402',
    name: '梅列区',
    cityCode: '3504',
    enName: 'Meilie',
  },
  {
    code: '350403',
    name: '三元区',
    cityCode: '3504',
    enName: 'Sanyuan',
  },
  {
    code: '350421',
    name: '明溪县',
    cityCode: '3504',
    enName: 'Mingxi',
  },
  {
    code: '350423',
    name: '清流县',
    cityCode: '3504',
    enName: 'Qingliu',
  },
  {
    code: '350424',
    name: '宁化县',
    cityCode: '3504',
    enName: 'Ninghua',
  },
  {
    code: '350425',
    name: '大田县',
    cityCode: '3504',
    enName: 'Datian',
  },
  { code: '350426', name: '尤溪县', cityCode: '3504', enName: 'Youxi' },
  { code: '350427', name: '沙县', cityCode: '3504', enName: 'Sha' },
  {
    code: '350428',
    name: '将乐县',
    cityCode: '3504',
    enName: 'Jiangle',
  },
  {
    code: '350429',
    name: '泰宁县',
    cityCode: '3504',
    enName: 'Taining',
  },
  {
    code: '350430',
    name: '建宁县',
    cityCode: '3504',
    enName: 'Jianning',
  },
  {
    code: '350481',
    name: '永安市',
    cityCode: '3504',
    enName: 'Yongan',
  },
  {
    code: '350502',
    name: '鲤城区',
    cityCode: '3505',
    enName: 'Licheng',
  },
  {
    code: '350503',
    name: '丰泽区',
    cityCode: '3505',
    enName: 'Fengze',
  },
  {
    code: '350504',
    name: '洛江区',
    cityCode: '3505',
    enName: 'Luojiang',
  },
  {
    code: '350505',
    name: '泉港区',
    cityCode: '3505',
    enName: 'Quangang',
  },
  { code: '350521', name: '惠安县', cityCode: '3505', enName: 'Huian' },
  { code: '350524', name: '安溪县', cityCode: '3505', enName: 'Anxi' },
  {
    code: '350525',
    name: '永春县',
    cityCode: '3505',
    enName: 'Yongchun',
  },
  { code: '350526', name: '德化县', cityCode: '3505', enName: 'Dehua' },
  {
    code: '350527',
    name: '金门县',
    cityCode: '3505',
    enName: 'Jinmen',
  },
  {
    code: '350581',
    name: '石狮市',
    cityCode: '3505',
    enName: 'Shishi',
  },
  {
    code: '350582',
    name: '晋江市',
    cityCode: '3505',
    enName: 'Jinjiang',
  },
  { code: '350583', name: '南安市', cityCode: '3505', enName: 'Nanan' },
  {
    code: '350602',
    name: '芗城区',
    cityCode: '3506',
    enName: 'Xiangcheng',
  },
  {
    code: '350603',
    name: '龙文区',
    cityCode: '3506',
    enName: 'Longwen',
  },
  {
    code: '350622',
    name: '云霄县',
    cityCode: '3506',
    enName: 'Yunxiao',
  },
  {
    code: '350623',
    name: '漳浦县',
    cityCode: '3506',
    enName: 'Zhangpu',
  },
  {
    code: '350624',
    name: '诏安县',
    cityCode: '3506',
    enName: 'Zhaoan',
  },
  {
    code: '350625',
    name: '长泰县',
    cityCode: '3506',
    enName: 'Changtai',
  },
  {
    code: '350626',
    name: '东山县',
    cityCode: '3506',
    enName: 'Dongshan',
  },
  {
    code: '350627',
    name: '南靖县',
    cityCode: '3506',
    enName: 'Nanjing',
  },
  {
    code: '350628',
    name: '平和县',
    cityCode: '3506',
    enName: 'Pinghe',
  },
  { code: '350629', name: '华安县', cityCode: '3506', enName: 'Huaan' },
  {
    code: '350681',
    name: '龙海市',
    cityCode: '3506',
    enName: 'Longhai',
  },
  {
    code: '350702',
    name: '延平区',
    cityCode: '3507',
    enName: 'Yanping',
  },
  {
    code: '350703',
    name: '建阳区',
    cityCode: '3507',
    enName: 'Jianyang',
  },
  {
    code: '350721',
    name: '顺昌县',
    cityCode: '3507',
    enName: 'Shunchang',
  },
  {
    code: '350722',
    name: '浦城县',
    cityCode: '3507',
    enName: 'Pucheng',
  },
  {
    code: '350723',
    name: '光泽县',
    cityCode: '3507',
    enName: 'Guangze',
  },
  {
    code: '350724',
    name: '松溪县',
    cityCode: '3507',
    enName: 'Songxi',
  },
  {
    code: '350725',
    name: '政和县',
    cityCode: '3507',
    enName: 'Zhenghe',
  },
  {
    code: '350781',
    name: '邵武市',
    cityCode: '3507',
    enName: 'Shaowu',
  },
  {
    code: '350782',
    name: '武夷山市',
    cityCode: '3507',
    enName: 'Wuyishan',
  },
  {
    code: '350783',
    name: '建瓯市',
    cityCode: '3507',
    enName: 'Jianou',
  },
  {
    code: '350802',
    name: '新罗区',
    cityCode: '3508',
    enName: 'Xinluo',
  },
  {
    code: '350803',
    name: '永定区',
    cityCode: '3508',
    enName: 'Yongding',
  },
  {
    code: '350821',
    name: '长汀县',
    cityCode: '3508',
    enName: 'Changting',
  },
  {
    code: '350823',
    name: '上杭县',
    cityCode: '3508',
    enName: 'Shanghang',
  },
  {
    code: '350824',
    name: '武平县',
    cityCode: '3508',
    enName: 'Wuping',
  },
  {
    code: '350825',
    name: '连城县',
    cityCode: '3508',
    enName: 'Liancheng',
  },
  {
    code: '350881',
    name: '漳平市',
    cityCode: '3508',
    enName: 'Zhangping',
  },
  {
    code: '350902',
    name: '蕉城区',
    cityCode: '3509',
    enName: 'Jiaocheng',
  },
  { code: '350921', name: '霞浦县', cityCode: '3509', enName: 'Xiapu' },
  {
    code: '350922',
    name: '古田县',
    cityCode: '3509',
    enName: 'Gutian',
  },
  {
    code: '350923',
    name: '屏南县',
    cityCode: '3509',
    enName: 'Pingnan',
  },
  {
    code: '350924',
    name: '寿宁县',
    cityCode: '3509',
    enName: 'Shouning',
  },
  {
    code: '350925',
    name: '周宁县',
    cityCode: '3509',
    enName: 'Zhouning',
  },
  {
    code: '350926',
    name: '柘荣县',
    cityCode: '3509',
    enName: 'Zherong',
  },
  { code: '350981', name: '福安市', cityCode: '3509', enName: 'Fuan' },
  {
    code: '350982',
    name: '福鼎市',
    cityCode: '3509',
    enName: 'Fuding',
  },
  {
    code: '360102',
    name: '东湖区',
    cityCode: '3601',
    enName: 'Donghu',
  },
  { code: '360103', name: '西湖区', cityCode: '3601', enName: 'Xihu' },
  {
    code: '360104',
    name: '青云谱区',
    cityCode: '3601',
    enName: 'Qingyunpu',
  },
  { code: '360105', name: '湾里区', cityCode: '3601', enName: 'Wanli' },
  {
    code: '360111',
    name: '青山湖区',
    cityCode: '3601',
    enName: 'Qingshanhu',
  },
  {
    code: '360112',
    name: '新建区',
    cityCode: '3601',
    enName: 'Xinjian',
  },
  {
    code: '360121',
    name: '南昌县',
    cityCode: '3601',
    enName: 'Nanchang',
  },
  { code: '360123', name: '安义县', cityCode: '3601', enName: 'Anyi' },
  {
    code: '360124',
    name: '进贤县',
    cityCode: '3601',
    enName: 'Jinxian',
  },
  {
    code: '360202',
    name: '昌江区',
    cityCode: '3602',
    enName: 'Changjiang',
  },
  {
    code: '360203',
    name: '珠山区',
    cityCode: '3602',
    enName: 'Zhushan',
  },
  {
    code: '360222',
    name: '浮梁县',
    cityCode: '3602',
    enName: 'Fuliang',
  },
  {
    code: '360281',
    name: '乐平市',
    cityCode: '3602',
    enName: 'Leping',
  },
  {
    code: '360302',
    name: '安源区',
    cityCode: '3603',
    enName: 'Anyuan',
  },
  {
    code: '360313',
    name: '湘东区',
    cityCode: '3603',
    enName: 'Xiangdong',
  },
  {
    code: '360321',
    name: '莲花县',
    cityCode: '3603',
    enName: 'Lianhua',
  },
  {
    code: '360322',
    name: '上栗县',
    cityCode: '3603',
    enName: 'Shangli',
  },
  { code: '360323', name: '芦溪县', cityCode: '3603', enName: 'Luxi' },
  {
    code: '360402',
    name: '濂溪区',
    cityCode: '3604',
    enName: 'Lianxi',
  },
  {
    code: '360403',
    name: '浔阳区',
    cityCode: '3604',
    enName: 'Xunyang',
  },
  {
    code: '360421',
    name: '九江县',
    cityCode: '3604',
    enName: 'Jiujiang',
  },
  {
    code: '360423',
    name: '武宁县',
    cityCode: '3604',
    enName: 'Wuning',
  },
  {
    code: '360424',
    name: '修水县',
    cityCode: '3604',
    enName: 'Xiushui',
  },
  {
    code: '360425',
    name: '永修县',
    cityCode: '3604',
    enName: 'Yongxiu',
  },
  { code: '360426', name: '德安县', cityCode: '3604', enName: 'Dean' },
  {
    code: '360428',
    name: '都昌县',
    cityCode: '3604',
    enName: 'Douchang',
  },
  { code: '360429', name: '湖口县', cityCode: '3604', enName: 'Hukou' },
  {
    code: '360430',
    name: '彭泽县',
    cityCode: '3604',
    enName: 'Pengze',
  },
  {
    code: '360481',
    name: '瑞昌市',
    cityCode: '3604',
    enName: 'Ruichang',
  },
  {
    code: '360482',
    name: '共青城市',
    cityCode: '3604',
    enName: 'Gongqingcheng',
  },
  {
    code: '360483',
    name: '庐山市',
    cityCode: '3604',
    enName: 'Lushan',
  },
  {
    code: '360502',
    name: '渝水区',
    cityCode: '3605',
    enName: 'Yushui',
  },
  { code: '360521', name: '分宜县', cityCode: '3605', enName: 'Fenyi' },
  { code: '360602', name: '月湖区', cityCode: '3606', enName: 'Yuehu' },
  {
    code: '360622',
    name: '余江县',
    cityCode: '3606',
    enName: 'Yujiang',
  },
  { code: '360681', name: '贵溪市', cityCode: '3606', enName: 'Guixi' },
  {
    code: '360702',
    name: '章贡区',
    cityCode: '3607',
    enName: 'Zhanggong',
  },
  {
    code: '360703',
    name: '南康区',
    cityCode: '3607',
    enName: 'Nankang',
  },
  { code: '360721', name: '赣县', cityCode: '3607', enName: 'Gan' },
  {
    code: '360722',
    name: '信丰县',
    cityCode: '3607',
    enName: 'Xinfeng',
  },
  { code: '360723', name: '大余县', cityCode: '3607', enName: 'Dayu' },
  {
    code: '360724',
    name: '上犹县',
    cityCode: '3607',
    enName: 'Shangyou',
  },
  {
    code: '360725',
    name: '崇义县',
    cityCode: '3607',
    enName: 'Chongyi',
  },
  {
    code: '360726',
    name: '安远县',
    cityCode: '3607',
    enName: 'Anyuan',
  },
  {
    code: '360727',
    name: '龙南县',
    cityCode: '3607',
    enName: 'Longnan',
  },
  {
    code: '360728',
    name: '定南县',
    cityCode: '3607',
    enName: 'Dingnan',
  },
  {
    code: '360729',
    name: '全南县',
    cityCode: '3607',
    enName: 'Quannan',
  },
  {
    code: '360730',
    name: '宁都县',
    cityCode: '3607',
    enName: 'Ningdou',
  },
  { code: '360731', name: '于都县', cityCode: '3607', enName: 'Yudou' },
  {
    code: '360732',
    name: '兴国县',
    cityCode: '3607',
    enName: 'Xingguo',
  },
  {
    code: '360733',
    name: '会昌县',
    cityCode: '3607',
    enName: 'Huichang',
  },
  { code: '360734', name: '寻乌县', cityCode: '3607', enName: 'Xunwu' },
  {
    code: '360735',
    name: '石城县',
    cityCode: '3607',
    enName: 'Shicheng',
  },
  {
    code: '360781',
    name: '瑞金市',
    cityCode: '3607',
    enName: 'Ruijin',
  },
  {
    code: '360802',
    name: '吉州区',
    cityCode: '3608',
    enName: 'Jizhou',
  },
  {
    code: '360803',
    name: '青原区',
    cityCode: '3608',
    enName: 'Qingyuan',
  },
  { code: '360821', name: '吉安县', cityCode: '3608', enName: 'Jian' },
  {
    code: '360822',
    name: '吉水县',
    cityCode: '3608',
    enName: 'Jishui',
  },
  {
    code: '360823',
    name: '峡江县',
    cityCode: '3608',
    enName: 'Xiajiang',
  },
  {
    code: '360824',
    name: '新干县',
    cityCode: '3608',
    enName: 'Xingan',
  },
  {
    code: '360825',
    name: '永丰县',
    cityCode: '3608',
    enName: 'Yongfeng',
  },
  { code: '360826', name: '泰和县', cityCode: '3608', enName: 'Taihe' },
  {
    code: '360827',
    name: '遂川县',
    cityCode: '3608',
    enName: 'Suichuan',
  },
  { code: '360828', name: '万安县', cityCode: '3608', enName: 'Wanan' },
  { code: '360829', name: '安福县', cityCode: '3608', enName: 'Anfu' },
  {
    code: '360830',
    name: '永新县',
    cityCode: '3608',
    enName: 'Yongxin',
  },
  {
    code: '360881',
    name: '井冈山市',
    cityCode: '3608',
    enName: 'Jinggangshan',
  },
  {
    code: '360902',
    name: '袁州区',
    cityCode: '3609',
    enName: 'Yuanzhou',
  },
  {
    code: '360921',
    name: '奉新县',
    cityCode: '3609',
    enName: 'Fengxin',
  },
  {
    code: '360922',
    name: '万载县',
    cityCode: '3609',
    enName: 'Wanzai',
  },
  {
    code: '360923',
    name: '上高县',
    cityCode: '3609',
    enName: 'Shanggao',
  },
  {
    code: '360924',
    name: '宜丰县',
    cityCode: '3609',
    enName: 'Yifeng',
  },
  {
    code: '360925',
    name: '靖安县',
    cityCode: '3609',
    enName: 'Jingan',
  },
  {
    code: '360926',
    name: '铜鼓县',
    cityCode: '3609',
    enName: 'Tonggu',
  },
  {
    code: '360981',
    name: '丰城市',
    cityCode: '3609',
    enName: 'Fengcheng',
  },
  {
    code: '360982',
    name: '樟树市',
    cityCode: '3609',
    enName: 'Zhangshu',
  },
  {
    code: '360983',
    name: '高安市',
    cityCode: '3609',
    enName: 'Gaoan',
  },
  {
    code: '361002',
    name: '临川区',
    cityCode: '3610',
    enName: 'Linchuan',
  },
  {
    code: '361021',
    name: '南城县',
    cityCode: '3610',
    enName: 'Nancheng',
  },
  {
    code: '361022',
    name: '黎川县',
    cityCode: '3610',
    enName: 'Lichuan',
  },
  {
    code: '361023',
    name: '南丰县',
    cityCode: '3610',
    enName: 'Nanfeng',
  },
  {
    code: '361024',
    name: '崇仁县',
    cityCode: '3610',
    enName: 'Chongren',
  },
  {
    code: '361025',
    name: '乐安县',
    cityCode: '3610',
    enName: 'Lean',
  },
  {
    code: '361026',
    name: '宜黄县',
    cityCode: '3610',
    enName: 'Yihuang',
  },
  {
    code: '361027',
    name: '金溪县',
    cityCode: '3610',
    enName: 'Jinxi',
  },
  {
    code: '361028',
    name: '资溪县',
    cityCode: '3610',
    enName: 'Zixi',
  },
  {
    code: '361029',
    name: '东乡县',
    cityCode: '3610',
    enName: 'Donggxian',
  },
  {
    code: '361030',
    name: '广昌县',
    cityCode: '3610',
    enName: 'Guangchang',
  },
  {
    code: '361102',
    name: '信州区',
    cityCode: '3611',
    enName: 'Xinzhou',
  },
  {
    code: '361103',
    name: '广丰区',
    cityCode: '3611',
    enName: 'Guangfeng',
  },
  {
    code: '361121',
    name: '上饶县',
    cityCode: '3611',
    enName: 'Shangrao',
  },
  {
    code: '361123',
    name: '玉山县',
    cityCode: '3611',
    enName: 'Yushan',
  },
  {
    code: '361124',
    name: '铅山县',
    cityCode: '3611',
    enName: 'Yanshan',
  },
  {
    code: '361125',
    name: '横峰县',
    cityCode: '3611',
    enName: 'Hengfeng',
  },
  {
    code: '361126',
    name: '弋阳县',
    cityCode: '3611',
    enName: 'Yiyang',
  },
  {
    code: '361127',
    name: '余干县',
    cityCode: '3611',
    enName: 'Yugan',
  },
  {
    code: '361128',
    name: '鄱阳县',
    cityCode: '3611',
    enName: 'Poyang',
  },
  {
    code: '361129',
    name: '万年县',
    cityCode: '3611',
    enName: 'Wannian',
  },
  {
    code: '361130',
    name: '婺源县',
    cityCode: '3611',
    enName: 'Wuyuan',
  },
  {
    code: '361181',
    name: '德兴市',
    cityCode: '3611',
    enName: 'Dexing',
  },
  {
    code: '370102',
    name: '历下区',
    cityCode: '3701',
    enName: 'Lixia',
  },
  {
    code: '370103',
    name: '市中区',
    cityCode: '3701',
    enName: 'Shizhong',
  },
  {
    code: '370104',
    name: '槐荫区',
    cityCode: '3701',
    enName: 'Huaiyin',
  },
  {
    code: '370105',
    name: '天桥区',
    cityCode: '3701',
    enName: 'Tianqiao',
  },
  {
    code: '370112',
    name: '历城区',
    cityCode: '3701',
    enName: 'Licheng',
  },
  {
    code: '370113',
    name: '长清区',
    cityCode: '3701',
    enName: 'Changqing',
  },
  {
    code: '370124',
    name: '平阴县',
    cityCode: '3701',
    enName: 'Pingyin',
  },
  {
    code: '370125',
    name: '济阳县',
    cityCode: '3701',
    enName: 'Jiyang',
  },
  {
    code: '370126',
    name: '商河县',
    cityCode: '3701',
    enName: 'Shanghe',
  },
  {
    code: '370181',
    name: '章丘市',
    cityCode: '3701',
    enName: 'Zhangqiu',
  },
  {
    code: '370202',
    name: '市南区',
    cityCode: '3702',
    enName: 'Shinan',
  },
  {
    code: '370203',
    name: '市北区',
    cityCode: '3702',
    enName: 'Shibei',
  },
  {
    code: '370211',
    name: '黄岛区',
    cityCode: '3702',
    enName: 'Huangdao',
  },
  {
    code: '370212',
    name: '崂山区',
    cityCode: '3702',
    enName: 'Laoshan',
  },
  {
    code: '370213',
    name: '李沧区',
    cityCode: '3702',
    enName: 'Licang',
  },
  {
    code: '370214',
    name: '城阳区',
    cityCode: '3702',
    enName: 'Chengyang',
  },
  {
    code: '370281',
    name: '胶州市',
    cityCode: '3702',
    enName: 'Jiaozhou',
  },
  {
    code: '370282',
    name: '即墨市',
    cityCode: '3702',
    enName: 'Jimo',
  },
  {
    code: '370283',
    name: '平度市',
    cityCode: '3702',
    enName: 'Pingdu',
  },
  {
    code: '370285',
    name: '莱西市',
    cityCode: '3702',
    enName: 'Laixi',
  },
  {
    code: '370302',
    name: '淄川区',
    cityCode: '3703',
    enName: 'Zichuan',
  },
  {
    code: '370303',
    name: '张店区',
    cityCode: '3703',
    enName: 'Zhangdian',
  },
  {
    code: '370304',
    name: '博山区',
    cityCode: '3703',
    enName: 'Boshan',
  },
  {
    code: '370305',
    name: '临淄区',
    cityCode: '3703',
    enName: 'Linzi',
  },
  {
    code: '370306',
    name: '周村区',
    cityCode: '3703',
    enName: 'Zhoucun',
  },
  {
    code: '370321',
    name: '桓台县',
    cityCode: '3703',
    enName: 'Huantai',
  },
  {
    code: '370322',
    name: '高青县',
    cityCode: '3703',
    enName: 'Gaoqing',
  },
  {
    code: '370323',
    name: '沂源县',
    cityCode: '3703',
    enName: 'Yiyuan',
  },
  {
    code: '370402',
    name: '市中区',
    cityCode: '3704',
    enName: 'Shizhong',
  },
  {
    code: '370403',
    name: '薛城区',
    cityCode: '3704',
    enName: 'Xuecheng',
  },
  {
    code: '370404',
    name: '峄城区',
    cityCode: '3704',
    enName: 'Yicheng',
  },
  {
    code: '370405',
    name: '台儿庄区',
    cityCode: '3704',
    enName: 'Taierzhuang',
  },
  {
    code: '370406',
    name: '山亭区',
    cityCode: '3704',
    enName: 'Shanting',
  },
  {
    code: '370481',
    name: '滕州',
    cityCode: '3704',
    enName: 'Tengzhoushi',
  },
  {
    code: '370502',
    name: '东营区',
    cityCode: '3705',
    enName: 'Dongying',
  },
  {
    code: '370503',
    name: '河口区',
    cityCode: '3705',
    enName: 'Hekou',
  },
  {
    code: '370505',
    name: '垦利区',
    cityCode: '3705',
    enName: 'Kenli',
  },
  {
    code: '370522',
    name: '利津县',
    cityCode: '3705',
    enName: 'Lijin',
  },
  {
    code: '370523',
    name: '广饶县',
    cityCode: '3705',
    enName: 'Guangrao',
  },
  {
    code: '370602',
    name: '芝罘区',
    cityCode: '3706',
    enName: 'Zhifu',
  },
  {
    code: '370611',
    name: '福山区',
    cityCode: '3706',
    enName: 'Fushan',
  },
  {
    code: '370612',
    name: '牟平区',
    cityCode: '3706',
    enName: 'Muping',
  },
  {
    code: '370613',
    name: '莱山区',
    cityCode: '3706',
    enName: 'Laishan',
  },
  {
    code: '370634',
    name: '长岛县',
    cityCode: '3706',
    enName: 'Changdao',
  },
  {
    code: '370681',
    name: '龙口市',
    cityCode: '3706',
    enName: 'Longkou',
  },
  {
    code: '370682',
    name: '莱阳市',
    cityCode: '3706',
    enName: 'Laiyang',
  },
  {
    code: '370683',
    name: '莱州市',
    cityCode: '3706',
    enName: 'Laizhou',
  },
  {
    code: '370684',
    name: '蓬莱市',
    cityCode: '3706',
    enName: 'Penglai',
  },
  {
    code: '370685',
    name: '招远市',
    cityCode: '3706',
    enName: 'Zhaoyuan',
  },
  {
    code: '370686',
    name: '栖霞市',
    cityCode: '3706',
    enName: 'Qixia',
  },
  {
    code: '370687',
    name: '海阳市',
    cityCode: '3706',
    enName: 'Haiyang',
  },
  {
    code: '370702',
    name: '潍城区',
    cityCode: '3707',
    enName: 'Weicheng',
  },
  {
    code: '370703',
    name: '寒亭区',
    cityCode: '3707',
    enName: 'Hanting',
  },
  {
    code: '370704',
    name: '坊子区',
    cityCode: '3707',
    enName: 'Fangzi',
  },
  {
    code: '370705',
    name: '奎文区',
    cityCode: '3707',
    enName: 'Kuiwen',
  },
  {
    code: '370724',
    name: '临朐县',
    cityCode: '3707',
    enName: 'Lin',
  },
  {
    code: '370725',
    name: '昌乐县',
    cityCode: '3707',
    enName: 'Changle',
  },
  {
    code: '370781',
    name: '青州市',
    cityCode: '3707',
    enName: 'Qingzhou',
  },
  {
    code: '370782',
    name: '诸城市',
    cityCode: '3707',
    enName: 'Zhucheng',
  },
  {
    code: '370783',
    name: '寿光市',
    cityCode: '3707',
    enName: 'Shouguang',
  },
  {
    code: '370784',
    name: '安丘市',
    cityCode: '3707',
    enName: 'Anqiu',
  },
  {
    code: '370785',
    name: '高密市',
    cityCode: '3707',
    enName: 'Gaomi',
  },
  {
    code: '370786',
    name: '昌邑市',
    cityCode: '3707',
    enName: 'Changyi',
  },
  {
    code: '370811',
    name: '任城区',
    cityCode: '3708',
    enName: 'Rencheng',
  },
  {
    code: '370812',
    name: '兖州区',
    cityCode: '3708',
    enName: 'Yanzhou',
  },
  {
    code: '370826',
    name: '微山县',
    cityCode: '3708',
    enName: 'Weishan',
  },
  {
    code: '370827',
    name: '鱼台县',
    cityCode: '3708',
    enName: 'Yutai',
  },
  {
    code: '370828',
    name: '金乡县',
    cityCode: '3708',
    enName: 'Jingxian',
  },
  {
    code: '370829',
    name: '嘉祥县',
    cityCode: '3708',
    enName: 'Jiagxian',
  },
  {
    code: '370830',
    name: '汶上县',
    cityCode: '3708',
    enName: 'Wenshang',
  },
  {
    code: '370831',
    name: '泗水县',
    cityCode: '3708',
    enName: 'Sishui',
  },
  {
    code: '370832',
    name: '梁山县',
    cityCode: '3708',
    enName: 'Liangshan',
  },
  {
    code: '370881',
    name: '曲阜市',
    cityCode: '3708',
    enName: 'Qufu',
  },
  {
    code: '370883',
    name: '邹城市',
    cityCode: '3708',
    enName: 'Zoucheng',
  },
  {
    code: '370902',
    name: '泰山区',
    cityCode: '3709',
    enName: 'Taishan',
  },
  {
    code: '370911',
    name: '岱岳区',
    cityCode: '3709',
    enName: 'Daiyue',
  },
  {
    code: '370921',
    name: '宁阳县',
    cityCode: '3709',
    enName: 'Ningyang',
  },
  {
    code: '370923',
    name: '东平县',
    cityCode: '3709',
    enName: 'Dongping',
  },
  {
    code: '370982',
    name: '新泰市',
    cityCode: '3709',
    enName: 'Xintai',
  },
  {
    code: '370983',
    name: '肥城市',
    cityCode: '3709',
    enName: 'Feicheng',
  },
  {
    code: '371002',
    name: '环翠区',
    cityCode: '3710',
    enName: 'Huancui',
  },
  {
    code: '371003',
    name: '文登区',
    cityCode: '3710',
    enName: 'Wendeng',
  },
  {
    code: '371082',
    name: '荣成市',
    cityCode: '3710',
    enName: 'Rongcheng',
  },
  {
    code: '371083',
    name: '乳山市',
    cityCode: '3710',
    enName: 'Rushan',
  },
  {
    code: '371102',
    name: '东港区',
    cityCode: '3711',
    enName: 'Donggang',
  },
  {
    code: '371103',
    name: '岚山区',
    cityCode: '3711',
    enName: 'Lanshan',
  },
  {
    code: '371121',
    name: '五莲县',
    cityCode: '3711',
    enName: 'Wulian',
  },
  { code: '371122', name: '莒县', cityCode: '3711', enName: 'Ju' },
  {
    code: '371202',
    name: '莱城区',
    cityCode: '3712',
    enName: 'Laicheng',
  },
  {
    code: '371203',
    name: '钢城区',
    cityCode: '3712',
    enName: 'Gangcheng',
  },
  {
    code: '371302',
    name: '兰山区',
    cityCode: '3713',
    enName: 'Lanshan',
  },
  {
    code: '371311',
    name: '罗庄区',
    cityCode: '3713',
    enName: 'Luozhuang',
  },
  {
    code: '371312',
    name: '河东区',
    cityCode: '3713',
    enName: 'Hedong',
  },
  {
    code: '371321',
    name: '沂南县',
    cityCode: '3713',
    enName: 'Yinan',
  },
  {
    code: '371322',
    name: '郯城县',
    cityCode: '3713',
    enName: 'Tancheng',
  },
  {
    code: '371323',
    name: '沂水县',
    cityCode: '3713',
    enName: 'Yishui',
  },
  {
    code: '371324',
    name: '兰陵县',
    cityCode: '3713',
    enName: 'Lanling',
  },
  {
    code: '371325',
    name: '费县',
    cityCode: '3713',
    enName: 'Fei',
  },
  {
    code: '371326',
    name: '平邑县',
    cityCode: '3713',
    enName: 'Pingyi',
  },
  {
    code: '371327',
    name: '莒南县',
    cityCode: '3713',
    enName: 'Junan',
  },
  {
    code: '371328',
    name: '蒙阴县',
    cityCode: '3713',
    enName: 'Mengyin',
  },
  {
    code: '371329',
    name: '临沭县',
    cityCode: '3713',
    enName: 'Linshu',
  },
  {
    code: '371402',
    name: '德城区',
    cityCode: '3714',
    enName: 'Decheng',
  },
  {
    code: '371403',
    name: '陵城区',
    cityCode: '3714',
    enName: 'Lingcheng',
  },
  {
    code: '371422',
    name: '宁津县',
    cityCode: '3714',
    enName: 'Ningjin',
  },
  {
    code: '371423',
    name: '庆云县',
    cityCode: '3714',
    enName: 'Qingyun',
  },
  {
    code: '371424',
    name: '临邑县',
    cityCode: '3714',
    enName: 'Linyi',
  },
  {
    code: '371425',
    name: '齐河县',
    cityCode: '3714',
    enName: 'Qihe',
  },
  {
    code: '371426',
    name: '平原县',
    cityCode: '3714',
    enName: 'Pingyuan',
  },
  {
    code: '371427',
    name: '夏津县',
    cityCode: '3714',
    enName: 'Xiajin',
  },
  {
    code: '371428',
    name: '武城县',
    cityCode: '3714',
    enName: 'Wucheng',
  },
  {
    code: '371481',
    name: '乐陵市',
    cityCode: '3714',
    enName: 'Leling',
  },
  {
    code: '371482',
    name: '禹城市',
    cityCode: '3714',
    enName: 'Yucheng',
  },
  {
    code: '371502',
    name: '东昌府区',
    cityCode: '3715',
    enName: 'Dongchangfu',
  },
  {
    code: '371521',
    name: '阳谷县',
    cityCode: '3715',
    enName: 'Yanggu',
  },
  {
    code: '371522',
    name: '莘县',
    cityCode: '3715',
    enName: 'Shen',
  },
  {
    code: '371523',
    name: '茌平县',
    cityCode: '3715',
    enName: 'Chiping',
  },
  {
    code: '371524',
    name: '东阿县',
    cityCode: '3715',
    enName: 'Donge',
  },
  {
    code: '371525',
    name: '冠县',
    cityCode: '3715',
    enName: 'Guan',
  },
  {
    code: '371526',
    name: '高唐县',
    cityCode: '3715',
    enName: 'Gaotang',
  },
  {
    code: '371581',
    name: '临清市',
    cityCode: '3715',
    enName: 'Linqing',
  },
  {
    code: '371602',
    name: '滨城区',
    cityCode: '3716',
    enName: 'Bincheng',
  },
  {
    code: '371603',
    name: '沾化区',
    cityCode: '3716',
    enName: 'Zhanhua',
  },
  {
    code: '371621',
    name: '惠民县',
    cityCode: '3716',
    enName: 'Huimin',
  },
  {
    code: '371622',
    name: '阳信县',
    cityCode: '3716',
    enName: 'Yangxin',
  },
  {
    code: '371623',
    name: '无棣县',
    cityCode: '3716',
    enName: 'Wudi',
  },
  {
    code: '371625',
    name: '博兴县',
    cityCode: '3716',
    enName: 'Boxing',
  },
  {
    code: '371626',
    name: '邹平县',
    cityCode: '3716',
    enName: 'Zouping',
  },
  {
    code: '371702',
    name: '牡丹区',
    cityCode: '3717',
    enName: 'Mudan',
  },
  {
    code: '371703',
    name: '定陶区',
    cityCode: '3717',
    enName: 'Dingtao',
  },
  {
    code: '371721',
    name: '曹县',
    cityCode: '3717',
    enName: 'Cao',
  },
  {
    code: '371722',
    name: '单县',
    cityCode: '3717',
    enName: 'Dan',
  },
  {
    code: '371723',
    name: '成武县',
    cityCode: '3717',
    enName: 'Chengwu',
  },
  {
    code: '371724',
    name: '巨野县',
    cityCode: '3717',
    enName: 'Juye',
  },
  {
    code: '371725',
    name: '郓城县',
    cityCode: '3717',
    enName: 'Yuncheng',
  },
  {
    code: '371726',
    name: '鄄城县',
    cityCode: '3717',
    enName: 'Juancheng',
  },
  {
    code: '371728',
    name: '东明县',
    cityCode: '3717',
    enName: 'Dongming',
  },
  {
    code: '410102',
    name: '中原区',
    cityCode: '4101',
    enName: 'Zhongyuan',
  },
  {
    code: '410103',
    name: '二七区',
    cityCode: '4101',
    enName: 'Erqi',
  },
  {
    code: '410104',
    name: '管城回族区',
    cityCode: '4101',
    enName: 'Guanchenghuizu',
  },
  {
    code: '410105',
    name: '金水区',
    cityCode: '4101',
    enName: 'Jinshui',
  },
  {
    code: '410106',
    name: '上街区',
    cityCode: '4101',
    enName: 'Shangjie',
  },
  {
    code: '410108',
    name: '惠济区',
    cityCode: '4101',
    enName: 'Huiji',
  },
  {
    code: '410122',
    name: '中牟县',
    cityCode: '4101',
    enName: 'Zhongmu',
  },
  {
    code: '410181',
    name: '巩义市',
    cityCode: '4101',
    enName: 'Gongyi',
  },
  {
    code: '410182',
    name: '荥阳市',
    cityCode: '4101',
    enName: 'Xingyang',
  },
  {
    code: '410183',
    name: '新密市',
    cityCode: '4101',
    enName: 'Xinmi',
  },
  {
    code: '410184',
    name: '新郑市',
    cityCode: '4101',
    enName: 'Xinzheng',
  },
  {
    code: '410185',
    name: '登封市',
    cityCode: '4101',
    enName: 'Dengfeng',
  },
  {
    code: '410202',
    name: '龙亭区',
    cityCode: '4102',
    enName: 'Longting',
  },
  {
    code: '410203',
    name: '顺河回族区',
    cityCode: '4102',
    enName: 'Shunhe Hui Autonomous Region',
  },
  {
    code: '410204',
    name: '鼓楼区',
    cityCode: '4102',
    enName: 'Gulou',
  },
  {
    code: '410205',
    name: '禹王台区',
    cityCode: '4102',
    enName: 'Yuwangtai',
  },
  {
    code: '410211',
    name: '金明区',
    cityCode: '4102',
    enName: 'Jinming',
  },
  {
    code: '410212',
    name: '祥符区',
    cityCode: '4102',
    enName: 'Xiangfu',
  },
  {
    code: '410221',
    name: '杞县',
    cityCode: '4102',
    enName: 'Qi',
  },
  {
    code: '410222',
    name: '通许县',
    cityCode: '4102',
    enName: 'Tongxu',
  },
  {
    code: '410223',
    name: '尉氏县',
    cityCode: '4102',
    enName: 'Weishi',
  },
  {
    code: '410225',
    name: '兰考县',
    cityCode: '4102',
    enName: 'Lankao',
  },
  {
    code: '410302',
    name: '老城区',
    cityCode: '4103',
    enName: 'Laocheng',
  },
  {
    code: '410303',
    name: '西工区',
    cityCode: '4103',
    enName: 'Xigong',
  },
  {
    code: '410304',
    name: '瀍河回族区',
    cityCode: '4103',
    enName: 'Chanhehuizu',
  },
  {
    code: '410305',
    name: '涧西区',
    cityCode: '4103',
    enName: 'Jianxi',
  },
  {
    code: '410306',
    name: '吉利区',
    cityCode: '4103',
    enName: 'Jili',
  },
  {
    code: '410311',
    name: '洛龙区',
    cityCode: '4103',
    enName: 'Luolong',
  },
  {
    code: '410322',
    name: '孟津县',
    cityCode: '4103',
    enName: 'Mengjin',
  },
  {
    code: '410323',
    name: '新安县',
    cityCode: '4103',
    enName: 'Xinan',
  },
  {
    code: '410324',
    name: '栾川县',
    cityCode: '4103',
    enName: 'Luanchuan',
  },
  {
    code: '410325',
    name: '嵩县',
    cityCode: '4103',
    enName: 'Song',
  },
  {
    code: '410326',
    name: '汝阳县',
    cityCode: '4103',
    enName: 'Ruyang',
  },
  {
    code: '410327',
    name: '宜阳县',
    cityCode: '4103',
    enName: 'Yiyang',
  },
  {
    code: '410328',
    name: '洛宁县',
    cityCode: '4103',
    enName: 'Luoning',
  },
  {
    code: '410329',
    name: '伊川县',
    cityCode: '4103',
    enName: 'Yichuan',
  },
  {
    code: '410381',
    name: '偃师市',
    cityCode: '4103',
    enName: 'Yanshi',
  },
  {
    code: '410402',
    name: '新华区',
    cityCode: '4104',
    enName: 'Xinhua',
  },
  {
    code: '410403',
    name: '卫东区',
    cityCode: '4104',
    enName: 'Weidong',
  },
  {
    code: '410404',
    name: '石龙区',
    cityCode: '4104',
    enName: 'Shilong',
  },
  {
    code: '410411',
    name: '湛河区',
    cityCode: '4104',
    enName: 'Zhanhe',
  },
  {
    code: '410421',
    name: '宝丰县',
    cityCode: '4104',
    enName: 'Baofeng',
  },
  {
    code: '410422',
    name: '叶县',
    cityCode: '4104',
    enName: 'Ye',
  },
  {
    code: '410423',
    name: '鲁山县',
    cityCode: '4104',
    enName: 'Lushan',
  },
  {
    code: '410425',
    name: '郏县',
    cityCode: '4104',
    enName: 'Jia',
  },
  {
    code: '410481',
    name: '舞钢市',
    cityCode: '4104',
    enName: 'Wugang',
  },
  {
    code: '410482',
    name: '汝州市',
    cityCode: '4104',
    enName: 'Ruzhou',
  },
  {
    code: '410502',
    name: '文峰区',
    cityCode: '4105',
    enName: 'Wenfeng',
  },
  {
    code: '410503',
    name: '北关区',
    cityCode: '4105',
    enName: 'Beiguan',
  },
  {
    code: '410505',
    name: '殷都区',
    cityCode: '4105',
    enName: 'Yindou',
  },
  {
    code: '410506',
    name: '龙安区',
    cityCode: '4105',
    enName: 'Longan',
  },
  {
    code: '410522',
    name: '安阳县',
    cityCode: '4105',
    enName: 'Anyang',
  },
  {
    code: '410523',
    name: '汤阴县',
    cityCode: '4105',
    enName: 'Tangyin',
  },
  {
    code: '410526',
    name: '滑县',
    cityCode: '4105',
    enName: 'Hua',
  },
  {
    code: '410527',
    name: '内黄县',
    cityCode: '4105',
    enName: 'Neihuang',
  },
  {
    code: '410581',
    name: '林州市',
    cityCode: '4105',
    enName: 'Linzhou',
  },
  {
    code: '410602',
    name: '鹤山区',
    cityCode: '4106',
    enName: 'Heshan',
  },
  {
    code: '410603',
    name: '山城区',
    cityCode: '4106',
    enName: 'Shancheng',
  },
  {
    code: '410611',
    name: '淇滨区',
    cityCode: '4106',
    enName: 'Qibin',
  },
  {
    code: '410621',
    name: '浚县',
    cityCode: '4106',
    enName: 'Jun',
  },
  {
    code: '410622',
    name: '淇县',
    cityCode: '4106',
    enName: 'Qi',
  },
  {
    code: '410702',
    name: '红旗区',
    cityCode: '4107',
    enName: 'Hongqi',
  },
  {
    code: '410703',
    name: '卫滨区',
    cityCode: '4107',
    enName: 'Weibin',
  },
  {
    code: '410704',
    name: '凤泉区',
    cityCode: '4107',
    enName: 'Fenganqu',
  },
  {
    code: '410711',
    name: '牧野区',
    cityCode: '4107',
    enName: 'Muye',
  },
  {
    code: '410721',
    name: '新乡县',
    cityCode: '4107',
    enName: 'Xingxian',
  },
  {
    code: '410724',
    name: '获嘉县',
    cityCode: '4107',
    enName: 'Huojia',
  },
  {
    code: '410725',
    name: '原阳县',
    cityCode: '4107',
    enName: 'Yuanyang',
  },
  {
    code: '410726',
    name: '延津县',
    cityCode: '4107',
    enName: 'Yanjin',
  },
  {
    code: '410727',
    name: '封丘县',
    cityCode: '4107',
    enName: 'Fengqiu',
  },
  {
    code: '410728',
    name: '长垣县',
    cityCode: '4107',
    enName: 'Changyuan',
  },
  {
    code: '410781',
    name: '卫辉市',
    cityCode: '4107',
    enName: 'Weihui',
  },
  {
    code: '410782',
    name: '辉县市',
    cityCode: '4107',
    enName: 'Huixian',
  },
  {
    code: '410802',
    name: '解放区',
    cityCode: '4108',
    enName: 'Jiefang',
  },
  {
    code: '410803',
    name: '中站区',
    cityCode: '4108',
    enName: 'Zhongzhan',
  },
  {
    code: '410804',
    name: '马村区',
    cityCode: '4108',
    enName: 'Macun',
  },
  {
    code: '410811',
    name: '山阳区',
    cityCode: '4108',
    enName: 'Shanyang',
  },
  {
    code: '410821',
    name: '修武县',
    cityCode: '4108',
    enName: 'Xiuwu',
  },
  {
    code: '410822',
    name: '博爱县',
    cityCode: '4108',
    enName: 'Boai',
  },
  {
    code: '410823',
    name: '武陟县',
    cityCode: '4108',
    enName: 'Wuzhi',
  },
  {
    code: '410825',
    name: '温县',
    cityCode: '4108',
    enName: 'Wen',
  },
  {
    code: '410882',
    name: '沁阳市',
    cityCode: '4108',
    enName: 'Qinyang',
  },
  {
    code: '410883',
    name: '孟州市',
    cityCode: '4108',
    enName: 'Mengzhou',
  },
  {
    code: '410902',
    name: '华龙区',
    cityCode: '4109',
    enName: 'Hualong',
  },
  {
    code: '410922',
    name: '清丰县',
    cityCode: '4109',
    enName: 'Qingfeng',
  },
  {
    code: '410923',
    name: '南乐县',
    cityCode: '4109',
    enName: 'Nanle',
  },
  {
    code: '410926',
    name: '范县',
    cityCode: '4109',
    enName: 'Fan',
  },
  {
    code: '410927',
    name: '台前县',
    cityCode: '4109',
    enName: 'Taiqian',
  },
  {
    code: '410928',
    name: '濮阳县',
    cityCode: '4109',
    enName: 'Puyang',
  },
  {
    code: '411002',
    name: '魏都区',
    cityCode: '4110',
    enName: 'Weidou',
  },
  {
    code: '411023',
    name: '许昌县',
    cityCode: '4110',
    enName: 'Xuchang',
  },
  {
    code: '411024',
    name: '鄢陵县',
    cityCode: '4110',
    enName: 'Yanling',
  },
  {
    code: '411025',
    name: '襄城县',
    cityCode: '4110',
    enName: 'Xiangcheng',
  },
  {
    code: '411081',
    name: '禹州市',
    cityCode: '4110',
    enName: 'Yuzhou',
  },
  {
    code: '411082',
    name: '长葛市',
    cityCode: '4110',
    enName: 'Changge',
  },
  {
    code: '411102',
    name: '源汇区',
    cityCode: '4111',
    enName: 'Yuanhui',
  },
  {
    code: '411103',
    name: '郾城区',
    cityCode: '4111',
    enName: 'Yancheng',
  },
  {
    code: '411104',
    name: '召陵区',
    cityCode: '4111',
    enName: 'Zhaoling',
  },
  {
    code: '411121',
    name: '舞阳县',
    cityCode: '4111',
    enName: 'Wuyang',
  },
  {
    code: '411122',
    name: '临颍县',
    cityCode: '4111',
    enName: 'Linying',
  },
  {
    code: '411202',
    name: '湖滨区',
    cityCode: '4112',
    enName: 'Hubin',
  },
  {
    code: '411203',
    name: '陕州区',
    cityCode: '4112',
    enName: 'Shanzhou',
  },
  {
    code: '411221',
    name: '渑池县',
    cityCode: '4112',
    enName: 'Mianchi',
  },
  {
    code: '411224',
    name: '卢氏县',
    cityCode: '4112',
    enName: 'Lushi',
  },
  {
    code: '411281',
    name: '义马市',
    cityCode: '4112',
    enName: 'Yima',
  },
  {
    code: '411282',
    name: '灵宝市',
    cityCode: '4112',
    enName: 'Lingbao',
  },
  {
    code: '411302',
    name: '宛城区',
    cityCode: '4113',
    enName: 'Wancheng',
  },
  {
    code: '411303',
    name: '卧龙区',
    cityCode: '4113',
    enName: 'Wolong',
  },
  {
    code: '411321',
    name: '南召县',
    cityCode: '4113',
    enName: 'Nanzhao',
  },
  {
    code: '411322',
    name: '方城县',
    cityCode: '4113',
    enName: 'Fangcheng',
  },
  {
    code: '411323',
    name: '西峡县',
    cityCode: '4113',
    enName: 'Xixia',
  },
  {
    code: '411324',
    name: '镇平县',
    cityCode: '4113',
    enName: 'Zhenping',
  },
  {
    code: '411325',
    name: '内乡县',
    cityCode: '4113',
    enName: 'Neigxian',
  },
  {
    code: '411326',
    name: '淅川县',
    cityCode: '4113',
    enName: 'Xichuan',
  },
  {
    code: '411327',
    name: '社旗县',
    cityCode: '4113',
    enName: 'Sheqi',
  },
  {
    code: '411328',
    name: '唐河县',
    cityCode: '4113',
    enName: 'Tanghe',
  },
  {
    code: '411329',
    name: '新野县',
    cityCode: '4113',
    enName: 'Xinye',
  },
  {
    code: '411330',
    name: '桐柏县',
    cityCode: '4113',
    enName: 'Tongbai',
  },
  {
    code: '411381',
    name: '邓州市',
    cityCode: '4113',
    enName: 'Dengzhou',
  },
  {
    code: '411402',
    name: '梁园区',
    cityCode: '4114',
    enName: 'Liangyuan',
  },
  {
    code: '411403',
    name: '睢阳区',
    cityCode: '4114',
    enName: 'Suiyang',
  },
  {
    code: '411421',
    name: '民权县',
    cityCode: '4114',
    enName: 'Minan',
  },
  {
    code: '411422',
    name: '睢县',
    cityCode: '4114',
    enName: 'Sui',
  },
  {
    code: '411423',
    name: '宁陵县',
    cityCode: '4114',
    enName: 'Ningling',
  },
  {
    code: '411424',
    name: '柘城县',
    cityCode: '4114',
    enName: 'Zhecheng',
  },
  {
    code: '411425',
    name: '虞城县',
    cityCode: '4114',
    enName: 'Yucheng',
  },
  {
    code: '411426',
    name: '夏邑县',
    cityCode: '4114',
    enName: 'Xiayi',
  },
  {
    code: '411481',
    name: '永城市',
    cityCode: '4114',
    enName: 'Yongcheng',
  },
  {
    code: '411502',
    name: '浉河区',
    cityCode: '4115',
    enName: 'Shihe',
  },
  {
    code: '411503',
    name: '平桥区',
    cityCode: '4115',
    enName: 'Pingqiao',
  },
  {
    code: '411521',
    name: '罗山县',
    cityCode: '4115',
    enName: 'Luoshan',
  },
  {
    code: '411522',
    name: '光山县',
    cityCode: '4115',
    enName: 'Guangshan',
  },
  {
    code: '411523',
    name: '新县',
    cityCode: '4115',
    enName: 'Xin',
  },
  {
    code: '411524',
    name: '商城县',
    cityCode: '4115',
    enName: 'Shangcheng',
  },
  {
    code: '411525',
    name: '固始县',
    cityCode: '4115',
    enName: 'Gushi',
  },
  {
    code: '411526',
    name: '潢川县',
    cityCode: '4115',
    enName: 'Huangchuan',
  },
  {
    code: '411527',
    name: '淮滨县',
    cityCode: '4115',
    enName: 'Huaibin',
  },
  {
    code: '411528',
    name: '息县',
    cityCode: '4115',
    enName: 'Xi',
  },
  {
    code: '411602',
    name: '川汇区',
    cityCode: '4116',
    enName: 'Chuanhui',
  },
  {
    code: '411621',
    name: '扶沟县',
    cityCode: '4116',
    enName: 'Fugou',
  },
  {
    code: '411622',
    name: '西华县',
    cityCode: '4116',
    enName: 'Xihua',
  },
  {
    code: '411623',
    name: '商水县',
    cityCode: '4116',
    enName: 'Shangshui',
  },
  {
    code: '411624',
    name: '沈丘县',
    cityCode: '4116',
    enName: 'Shenqiu',
  },
  {
    code: '411625',
    name: '郸城县',
    cityCode: '4116',
    enName: 'Dancheng',
  },
  {
    code: '411626',
    name: '淮阳县',
    cityCode: '4116',
    enName: 'Huaiyang',
  },
  {
    code: '411627',
    name: '太康县',
    cityCode: '4116',
    enName: 'Taikang',
  },
  {
    code: '411628',
    name: '鹿邑县',
    cityCode: '4116',
    enName: 'Luyi',
  },
  {
    code: '411681',
    name: '项城市',
    cityCode: '4116',
    enName: 'Xiangcheng',
  },
  {
    code: '411702',
    name: '驿城区',
    cityCode: '4117',
    enName: 'Yicheng',
  },
  {
    code: '411721',
    name: '西平县',
    cityCode: '4117',
    enName: 'Xiping',
  },
  {
    code: '411722',
    name: '上蔡县',
    cityCode: '4117',
    enName: 'Shangcai',
  },
  {
    code: '411723',
    name: '平舆县',
    cityCode: '4117',
    enName: 'Pingyu',
  },
  {
    code: '411724',
    name: '正阳县',
    cityCode: '4117',
    enName: 'Zhengyang',
  },
  {
    code: '411725',
    name: '确山县',
    cityCode: '4117',
    enName: 'Queshan',
  },
  {
    code: '411726',
    name: '泌阳县',
    cityCode: '4117',
    enName: 'Miyang',
  },
  {
    code: '411727',
    name: '汝南县',
    cityCode: '4117',
    enName: 'Runan',
  },
  {
    code: '411728',
    name: '遂平县',
    cityCode: '4117',
    enName: 'Suiping',
  },
  {
    code: '411729',
    name: '新蔡县',
    cityCode: '4117',
    enName: 'Xincai',
  },
  {
    code: '419001',
    name: '济源市',
    cityCode: '4190',
    enName: 'Jiyuan',
  },
  {
    code: '420102',
    name: '江岸区',
    cityCode: '4201',
    enName: 'Jiangan',
  },
  {
    code: '420103',
    name: '江汉区',
    cityCode: '4201',
    enName: 'Jianghan',
  },
  {
    code: '420104',
    name: '硚口区',
    cityCode: '4201',
    enName: 'Qiaokou',
  },
  {
    code: '420105',
    name: '汉阳区',
    cityCode: '4201',
    enName: 'Hanyang',
  },
  {
    code: '420106',
    name: '武昌区',
    cityCode: '4201',
    enName: 'Wuchang',
  },
  {
    code: '420107',
    name: '青山区',
    cityCode: '4201',
    enName: 'Qingshan',
  },
  {
    code: '420111',
    name: '洪山区',
    cityCode: '4201',
    enName: 'Hongshan',
  },
  {
    code: '420112',
    name: '东西湖区',
    cityCode: '4201',
    enName: 'Dongxihu',
  },
  {
    code: '420113',
    name: '汉南区',
    cityCode: '4201',
    enName: 'Hannan',
  },
  {
    code: '420114',
    name: '蔡甸区',
    cityCode: '4201',
    enName: 'Caidian',
  },
  {
    code: '420115',
    name: '江夏区',
    cityCode: '4201',
    enName: 'Jiangxia',
  },
  {
    code: '420116',
    name: '黄陂区',
    cityCode: '4201',
    enName: 'Huangbei',
  },
  {
    code: '420117',
    name: '新洲区',
    cityCode: '4201',
    enName: 'Xinzhou',
  },
  {
    code: '420202',
    name: '黄石港区',
    cityCode: '4202',
    enName: 'Huangshigang',
  },
  {
    code: '420203',
    name: '西塞山区',
    cityCode: '4202',
    enName: 'Xisaishan',
  },
  {
    code: '420204',
    name: '下陆区',
    cityCode: '4202',
    enName: 'Xialu',
  },
  {
    code: '420205',
    name: '铁山区',
    cityCode: '4202',
    enName: 'Tieshan',
  },
  {
    code: '420222',
    name: '阳新县',
    cityCode: '4202',
    enName: 'Yangxin',
  },
  {
    code: '420281',
    name: '大冶市',
    cityCode: '4202',
    enName: 'Daye',
  },
  {
    code: '420302',
    name: '茅箭区',
    cityCode: '4203',
    enName: 'Maojian',
  },
  {
    code: '420303',
    name: '张湾区',
    cityCode: '4203',
    enName: 'Zhangwan',
  },
  {
    code: '420304',
    name: '郧阳区',
    cityCode: '4203',
    enName: 'Yunyang',
  },
  {
    code: '420322',
    name: '郧西县',
    cityCode: '4203',
    enName: 'Yunxi',
  },
  {
    code: '420323',
    name: '竹山县',
    cityCode: '4203',
    enName: 'Zhushan',
  },
  {
    code: '420324',
    name: '竹溪县',
    cityCode: '4203',
    enName: 'Zhuxi',
  },
  {
    code: '420325',
    name: '房县',
    cityCode: '4203',
    enName: 'Fang',
  },
  {
    code: '420381',
    name: '丹江口市',
    cityCode: '4203',
    enName: 'Danjiangkou',
  },
  {
    code: '420502',
    name: '西陵区',
    cityCode: '4205',
    enName: 'Xiling',
  },
  {
    code: '420503',
    name: '伍家岗区',
    cityCode: '4205',
    enName: 'Wujiagang',
  },
  {
    code: '420504',
    name: '点军区',
    cityCode: '4205',
    enName: 'Dianjun',
  },
  {
    code: '420505',
    name: '猇亭区',
    cityCode: '4205',
    enName: 'Xiaoting',
  },
  {
    code: '420506',
    name: '夷陵区',
    cityCode: '4205',
    enName: 'Yiling',
  },
  {
    code: '420525',
    name: '远安县',
    cityCode: '4205',
    enName: 'Yuanan',
  },
  {
    code: '420526',
    name: '兴山县',
    cityCode: '4205',
    enName: 'Xingshan',
  },
  {
    code: '420527',
    name: '秭归县',
    cityCode: '4205',
    enName: 'Zigui',
  },
  {
    code: '420528',
    name: '长阳土家族自治县',
    cityCode: '4205',
    enName: 'Changyang Tujia Autonomous County',
  },
  {
    code: '420529',
    name: '五峰土家族自治县',
    cityCode: '4205',
    enName: 'Wufeng Tujia Autonomous County',
  },
  {
    code: '420581',
    name: '宜都市',
    cityCode: '4205',
    enName: 'Yidushi',
  },
  {
    code: '420582',
    name: '当阳市',
    cityCode: '4205',
    enName: 'Dangyang',
  },
  {
    code: '420583',
    name: '枝江市',
    cityCode: '4205',
    enName: 'Zhijiang',
  },
  {
    code: '420602',
    name: '襄城区',
    cityCode: '4206',
    enName: 'Xiangcheng',
  },
  {
    code: '420606',
    name: '樊城区',
    cityCode: '4206',
    enName: 'Fancheng',
  },
  {
    code: '420607',
    name: '襄州区',
    cityCode: '4206',
    enName: 'Xiangzhou',
  },
  {
    code: '420624',
    name: '南漳县',
    cityCode: '4206',
    enName: 'Nanzhang',
  },
  {
    code: '420625',
    name: '谷城县',
    cityCode: '4206',
    enName: 'Gucheng',
  },
  {
    code: '420626',
    name: '保康县',
    cityCode: '4206',
    enName: 'Baokang',
  },
  {
    code: '420682',
    name: '老河口市',
    cityCode: '4206',
    enName: 'Laohekou',
  },
  {
    code: '420683',
    name: '枣阳市',
    cityCode: '4206',
    enName: 'Zaoyang',
  },
  {
    code: '420684',
    name: '宜城市',
    cityCode: '4206',
    enName: 'Yicheng',
  },
  {
    code: '420702',
    name: '梁子湖区',
    cityCode: '4207',
    enName: 'Liangzihu',
  },
  {
    code: '420703',
    name: '华容区',
    cityCode: '4207',
    enName: 'Huarong',
  },
  {
    code: '420704',
    name: '鄂城区',
    cityCode: '4207',
    enName: 'Echeng',
  },
  {
    code: '420802',
    name: '东宝区',
    cityCode: '4208',
    enName: 'Dongbao',
  },
  {
    code: '420804',
    name: '掇刀区',
    cityCode: '4208',
    enName: 'Duodao',
  },
  {
    code: '420821',
    name: '京山县',
    cityCode: '4208',
    enName: 'Jingshan',
  },
  {
    code: '420822',
    name: '沙洋县',
    cityCode: '4208',
    enName: 'Shayang',
  },
  {
    code: '420881',
    name: '钟祥市',
    cityCode: '4208',
    enName: 'Zhongg',
  },
  {
    code: '420902',
    name: '孝南区',
    cityCode: '4209',
    enName: 'Xiaonan',
  },
  {
    code: '420921',
    name: '孝昌县',
    cityCode: '4209',
    enName: 'Xiaochang',
  },
  {
    code: '420922',
    name: '大悟县',
    cityCode: '4209',
    enName: 'Dawu',
  },
  {
    code: '420923',
    name: '云梦县',
    cityCode: '4209',
    enName: 'Yunmeng',
  },
  {
    code: '420981',
    name: '应城市',
    cityCode: '4209',
    enName: 'Yingcheng',
  },
  {
    code: '420982',
    name: '安陆市',
    cityCode: '4209',
    enName: 'Anlu',
  },
  {
    code: '420984',
    name: '汉川市',
    cityCode: '4209',
    enName: 'Hanchuan',
  },
  {
    code: '421002',
    name: '沙市区',
    cityCode: '4210',
    enName: 'Shashi',
  },
  {
    code: '421003',
    name: '荆州区',
    cityCode: '4210',
    enName: 'Jingzhou',
  },
  {
    code: '421022',
    name: '公安县',
    cityCode: '4210',
    enName: 'Gongan',
  },
  {
    code: '421023',
    name: '监利县',
    cityCode: '4210',
    enName: 'Jianli',
  },
  {
    code: '421024',
    name: '江陵县',
    cityCode: '4210',
    enName: 'Jiangling',
  },
  {
    code: '421081',
    name: '石首市',
    cityCode: '4210',
    enName: 'Shishou',
  },
  {
    code: '421083',
    name: '洪湖市',
    cityCode: '4210',
    enName: 'Honghu',
  },
  {
    code: '421087',
    name: '松滋市',
    cityCode: '4210',
    enName: 'Songzi',
  },
  {
    code: '421102',
    name: '黄州区',
    cityCode: '4211',
    enName: 'Huangzhou',
  },
  {
    code: '421121',
    name: '团风县',
    cityCode: '4211',
    enName: 'Tuanfeng',
  },
  {
    code: '421122',
    name: '红安县',
    cityCode: '4211',
    enName: 'Hongan',
  },
  {
    code: '421123',
    name: '罗田县',
    cityCode: '4211',
    enName: 'Luotian',
  },
  {
    code: '421124',
    name: '英山县',
    cityCode: '4211',
    enName: 'Yingshan',
  },
  {
    code: '421125',
    name: '浠水县',
    cityCode: '4211',
    enName: 'Xishui',
  },
  {
    code: '421126',
    name: '蕲春县',
    cityCode: '4211',
    enName: 'Qichun',
  },
  {
    code: '421127',
    name: '黄梅县',
    cityCode: '4211',
    enName: 'Huangmei',
  },
  {
    code: '421181',
    name: '麻城市',
    cityCode: '4211',
    enName: 'Macheng',
  },
  {
    code: '421182',
    name: '武穴市',
    cityCode: '4211',
    enName: 'Wuxue',
  },
  {
    code: '421202',
    name: '咸安区',
    cityCode: '4212',
    enName: 'Xianan',
  },
  {
    code: '421221',
    name: '嘉鱼县',
    cityCode: '4212',
    enName: 'Jiayu',
  },
  {
    code: '421222',
    name: '通城县',
    cityCode: '4212',
    enName: 'Tongcheng',
  },
  {
    code: '421223',
    name: '崇阳县',
    cityCode: '4212',
    enName: 'Chongyang',
  },
  {
    code: '421224',
    name: '通山县',
    cityCode: '4212',
    enName: 'Tongshan',
  },
  {
    code: '421281',
    name: '赤壁市',
    cityCode: '4212',
    enName: 'Chibi',
  },
  {
    code: '421303',
    name: '曾都区',
    cityCode: '4213',
    enName: 'Cengdou',
  },
  {
    code: '421321',
    name: '随县',
    cityCode: '4213',
    enName: 'Sui',
  },
  {
    code: '421381',
    name: '广水市',
    cityCode: '4213',
    enName: 'Guangshui',
  },
  {
    code: '422801',
    name: '恩施市',
    cityCode: '4228',
    enName: 'Enshi',
  },
  {
    code: '422802',
    name: '利川市',
    cityCode: '4228',
    enName: 'Lichuan',
  },
  {
    code: '422822',
    name: '建始县',
    cityCode: '4228',
    enName: 'Jianshi',
  },
  {
    code: '422823',
    name: '巴东县',
    cityCode: '4228',
    enName: 'Badong',
  },
  {
    code: '422825',
    name: '宣恩县',
    cityCode: '4228',
    enName: 'Xuanen',
  },
  {
    code: '422826',
    name: '咸丰县',
    cityCode: '4228',
    enName: 'Xianfeng',
  },
  {
    code: '422827',
    name: '来凤县',
    cityCode: '4228',
    enName: 'Laifeng',
  },
  {
    code: '422828',
    name: '鹤峰县',
    cityCode: '4228',
    enName: 'Hefeng',
  },
  {
    code: '429004',
    name: '仙桃市',
    cityCode: '4290',
    enName: 'Xiantao',
  },
  {
    code: '429005',
    name: '潜江市',
    cityCode: '4290',
    enName: 'Qianjiang',
  },
  {
    code: '429006',
    name: '天门市',
    cityCode: '4290',
    enName: 'Tianmen',
  },
  {
    code: '429021',
    name: '神农架林区',
    cityCode: '4290',
    enName: 'Shennongjia Forestry District',
  },
  {
    code: '430102',
    name: '芙蓉区',
    cityCode: '4301',
    enName: 'Furong',
  },
  {
    code: '430103',
    name: '天心区',
    cityCode: '4301',
    enName: 'Tianxin',
  },
  {
    code: '430104',
    name: '岳麓区',
    cityCode: '4301',
    enName: 'Yuelu',
  },
  {
    code: '430105',
    name: '开福区',
    cityCode: '4301',
    enName: 'Kaifu',
  },
  {
    code: '430111',
    name: '雨花区',
    cityCode: '4301',
    enName: 'Yuhua',
  },
  {
    code: '430112',
    name: '望城区',
    cityCode: '4301',
    enName: 'Wangcheng',
  },
  {
    code: '430121',
    name: '长沙县',
    cityCode: '4301',
    enName: 'Changsha',
  },
  {
    code: '430124',
    name: '宁乡县',
    cityCode: '4301',
    enName: 'Ninggxian',
  },
  {
    code: '430181',
    name: '浏阳市',
    cityCode: '4301',
    enName: 'Liuyang',
  },
  {
    code: '430202',
    name: '荷塘区',
    cityCode: '4302',
    enName: 'Hetang',
  },
  {
    code: '430203',
    name: '芦淞区',
    cityCode: '4302',
    enName: 'Lusong',
  },
  {
    code: '430204',
    name: '石峰区',
    cityCode: '4302',
    enName: 'Shifeng',
  },
  {
    code: '430211',
    name: '天元区',
    cityCode: '4302',
    enName: 'Tianyuan',
  },
  {
    code: '430221',
    name: '株洲县',
    cityCode: '4302',
    enName: 'Zhuzhou',
  },
  {
    code: '430223',
    name: '攸县',
    cityCode: '4302',
    enName: 'You',
  },
  {
    code: '430224',
    name: '茶陵县',
    cityCode: '4302',
    enName: 'Chaling',
  },
  {
    code: '430225',
    name: '炎陵县',
    cityCode: '4302',
    enName: 'Yanling',
  },
  {
    code: '430281',
    name: '醴陵市',
    cityCode: '4302',
    enName: 'Liling',
  },
  {
    code: '430302',
    name: '雨湖区',
    cityCode: '4303',
    enName: 'Yuhu',
  },
  {
    code: '430304',
    name: '岳塘区',
    cityCode: '4303',
    enName: 'Yuetang',
  },
  {
    code: '430321',
    name: '湘潭县',
    cityCode: '4303',
    enName: 'Xiangtan',
  },
  {
    code: '430381',
    name: '湘乡市',
    cityCode: '4303',
    enName: 'Xiangxiang',
  },
  {
    code: '430382',
    name: '韶山市',
    cityCode: '4303',
    enName: 'Shaoshan',
  },
  {
    code: '430405',
    name: '珠晖区',
    cityCode: '4304',
    enName: 'Zhuhui',
  },
  {
    code: '430406',
    name: '雁峰区',
    cityCode: '4304',
    enName: 'Yanfeng',
  },
  {
    code: '430407',
    name: '石鼓区',
    cityCode: '4304',
    enName: 'Shigu',
  },
  {
    code: '430408',
    name: '蒸湘区',
    cityCode: '4304',
    enName: 'Zhengg',
  },
  {
    code: '430412',
    name: '南岳区',
    cityCode: '4304',
    enName: 'Nanyue',
  },
  {
    code: '430421',
    name: '衡阳县',
    cityCode: '4304',
    enName: 'Hengyang',
  },
  {
    code: '430422',
    name: '衡南县',
    cityCode: '4304',
    enName: 'Hengnan',
  },
  {
    code: '430423',
    name: '衡山县',
    cityCode: '4304',
    enName: 'Hengshan',
  },
  {
    code: '430424',
    name: '衡东县',
    cityCode: '4304',
    enName: 'Hengdong',
  },
  {
    code: '430426',
    name: '祁东县',
    cityCode: '4304',
    enName: 'Qidong',
  },
  {
    code: '430481',
    name: '耒阳市',
    cityCode: '4304',
    enName: 'Leiyang',
  },
  {
    code: '430482',
    name: '常宁市',
    cityCode: '4304',
    enName: 'Changning',
  },
  {
    code: '430502',
    name: '双清区',
    cityCode: '4305',
    enName: 'Shuangqing',
  },
  {
    code: '430503',
    name: '大祥区',
    cityCode: '4305',
    enName: 'Dag',
  },
  {
    code: '430511',
    name: '北塔区',
    cityCode: '4305',
    enName: 'Beita',
  },
  {
    code: '430521',
    name: '邵东县',
    cityCode: '4305',
    enName: 'Shaodong',
  },
  {
    code: '430522',
    name: '新邵县',
    cityCode: '4305',
    enName: 'Xinshao',
  },
  {
    code: '430523',
    name: '邵阳县',
    cityCode: '4305',
    enName: 'Shaoyang',
  },
  {
    code: '430524',
    name: '隆回县',
    cityCode: '4305',
    enName: 'Longhui',
  },
  {
    code: '430525',
    name: '洞口县',
    cityCode: '4305',
    enName: 'Dongkou',
  },
  {
    code: '430527',
    name: '绥宁县',
    cityCode: '4305',
    enName: 'Suining',
  },
  {
    code: '430528',
    name: '新宁县',
    cityCode: '4305',
    enName: 'Xinning',
  },
  {
    code: '430529',
    name: '城步苗族自治县',
    cityCode: '4305',
    enName: 'Chengbu Miao Autonomous County',
  },
  {
    code: '430581',
    name: '武冈市',
    cityCode: '4305',
    enName: 'Wugang',
  },
  {
    code: '430602',
    name: '岳阳楼区',
    cityCode: '4306',
    enName: 'Yueyanglou',
  },
  {
    code: '430603',
    name: '云溪区',
    cityCode: '4306',
    enName: 'Yunxi',
  },
  {
    code: '430611',
    name: '君山区',
    cityCode: '4306',
    enName: 'Junshan',
  },
  {
    code: '430621',
    name: '岳阳县',
    cityCode: '4306',
    enName: 'Yueyang',
  },
  {
    code: '430623',
    name: '华容县',
    cityCode: '4306',
    enName: 'Huarong',
  },
  {
    code: '430624',
    name: '湘阴县',
    cityCode: '4306',
    enName: 'Xiangyin',
  },
  {
    code: '430626',
    name: '平江县',
    cityCode: '4306',
    enName: 'Pingjiang',
  },
  {
    code: '430681',
    name: '汨罗市',
    cityCode: '4306',
    enName: 'Miluo',
  },
  {
    code: '430682',
    name: '临湘市',
    cityCode: '4306',
    enName: 'Lingxiang',
  },
  {
    code: '430702',
    name: '武陵区',
    cityCode: '4307',
    enName: 'Wuling',
  },
  {
    code: '430703',
    name: '鼎城区',
    cityCode: '4307',
    enName: 'Dingcheng',
  },
  {
    code: '430721',
    name: '安乡县',
    cityCode: '4307',
    enName: 'Angxian',
  },
  {
    code: '430722',
    name: '汉寿县',
    cityCode: '4307',
    enName: 'Hanshou',
  },
  {
    code: '430723',
    name: '澧县',
    cityCode: '4307',
    enName: 'Li',
  },
  {
    code: '430724',
    name: '临澧县',
    cityCode: '4307',
    enName: 'Linli',
  },
  {
    code: '430725',
    name: '桃源县',
    cityCode: '4307',
    enName: 'Taoyuan',
  },
  {
    code: '430726',
    name: '石门县',
    cityCode: '4307',
    enName: 'Shimen',
  },
  {
    code: '430781',
    name: '津市市',
    cityCode: '4307',
    enName: 'Jinshi',
  },
  {
    code: '430802',
    name: '永定区',
    cityCode: '4308',
    enName: 'Yongding',
  },
  {
    code: '430811',
    name: '武陵源区',
    cityCode: '4308',
    enName: 'Wulingyuan',
  },
  {
    code: '430821',
    name: '慈利县',
    cityCode: '4308',
    enName: 'Cili',
  },
  {
    code: '430822',
    name: '桑植县',
    cityCode: '4308',
    enName: 'Sangzhi',
  },
  {
    code: '430902',
    name: '资阳区',
    cityCode: '4309',
    enName: 'Ziyang',
  },
  {
    code: '430903',
    name: '赫山区',
    cityCode: '4309',
    enName: 'Heshan',
  },
  {
    code: '430921',
    name: '南县',
    cityCode: '4309',
    enName: 'Nan',
  },
  {
    code: '430922',
    name: '桃江县',
    cityCode: '4309',
    enName: 'Taojiang',
  },
  {
    code: '430923',
    name: '安化县',
    cityCode: '4309',
    enName: 'Anhua',
  },
  {
    code: '430981',
    name: '沅江市',
    cityCode: '4309',
    enName: 'Yuanjiang',
  },
  {
    code: '431002',
    name: '北湖区',
    cityCode: '4310',
    enName: 'Beihu',
  },
  {
    code: '431003',
    name: '苏仙区',
    cityCode: '4310',
    enName: 'Su',
  },
  {
    code: '431021',
    name: '桂阳县',
    cityCode: '4310',
    enName: 'Guiyang',
  },
  {
    code: '431022',
    name: '宜章县',
    cityCode: '4310',
    enName: 'Yizhang',
  },
  {
    code: '431023',
    name: '永兴县',
    cityCode: '4310',
    enName: 'Yongxing',
  },
  {
    code: '431024',
    name: '嘉禾县',
    cityCode: '4310',
    enName: 'Jiahe',
  },
  {
    code: '431025',
    name: '临武县',
    cityCode: '4310',
    enName: 'Linwu',
  },
  {
    code: '431026',
    name: '汝城县',
    cityCode: '4310',
    enName: 'Rucheng',
  },
  {
    code: '431027',
    name: '桂东县',
    cityCode: '4310',
    enName: 'Guidong',
  },
  {
    code: '431028',
    name: '安仁县',
    cityCode: '4310',
    enName: 'Anren',
  },
  {
    code: '431081',
    name: '资兴市',
    cityCode: '4310',
    enName: 'Zixing',
  },
  {
    code: '431102',
    name: '零陵区',
    cityCode: '4311',
    enName: 'Lingling',
  },
  {
    code: '431103',
    name: '冷水滩区',
    cityCode: '4311',
    enName: 'Lengshuitan',
  },
  {
    code: '431121',
    name: '祁阳县',
    cityCode: '4311',
    enName: 'Qiyang',
  },
  {
    code: '431122',
    name: '东安县',
    cityCode: '4311',
    enName: 'Dongan',
  },
  {
    code: '431123',
    name: '双牌县',
    cityCode: '4311',
    enName: 'Shuangpai',
  },
  {
    code: '431124',
    name: '道县',
    cityCode: '4311',
    enName: 'Dao',
  },
  {
    code: '431125',
    name: '江永县',
    cityCode: '4311',
    enName: 'Jiangyong',
  },
  {
    code: '431126',
    name: '宁远县',
    cityCode: '4311',
    enName: 'Ningyuan',
  },
  {
    code: '431127',
    name: '蓝山县',
    cityCode: '4311',
    enName: 'Lanshan',
  },
  {
    code: '431128',
    name: '新田县',
    cityCode: '4311',
    enName: 'Xintian',
  },
  {
    code: '431129',
    name: '江华瑶族自治县',
    cityCode: '4311',
    enName: 'Jianghua Yao Autonomous County',
  },
  {
    code: '431202',
    name: '鹤城区',
    cityCode: '4312',
    enName: 'Hecheng',
  },
  {
    code: '431221',
    name: '中方县',
    cityCode: '4312',
    enName: 'Zhongfang',
  },
  {
    code: '431222',
    name: '沅陵县',
    cityCode: '4312',
    enName: 'Yuanling',
  },
  {
    code: '431223',
    name: '辰溪县',
    cityCode: '4312',
    enName: 'Chenxi',
  },
  {
    code: '431224',
    name: '溆浦县',
    cityCode: '4312',
    enName: 'Xupu',
  },
  {
    code: '431225',
    name: '会同县',
    cityCode: '4312',
    enName: 'Huitong',
  },
  {
    code: '431226',
    name: '麻阳苗族自治县',
    cityCode: '4312',
    enName: 'Mayang Miao Autonomous County ',
  },
  {
    code: '431227',
    name: '新晃侗族自治县',
    cityCode: '4312',
    enName: 'Xinghuang Dong Autonomous County ',
  },
  {
    code: '431228',
    name: '芷江侗族自治县',
    cityCode: '4312',
    enName: 'Zhijiang Dong Autonomous County ',
  },
  {
    code: '431229',
    name: '靖州苗族侗族自治县',
    cityCode: '4312',
    enName: 'Jingzhou Miao Autonomous County ',
  },
  {
    code: '431230',
    name: '通道侗族自治县',
    cityCode: '4312',
    enName: 'Tongdao Dong Autonomous County',
  },
  {
    code: '431281',
    name: '洪江市',
    cityCode: '4312',
    enName: 'Hongjiang',
  },
  {
    code: '431302',
    name: '娄星区',
    cityCode: '4313',
    enName: 'Louxing',
  },
  {
    code: '431321',
    name: '双峰县',
    cityCode: '4313',
    enName: 'Shuangfeng',
  },
  {
    code: '431322',
    name: '新化县',
    cityCode: '4313',
    enName: 'Xinhua',
  },
  {
    code: '431381',
    name: '冷水江市',
    cityCode: '4313',
    enName: 'Lengshuijiang',
  },
  {
    code: '431382',
    name: '涟源市',
    cityCode: '4313',
    enName: 'Lianyuan',
  },
  {
    code: '433122',
    name: '泸溪县',
    cityCode: '4331',
    enName: 'Luxi',
  },
  {
    code: '433123',
    name: '凤凰县',
    cityCode: '4331',
    enName: 'Fenghuang',
  },
  {
    code: '433124',
    name: '花垣县',
    cityCode: '4331',
    enName: 'Huayuan',
  },
  {
    code: '433125',
    name: '保靖县',
    cityCode: '4331',
    enName: 'Baojing',
  },
  {
    code: '433126',
    name: '古丈县',
    cityCode: '4331',
    enName: 'Guzhang',
  },
  {
    code: '433127',
    name: '永顺县',
    cityCode: '4331',
    enName: 'Yongshun',
  },
  {
    code: '433130',
    name: '龙山县',
    cityCode: '4331',
    enName: 'Longshan',
  },
  {
    code: '440103',
    name: '荔湾区',
    cityCode: '4401',
    enName: 'Liwan',
  },
  {
    code: '440104',
    name: '越秀区',
    cityCode: '4401',
    enName: 'Yuexiu',
  },
  {
    code: '440105',
    name: '海珠区',
    cityCode: '4401',
    enName: 'Haizhu',
  },
  {
    code: '440106',
    name: '天河区',
    cityCode: '4401',
    enName: 'Tianhe',
  },
  {
    code: '440111',
    name: '白云区',
    cityCode: '4401',
    enName: 'Baiyun',
  },
  {
    code: '440112',
    name: '黄埔区',
    cityCode: '4401',
    enName: 'Huangpu',
  },
  {
    code: '440113',
    name: '番禺区',
    cityCode: '4401',
    enName: 'Panyu',
  },
  {
    code: '440114',
    name: '花都区',
    cityCode: '4401',
    enName: 'Huadu',
  },
  {
    code: '440115',
    name: '南沙区',
    cityCode: '4401',
    enName: 'Nansha',
  },
  {
    code: '440117',
    name: '从化区',
    cityCode: '4401',
    enName: 'Conghua',
  },
  {
    code: '440118',
    name: '增城区',
    cityCode: '4401',
    enName: 'Zengcheng',
  },
  {
    code: '440203',
    name: '武江区',
    cityCode: '4402',
    enName: 'Wujiang',
  },
  {
    code: '440204',
    name: '浈江区',
    cityCode: '4402',
    enName: 'Zhenjiang',
  },
  {
    code: '440205',
    name: '曲江区',
    cityCode: '4402',
    enName: 'Qujiang',
  },
  {
    code: '440222',
    name: '始兴县',
    cityCode: '4402',
    enName: 'Shixing',
  },
  {
    code: '440224',
    name: '仁化县',
    cityCode: '4402',
    enName: 'Renhua',
  },
  {
    code: '440229',
    name: '翁源县',
    cityCode: '4402',
    enName: 'Wengyuan',
  },
  {
    code: '440232',
    name: '乳源瑶族自治县',
    cityCode: '4402',
    enName: 'Ruyuan Yao Autonomous County',
  },
  {
    code: '440233',
    name: '新丰县',
    cityCode: '4402',
    enName: 'Xinfeng',
  },
  {
    code: '440281',
    name: '乐昌市',
    cityCode: '4402',
    enName: 'Lechang',
  },
  {
    code: '440282',
    name: '南雄市',
    cityCode: '4402',
    enName: 'Nanxiong',
  },
  {
    code: '440300',
    name: '深圳市',
    cityCode: '4403',
    enName: 'Shenzhen',
  },
  {
    code: '440301',
    name: '罗湖区',
    cityCode: '4403',
    enName: 'Luohu',
  },
  {
    code: '440303',
    name: '盐田区',
    cityCode: '4403',
    enName: 'Yantian',
  },
  {
    code: '440304',
    name: '福田区',
    cityCode: '4403',
    enName: 'Futian',
  },
  {
    code: '440305',
    name: '南山区',
    cityCode: '4403',
    enName: 'Nanshan',
  },
  {
    code: '440306',
    name: '宝安区',
    cityCode: '4403',
    enName: "Bao'an",
  },
  {
    code: '440307',
    name: '龙岗区',
    cityCode: '4403',
    enName: 'Longgang',
  },
  {
    code: '440308',
    name: '龙华区',
    cityCode: '4403',
    enName: 'Longhua',
  },
  {
    code: '440400',
    name: '珠海市',
    cityCode: '4404',
    enName: 'Zhuhai',
  },
  {
    code: '440401',
    name: '香洲区',
    cityCode: '4404',
    enName: 'Xiangzhou',
  },
  {
    code: '440402',
    name: '斗门区',
    cityCode: '4404',
    enName: 'Doumen',
  },
  {
    code: '440403',
    name: '金湾区',
    cityCode: '4404',
    enName: 'Jinwan',
  },
  {
    code: '440507',
    name: '龙湖区',
    cityCode: '4405',
    enName: 'Longhu',
  },
  {
    code: '440511',
    name: '金平区',
    cityCode: '4405',
    enName: 'Jinping',
  },
  {
    code: '440512',
    name: '濠江区',
    cityCode: '4405',
    enName: 'Haojiang',
  },
  {
    code: '440513',
    name: '潮阳区',
    cityCode: '4405',
    enName: 'Chaoyang',
  },
  {
    code: '440514',
    name: '潮南区',
    cityCode: '4405',
    enName: 'Chaonan',
  },
  {
    code: '440515',
    name: '澄海区',
    cityCode: '4405',
    enName: 'Chenghai',
  },
  {
    code: '440523',
    name: '南澳县',
    cityCode: '4405',
    enName: 'Nanao',
  },
  {
    code: '440604',
    name: '禅城区',
    cityCode: '4406',
    enName: 'Chancheng',
  },
  {
    code: '440605',
    name: '南海区',
    cityCode: '4406',
    enName: 'Nanhai',
  },
  {
    code: '440606',
    name: '顺德区',
    cityCode: '4406',
    enName: 'Shunde',
  },
  {
    code: '440607',
    name: '三水区',
    cityCode: '4406',
    enName: 'Sanshui',
  },
  {
    code: '440608',
    name: '高明区',
    cityCode: '4406',
    enName: 'Gaoming',
  },
  {
    code: '440703',
    name: '蓬江区',
    cityCode: '4407',
    enName: 'Pengjiang',
  },
  {
    code: '440704',
    name: '江海区',
    cityCode: '4407',
    enName: 'Jianghai',
  },
  {
    code: '440705',
    name: '新会区',
    cityCode: '4407',
    enName: 'Xinhui',
  },
  {
    code: '440781',
    name: '台山市',
    cityCode: '4407',
    enName: 'Taishan',
  },
  {
    code: '440783',
    name: '开平市',
    cityCode: '4407',
    enName: 'Kaiping',
  },
  {
    code: '440784',
    name: '鹤山市',
    cityCode: '4407',
    enName: 'Heshan',
  },
  {
    code: '440785',
    name: '恩平市',
    cityCode: '4407',
    enName: 'Enping',
  },
  {
    code: '440802',
    name: '赤坎区',
    cityCode: '4408',
    enName: 'Chikan',
  },
  {
    code: '440803',
    name: '霞山区',
    cityCode: '4408',
    enName: 'Xiashan',
  },
  {
    code: '440804',
    name: '坡头区',
    cityCode: '4408',
    enName: 'Potou',
  },
  {
    code: '440811',
    name: '麻章区',
    cityCode: '4408',
    enName: 'Machang',
  },
  { code: '440823', name: '遂溪县', cityCode: '4408', enName: 'Suixi' },
  {
    code: '440825',
    name: '徐闻县',
    cityCode: '4408',
    enName: 'Xuwen',
  },
  {
    code: '440881',
    name: '廉江市',
    cityCode: '4408',
    enName: 'Lianjiang',
  },
  {
    code: '440882',
    name: '雷州市',
    cityCode: '4408',
    enName: 'Leizhou',
  },
  {
    code: '440883',
    name: '吴川市',
    cityCode: '4408',
    enName: 'Wuchuan',
  },
  {
    code: '440902',
    name: '茂南区',
    cityCode: '4409',
    enName: 'Maonan',
  },
  {
    code: '440903',
    name: '茂港区',
    cityCode: '4409',
    enName: 'Maogang',
  },
  {
    code: '440923',
    name: '电白县',
    cityCode: '4409',
    enName: 'Dianbai',
  },
  {
    code: '440981',
    name: '高州市',
    cityCode: '4409',
    enName: 'Gaozhou',
  },
  {
    code: '440982',
    name: '化州市',
    cityCode: '4409',
    enName: 'Huazhou',
  },
  {
    code: '440983',
    name: '信宜市',
    cityCode: '4409',
    enName: 'Xinyi',
  },
  {
    code: '441202',
    name: '端州区',
    cityCode: '4412',
    enName: 'Duanzhou',
  },
  {
    code: '441203',
    name: '鼎湖区',
    cityCode: '4412',
    enName: 'Dinghu',
  },
  {
    code: '441223',
    name: '广宁县',
    cityCode: '4412',
    enName: 'Guangning',
  },
  {
    code: '441224',
    name: '怀集县',
    cityCode: '4412',
    enName: 'Huaiji',
  },
  {
    code: '441225',
    name: '封开县',
    cityCode: '4412',
    enName: 'Fengkai',
  },
  {
    code: '441226',
    name: '德庆县',
    cityCode: '4412',
    enName: 'Deqing',
  },
  {
    code: '441283',
    name: '高要市',
    cityCode: '4412',
    enName: 'Gaoyao',
  },
  {
    code: '441284',
    name: '四会市',
    cityCode: '4412',
    enName: 'Sihui',
  },
  {
    code: '441302',
    name: '惠城区',
    cityCode: '4413',
    enName: 'Huicheng',
  },
  {
    code: '441303',
    name: '惠阳区',
    cityCode: '4413',
    enName: 'Huiyang',
  },
  {
    code: '441322',
    name: '博罗县',
    cityCode: '4413',
    enName: 'Boluo',
  },
  {
    code: '441323',
    name: '惠东县',
    cityCode: '4413',
    enName: 'Huidong',
  },
  {
    code: '441324',
    name: '龙门县',
    cityCode: '4413',
    enName: 'Longmen',
  },
  {
    code: '441402',
    name: '梅江区',
    cityCode: '4414',
    enName: 'Meijiang',
  },
  { code: '441421', name: '梅县', cityCode: '4414', enName: 'Meixian' },
  { code: '441422', name: '大埔县', cityCode: '4414', enName: 'Dabu' },
  {
    code: '441423',
    name: '丰顺县',
    cityCode: '4414',
    enName: 'Fengshun',
  },
  { code: '441424', name: '五华县', cityCode: '4414', enName: 'Wuhua' },
  {
    code: '441426',
    name: '平远县',
    cityCode: '4414',
    enName: 'Pingyuan',
  },
  {
    code: '441427',
    name: '蕉岭县',
    cityCode: '4414',
    enName: 'Jiaoling',
  },
  {
    code: '441481',
    name: '兴宁市',
    cityCode: '4414',
    enName: 'Xingning',
  },
  {
    code: '441502',
    name: '城区',
    cityCode: '4415',
    enName: 'Urban District',
  },
  {
    code: '441521',
    name: '海丰县',
    cityCode: '4415',
    enName: 'Haifeng',
  },
  { code: '441523', name: '陆河县', cityCode: '4415', enName: 'Luhe' },
  {
    code: '441581',
    name: '陆丰市',
    cityCode: '4415',
    enName: 'Lufeng',
  },
  {
    code: '441602',
    name: '源城区',
    cityCode: '4416',
    enName: 'Yuancheng',
  },
  { code: '441621', name: '紫金县', cityCode: '4416', enName: 'Zijin' },
  {
    code: '441622',
    name: '龙川县',
    cityCode: '4416',
    enName: 'Longchuan',
  },
  {
    code: '441623',
    name: '连平县',
    cityCode: '4416',
    enName: 'Lianping',
  },
  {
    code: '441624',
    name: '和平县',
    cityCode: '4416',
    enName: 'Heping',
  },
  {
    code: '441625',
    name: '东源县',
    cityCode: '4416',
    enName: 'Dongyuan',
  },
  {
    code: '441702',
    name: '江城区',
    cityCode: '4417',
    enName: 'Jiangcheng',
  },
  {
    code: '441721',
    name: '阳西县',
    cityCode: '4417',
    enName: 'Yangxi',
  },
  {
    code: '441723',
    name: '阳东县',
    cityCode: '4417',
    enName: 'Yangdong',
  },
  {
    code: '441781',
    name: '阳春市',
    cityCode: '4417',
    enName: 'Yangchun',
  },
  {
    code: '441802',
    name: '清城区',
    cityCode: '4418',
    enName: 'Qingcheng',
  },
  {
    code: '441821',
    name: '佛冈县',
    cityCode: '4418',
    enName: 'Fogang',
  },
  {
    code: '441823',
    name: '阳山县',
    cityCode: '4418',
    enName: 'Yangshan',
  },
  {
    code: '441825',
    name: '连山壮族瑶族自治县',
    cityCode: '4418',
    enName: 'Lianshan',
  },
  {
    code: '441826',
    name: '连南瑶族自治县',
    cityCode: '4418',
    enName: 'Liannan',
  },
  {
    code: '441827',
    name: '清新县',
    cityCode: '4418',
    enName: 'Qingxin',
  },
  {
    code: '441881',
    name: '英德市',
    cityCode: '4418',
    enName: 'Yingde',
  },
  {
    code: '441882',
    name: '连州市',
    cityCode: '4418',
    enName: 'Lianzhou',
  },
  {
    code: '441901',
    name: '莞城区',
    cityCode: '4419',
    enName: 'Guancheng',
  },
  {
    code: '441902',
    name: '万江区',
    cityCode: '4419',
    enName: 'Wanjiang',
  },
  { code: '442001', name: '石岐区', cityCode: '4420', enName: 'Shiqi' },
  { code: '442002', name: '南区', cityCode: '4420', enName: 'Nanqu' },
  {
    code: '442003',
    name: '五桂山区',
    cityCode: '4420',
    enName: 'Wuguishan',
  },
  {
    code: '442004',
    name: '火炬开发区',
    cityCode: '4420',
    enName: 'Torch',
  },
  {
    code: '442005',
    name: '黄圃镇',
    cityCode: '4420',
    enName: 'Huangpu',
  },
  {
    code: '442006',
    name: '南头镇',
    cityCode: '4420',
    enName: 'Nantou',
  },
  {
    code: '445102',
    name: '湘桥区',
    cityCode: '4451',
    enName: 'Xiangqiao',
  },
  {
    code: '445121',
    name: '潮安县',
    cityCode: '4451',
    enName: "Chao'an",
  },
  {
    code: '445122',
    name: '饶平县',
    cityCode: '4451',
    enName: 'Raoping',
  },
  {
    code: '445202',
    name: '榕城区',
    cityCode: '4452',
    enName: 'Rongcheng',
  },
  {
    code: '445221',
    name: '揭东县',
    cityCode: '4452',
    enName: 'Jiedong',
  },
  {
    code: '445222',
    name: '揭西县',
    cityCode: '4452',
    enName: 'Jiewest',
  },
  {
    code: '445224',
    name: '惠来县',
    cityCode: '4452',
    enName: 'Huilai',
  },
  {
    code: '445281',
    name: '普宁市',
    cityCode: '4452',
    enName: 'Puning',
  },
  {
    code: '450102',
    name: '兴宁区',
    cityCode: '4501',
    enName: 'Xingning',
  },
  {
    code: '450103',
    name: '青秀区',
    cityCode: '4501',
    enName: 'Qingxiu',
  },
  {
    code: '450105',
    name: '江南区',
    cityCode: '4501',
    enName: 'Jiangnan',
  },
  {
    code: '450107',
    name: '西乡塘区',
    cityCode: '4501',
    enName: 'Xigtang',
  },
  {
    code: '450108',
    name: '良庆区',
    cityCode: '4501',
    enName: 'Liangqing',
  },
  {
    code: '450109',
    name: '邕宁区',
    cityCode: '4501',
    enName: 'Yongning',
  },
  {
    code: '450110',
    name: '武鸣区',
    cityCode: '4501',
    enName: 'Wuming',
  },
  {
    code: '450123',
    name: '隆安县',
    cityCode: '4501',
    enName: 'Longan',
  },
  {
    code: '450124',
    name: '马山县',
    cityCode: '4501',
    enName: 'Mashan',
  },
  {
    code: '450125',
    name: '上林县',
    cityCode: '4501',
    enName: 'Shanglin',
  },
  {
    code: '450126',
    name: '宾阳县',
    cityCode: '4501',
    enName: 'Binyang',
  },
  {
    code: '450127',
    name: '横县',
    cityCode: '4501',
    enName: 'Heng',
  },
  {
    code: '450202',
    name: '城中区',
    cityCode: '4502',
    enName: 'Chengzhong',
  },
  {
    code: '450203',
    name: '鱼峰区',
    cityCode: '4502',
    enName: 'Yufeng',
  },
  {
    code: '450204',
    name: '柳南区',
    cityCode: '4502',
    enName: 'Liunan',
  },
  {
    code: '450205',
    name: '柳北区',
    cityCode: '4502',
    enName: 'Liubei',
  },
  {
    code: '450206',
    name: '柳江区',
    cityCode: '4502',
    enName: 'Liujiang',
  },
  {
    code: '450222',
    name: '柳城县',
    cityCode: '4502',
    enName: 'Liucheng',
  },
  {
    code: '450223',
    name: '鹿寨县',
    cityCode: '4502',
    enName: 'Luzhai',
  },
  {
    code: '450224',
    name: '融安县',
    cityCode: '4502',
    enName: 'Rongan',
  },
  {
    code: '450225',
    name: '融水苗族自治县',
    cityCode: '4502',
    enName: 'Rongshui Miao Autonomous County',
  },
  {
    code: '450226',
    name: '三江侗族自治县',
    cityCode: '4502',
    enName: 'Sanjiang Dong Autonomous County',
  },
  {
    code: '450302',
    name: '秀峰区',
    cityCode: '4503',
    enName: 'Xiufeng',
  },
  {
    code: '450303',
    name: '叠彩区',
    cityCode: '4503',
    enName: 'Diecai',
  },
  {
    code: '450304',
    name: '象山区',
    cityCode: '4503',
    enName: 'Xiangshan',
  },
  {
    code: '450305',
    name: '七星区',
    cityCode: '4503',
    enName: 'Qixing',
  },
  {
    code: '450311',
    name: '雁山区',
    cityCode: '4503',
    enName: 'Yanshan',
  },
  {
    code: '450312',
    name: '临桂区',
    cityCode: '4503',
    enName: 'Lingui',
  },
  {
    code: '450321',
    name: '阳朔县',
    cityCode: '4503',
    enName: 'Yangshuo',
  },
  {
    code: '450323',
    name: '灵川县',
    cityCode: '4503',
    enName: 'Lingchuan',
  },
  {
    code: '450324',
    name: '全州县',
    cityCode: '4503',
    enName: 'Quanzhou',
  },
  {
    code: '450325',
    name: '兴安县',
    cityCode: '4503',
    enName: 'Xingan',
  },
  {
    code: '450326',
    name: '永福县',
    cityCode: '4503',
    enName: 'Yongfu',
  },
  {
    code: '450327',
    name: '灌阳县',
    cityCode: '4503',
    enName: 'Guanyang',
  },
  {
    code: '450328',
    name: '龙胜各族自治县',
    cityCode: '4503',
    enName: 'Longsheng Ge Autonomous County',
  },
  {
    code: '450329',
    name: '资源县',
    cityCode: '4503',
    enName: 'Ziyuan',
  },
  {
    code: '450330',
    name: '平乐县',
    cityCode: '4503',
    enName: 'Pingle',
  },
  {
    code: '450331',
    name: '荔浦县',
    cityCode: '4503',
    enName: 'Lipu',
  },
  {
    code: '450332',
    name: '恭城瑶族自治县',
    cityCode: '4503',
    enName: 'Gongcheng Yao Autonomous County',
  },
  {
    code: '450403',
    name: '万秀区',
    cityCode: '4504',
    enName: 'Wanxiu',
  },
  {
    code: '450405',
    name: '长洲区',
    cityCode: '4504',
    enName: 'Changzhou',
  },
  {
    code: '450406',
    name: '龙圩区',
    cityCode: '4504',
    enName: 'Longxu',
  },
  {
    code: '450421',
    name: '苍梧县',
    cityCode: '4504',
    enName: 'Cangwu',
  },
  {
    code: '450422',
    name: '藤县',
    cityCode: '4504',
    enName: 'Teng',
  },
  {
    code: '450423',
    name: '蒙山县',
    cityCode: '4504',
    enName: 'Mengshan',
  },
  {
    code: '450481',
    name: '岑溪市',
    cityCode: '4504',
    enName: 'Cenxi',
  },
  {
    code: '450502',
    name: '海城区',
    cityCode: '4505',
    enName: 'Haicheng',
  },
  {
    code: '450503',
    name: '银海区',
    cityCode: '4505',
    enName: 'Yinhai',
  },
  {
    code: '450512',
    name: '铁山港区',
    cityCode: '4505',
    enName: 'Tieshangang',
  },
  {
    code: '450521',
    name: '合浦县',
    cityCode: '4505',
    enName: 'Hepu',
  },
  {
    code: '450602',
    name: '港口区',
    cityCode: '4506',
    enName: 'Gangkou',
  },
  {
    code: '450603',
    name: '防城区',
    cityCode: '4506',
    enName: 'Fangcheng',
  },
  {
    code: '450621',
    name: '上思县',
    cityCode: '4506',
    enName: 'Shangsi',
  },
  {
    code: '450681',
    name: '东兴市',
    cityCode: '4506',
    enName: 'Dongxing',
  },
  {
    code: '450702',
    name: '钦南区',
    cityCode: '4507',
    enName: 'Qinnan',
  },
  {
    code: '450703',
    name: '钦北区',
    cityCode: '4507',
    enName: 'Qinbei',
  },
  {
    code: '450721',
    name: '灵山县',
    cityCode: '4507',
    enName: 'Lingshan',
  },
  {
    code: '450722',
    name: '浦北县',
    cityCode: '4507',
    enName: 'Pubei',
  },
  {
    code: '450802',
    name: '港北区',
    cityCode: '4508',
    enName: 'Gangbei',
  },
  {
    code: '450803',
    name: '港南区',
    cityCode: '4508',
    enName: 'Gangnan',
  },
  {
    code: '450804',
    name: '覃塘区',
    cityCode: '4508',
    enName: 'Qintang',
  },
  {
    code: '450821',
    name: '平南县',
    cityCode: '4508',
    enName: 'Pingnan',
  },
  {
    code: '450881',
    name: '桂平市',
    cityCode: '4508',
    enName: 'Guiping',
  },
  {
    code: '450902',
    name: '玉州区',
    cityCode: '4509',
    enName: 'Yuzhou',
  },
  {
    code: '450903',
    name: '福绵区',
    cityCode: '4509',
    enName: 'Fumian',
  },
  {
    code: '450921',
    name: '容县',
    cityCode: '4509',
    enName: 'Rong',
  },
  {
    code: '450922',
    name: '陆川县',
    cityCode: '4509',
    enName: 'Luchuan',
  },
  {
    code: '450923',
    name: '博白县',
    cityCode: '4509',
    enName: 'Bobai',
  },
  {
    code: '450924',
    name: '兴业县',
    cityCode: '4509',
    enName: 'Xingye',
  },
  {
    code: '450981',
    name: '北流市',
    cityCode: '4509',
    enName: 'Beiliu',
  },
  {
    code: '451002',
    name: '右江区',
    cityCode: '4510',
    enName: 'Youjiang',
  },
  {
    code: '451021',
    name: '田阳县',
    cityCode: '4510',
    enName: 'Tianyang',
  },
  {
    code: '451022',
    name: '田东县',
    cityCode: '4510',
    enName: 'Tiandong',
  },
  {
    code: '451023',
    name: '平果县',
    cityCode: '4510',
    enName: 'Pingguo',
  },
  {
    code: '451024',
    name: '德保县',
    cityCode: '4510',
    enName: 'Debao',
  },
  {
    code: '451026',
    name: '那坡县',
    cityCode: '4510',
    enName: 'Napo',
  },
  {
    code: '451027',
    name: '凌云县',
    cityCode: '4510',
    enName: 'Lingyun',
  },
  {
    code: '451028',
    name: '乐业县',
    cityCode: '4510',
    enName: 'Leye',
  },
  {
    code: '451029',
    name: '田林县',
    cityCode: '4510',
    enName: 'Tianlin',
  },
  {
    code: '451030',
    name: '西林县',
    cityCode: '4510',
    enName: 'Xilin',
  },
  {
    code: '451031',
    name: '隆林各族自治县',
    cityCode: '4510',
    enName: 'Longlin Ge Autonomous County',
  },
  {
    code: '451081',
    name: '靖西市',
    cityCode: '4510',
    enName: 'Jingxi',
  },
  {
    code: '451102',
    name: '八步区',
    cityCode: '4511',
    enName: 'Babu',
  },
  {
    code: '451103',
    name: '平桂区',
    cityCode: '4511',
    enName: 'Pinggui',
  },
  {
    code: '451121',
    name: '昭平县',
    cityCode: '4511',
    enName: 'Zhaoping',
  },
  {
    code: '451122',
    name: '钟山县',
    cityCode: '4511',
    enName: 'Zhongshan',
  },
  {
    code: '451123',
    name: '富川瑶族自治县',
    cityCode: '4511',
    enName: 'Fuchuan Yao Autonomous County',
  },
  {
    code: '451202',
    name: '金城江区',
    cityCode: '4512',
    enName: 'Jinchengjiang',
  },
  {
    code: '451221',
    name: '南丹县',
    cityCode: '4512',
    enName: 'Nandan',
  },
  {
    code: '451222',
    name: '天峨县',
    cityCode: '4512',
    enName: 'Tiane',
  },
  {
    code: '451223',
    name: '凤山县',
    cityCode: '4512',
    enName: 'Fengshan',
  },
  {
    code: '451224',
    name: '东兰县',
    cityCode: '4512',
    enName: 'Donglan',
  },
  {
    code: '451225',
    name: '罗城仫佬族自治县',
    cityCode: '4512',
    enName: 'Luocheng Mulao Autonomous County',
  },
  {
    code: '451226',
    name: '环江毛南族自治县',
    cityCode: '4512',
    enName: 'Huanjiang Maonan Autonomous County',
  },
  {
    code: '451227',
    name: '巴马瑶族自治县',
    cityCode: '4512',
    enName: 'Bama Yao Autonomous County',
  },
  {
    code: '451228',
    name: '都安瑶族自治县',
    cityCode: '4512',
    enName: 'Duan Yao Autonomous County',
  },
  {
    code: '451229',
    name: '大化瑶族自治县',
    cityCode: '4512',
    enName: 'Dahua Yao Autonomous County',
  },
  {
    code: '451281',
    name: '宜州市',
    cityCode: '4512',
    enName: 'Yizhou',
  },
  {
    code: '451302',
    name: '兴宾区',
    cityCode: '4513',
    enName: 'Xingbin',
  },
  {
    code: '451321',
    name: '忻城县',
    cityCode: '4513',
    enName: 'Xincheng',
  },
  {
    code: '451322',
    name: '象州县',
    cityCode: '4513',
    enName: 'Xiangzhou',
  },
  {
    code: '451323',
    name: '武宣县',
    cityCode: '4513',
    enName: 'Wuxuan',
  },
  {
    code: '451324',
    name: '金秀瑶族自治县',
    cityCode: '4513',
    enName: 'Jinxiu Yao Autonomous County',
  },
  {
    code: '451381',
    name: '合山市',
    cityCode: '4513',
    enName: 'Heshan',
  },
  {
    code: '451402',
    name: '江州区',
    cityCode: '4514',
    enName: 'Jiangzhou',
  },
  {
    code: '451421',
    name: '扶绥县',
    cityCode: '4514',
    enName: 'Fusui',
  },
  {
    code: '451422',
    name: '宁明县',
    cityCode: '4514',
    enName: 'Ningming',
  },
  {
    code: '451423',
    name: '龙州县',
    cityCode: '4514',
    enName: 'Longzhou',
  },
  {
    code: '451424',
    name: '大新县',
    cityCode: '4514',
    enName: 'Daxin',
  },
  {
    code: '451425',
    name: '天等县',
    cityCode: '4514',
    enName: 'Tiandeng',
  },
  {
    code: '451481',
    name: '凭祥市',
    cityCode: '4514',
    enName: 'Pingxiang',
  },
  {
    code: '460105',
    name: '秀英区',
    cityCode: '4601',
    enName: 'Xiuying',
  },
  {
    code: '460106',
    name: '龙华区',
    cityCode: '4601',
    enName: 'Longhua',
  },
  {
    code: '460107',
    name: '琼山区',
    cityCode: '4601',
    enName: 'Qiongshan',
  },
  {
    code: '460108',
    name: '美兰区',
    cityCode: '4601',
    enName: 'Meilan',
  },
  {
    code: '460202',
    name: '海棠区',
    cityCode: '4602',
    enName: 'Haitang',
  },
  {
    code: '460203',
    name: '吉阳区',
    cityCode: '4602',
    enName: 'Jiyang',
  },
  {
    code: '460204',
    name: '天涯区',
    cityCode: '4602',
    enName: 'Tianya',
  },
  {
    code: '460205',
    name: '崖州区',
    cityCode: '4602',
    enName: 'Yazhou',
  },
  {
    code: '460300',
    name: '三沙市',
    cityCode: '4603',
    enName: 'Sansha',
  },
  {
    code: '460400',
    name: '儋州市',
    cityCode: '4604',
    enName: 'Danzhou',
  },
  {
    code: '469001',
    name: '五指山市',
    cityCode: '4690',
    enName: 'Wuzhishan',
  },
  {
    code: '469002',
    name: '琼海市',
    cityCode: '4690',
    enName: 'Qionghai',
  },
  {
    code: '469005',
    name: '文昌市',
    cityCode: '4690',
    enName: 'Wenchang',
  },
  {
    code: '469006',
    name: '万宁市',
    cityCode: '4690',
    enName: 'Wanning',
  },
  {
    code: '469007',
    name: '东方市',
    cityCode: '4690',
    enName: 'Dongfang',
  },
  {
    code: '469021',
    name: '定安县',
    cityCode: '4690',
    enName: 'Dingan',
  },
  {
    code: '469022',
    name: '屯昌县',
    cityCode: '4690',
    enName: 'Tunchang',
  },
  {
    code: '469023',
    name: '澄迈县',
    cityCode: '4690',
    enName: 'Chengmai',
  },
  {
    code: '469024',
    name: '临高县',
    cityCode: '4690',
    enName: 'Lingao',
  },
  {
    code: '469025',
    name: '白沙黎族自治县',
    cityCode: '4690',
    enName: 'Baisha Li Autonomous County',
  },
  {
    code: '469026',
    name: '昌江黎族自治县',
    cityCode: '4690',
    enName: 'Changjiang Li Autonomous County',
  },
  {
    code: '469027',
    name: '乐东黎族自治县',
    cityCode: '4690',
    enName: 'Ledong Li Autonomous County',
  },
  {
    code: '469028',
    name: '陵水黎族自治县',
    cityCode: '4690',
    enName: 'Lingshui Li Autonomous County',
  },
  {
    code: '469029',
    name: '保亭黎族苗族自治县',
    cityCode: '4690',
    enName: 'Baoting Li&Miao Autonomous County',
  },
  {
    code: '469030',
    name: '琼中黎族苗族自治县',
    cityCode: '4690',
    enName: 'Qiongzhong Li&Miao Autonomous County',
  },
  {
    code: '500101',
    name: '万州区',
    cityCode: '5001',
    enName: 'Wanzhou',
  },
  {
    code: '500102',
    name: '涪陵区',
    cityCode: '5001',
    enName: 'Fuling',
  },
  {
    code: '500103',
    name: '渝中区',
    cityCode: '5001',
    enName: 'Yuzhong',
  },
  {
    code: '500104',
    name: '大渡口区',
    cityCode: '5001',
    enName: 'Dadukou',
  },
  {
    code: '500105',
    name: '江北区',
    cityCode: '5001',
    enName: 'Jiangbei',
  },
  {
    code: '500106',
    name: '沙坪坝区',
    cityCode: '5001',
    enName: 'Shapingba',
  },
  {
    code: '500107',
    name: '九龙坡区',
    cityCode: '5001',
    enName: 'Jiulongpo',
  },
  {
    code: '500108',
    name: '南岸区',
    cityCode: '5001',
    enName: 'Nanan',
  },
  {
    code: '500109',
    name: '北碚区',
    cityCode: '5001',
    enName: 'Beibei',
  },
  {
    code: '500110',
    name: '綦江区',
    cityCode: '5001',
    enName: 'Qijiang',
  },
  {
    code: '500111',
    name: '大足区',
    cityCode: '5001',
    enName: 'Dazu',
  },
  {
    code: '500112',
    name: '渝北区',
    cityCode: '5001',
    enName: 'Yubei',
  },
  {
    code: '500113',
    name: '巴南区',
    cityCode: '5001',
    enName: 'Banan',
  },
  {
    code: '500114',
    name: '黔江区',
    cityCode: '5001',
    enName: 'Qianjiang',
  },
  {
    code: '500115',
    name: '长寿区',
    cityCode: '5001',
    enName: 'Changshou',
  },
  {
    code: '500116',
    name: '江津区',
    cityCode: '5001',
    enName: 'Jiangjin',
  },
  {
    code: '500117',
    name: '合川区',
    cityCode: '5001',
    enName: 'Hechuan',
  },
  {
    code: '500118',
    name: '永川区',
    cityCode: '5001',
    enName: 'Yongchuan',
  },
  {
    code: '500119',
    name: '南川区',
    cityCode: '5001',
    enName: 'Nanchuan',
  },
  {
    code: '500120',
    name: '璧山区',
    cityCode: '5001',
    enName: 'Bishan',
  },
  {
    code: '500151',
    name: '铜梁区',
    cityCode: '5001',
    enName: 'Tongliang',
  },
  {
    code: '500152',
    name: '潼南区',
    cityCode: '5001',
    enName: 'Tongnan',
  },
  {
    code: '500153',
    name: '荣昌区',
    cityCode: '5001',
    enName: 'Rongchang',
  },
  {
    code: '500154',
    name: '开州区',
    cityCode: '5001',
    enName: 'Kaizhou',
  },
  {
    code: '500228',
    name: '梁平县',
    cityCode: '5002',
    enName: 'Liangping',
  },
  {
    code: '500229',
    name: '城口县',
    cityCode: '5002',
    enName: 'Chengkou',
  },
  {
    code: '500230',
    name: '丰都县',
    cityCode: '5002',
    enName: 'Fengdou',
  },
  {
    code: '500231',
    name: '垫江县',
    cityCode: '5002',
    enName: 'Dianjiang',
  },
  {
    code: '500232',
    name: '武隆县',
    cityCode: '5002',
    enName: 'Wulong',
  },
  {
    code: '500233',
    name: '忠县',
    cityCode: '5002',
    enName: 'Zhong',
  },
  {
    code: '500235',
    name: '云阳县',
    cityCode: '5002',
    enName: 'Yunyang',
  },
  {
    code: '500236',
    name: '奉节县',
    cityCode: '5002',
    enName: 'Fengjie',
  },
  {
    code: '500237',
    name: '巫山县',
    cityCode: '5002',
    enName: 'Wushan',
  },
  {
    code: '500238',
    name: '巫溪县',
    cityCode: '5002',
    enName: 'Wuxi',
  },
  {
    code: '500240',
    name: '石柱土家族自治县',
    cityCode: '5002',
    enName: 'Shizhu Tujia Autonomous County',
  },
  {
    code: '500241',
    name: '秀山土家族苗族自治县',
    cityCode: '5002',
    enName: 'Xiushan Tujia&Miao Autonomous County',
  },
  {
    code: '500242',
    name: '酉阳土家族苗族自治县',
    cityCode: '5002',
    enName: 'Youyang Tujia&Miao Autonomous County',
  },
  {
    code: '500243',
    name: '彭水苗族土家族自治县',
    cityCode: '5002',
    enName: 'Pengshui Miao&Tujia Autonomous County',
  },
  {
    code: '510104',
    name: '锦江区',
    cityCode: '5101',
    enName: 'Jinjiang',
  },
  {
    code: '510105',
    name: '青羊区',
    cityCode: '5101',
    enName: 'Qingyang',
  },
  {
    code: '510106',
    name: '金牛区',
    cityCode: '5101',
    enName: 'Jinniu',
  },
  {
    code: '510107',
    name: '武侯区',
    cityCode: '5101',
    enName: 'Wuhou',
  },
  {
    code: '510108',
    name: '成华区',
    cityCode: '5101',
    enName: 'Chenghua',
  },
  {
    code: '510112',
    name: '龙泉驿区',
    cityCode: '5101',
    enName: 'Longanyiqu',
  },
  {
    code: '510113',
    name: '青白江区',
    cityCode: '5101',
    enName: 'Qingbaijiang',
  },
  {
    code: '510114',
    name: '新都区',
    cityCode: '5101',
    enName: 'Xindou',
  },
  {
    code: '510115',
    name: '温江区',
    cityCode: '5101',
    enName: 'Wenjiang',
  },
  {
    code: '510116',
    name: '双流区',
    cityCode: '5101',
    enName: 'Shuangliu',
  },
  {
    code: '510121',
    name: '金堂县',
    cityCode: '5101',
    enName: 'Jintang',
  },
  {
    code: '510124',
    name: '郫县',
    cityCode: '5101',
    enName: 'Pi',
  },
  {
    code: '510129',
    name: '大邑县',
    cityCode: '5101',
    enName: 'Dayi',
  },
  {
    code: '510131',
    name: '蒲江县',
    cityCode: '5101',
    enName: 'Pujiang',
  },
  {
    code: '510132',
    name: '新津县',
    cityCode: '5101',
    enName: 'Xinjin',
  },
  {
    code: '510181',
    name: '都江堰市',
    cityCode: '5101',
    enName: 'Dujiangyan',
  },
  {
    code: '510182',
    name: '彭州市',
    cityCode: '5101',
    enName: 'Pengzhou',
  },
  {
    code: '510183',
    name: '邛崃市',
    cityCode: '5101',
    enName: 'Qionglai',
  },
  {
    code: '510184',
    name: '崇州市',
    cityCode: '5101',
    enName: 'Chongzhou',
  },
  {
    code: '510185',
    name: '简阳市',
    cityCode: '5101',
    enName: 'Jianyang',
  },
  {
    code: '510302',
    name: '自流井区',
    cityCode: '5103',
    enName: 'Ziliujing',
  },
  {
    code: '510303',
    name: '贡井区',
    cityCode: '5103',
    enName: 'Gongjing',
  },
  {
    code: '510304',
    name: '大安区',
    cityCode: '5103',
    enName: 'Daan',
  },
  {
    code: '510311',
    name: '沿滩区',
    cityCode: '5103',
    enName: 'Yantan',
  },
  {
    code: '510321',
    name: '荣县',
    cityCode: '5103',
    enName: 'Rong',
  },
  {
    code: '510322',
    name: '富顺县',
    cityCode: '5103',
    enName: 'Fushun',
  },
  {
    code: '510402',
    name: '东区',
    cityCode: '5104',
    enName: 'Dong',
  },
  {
    code: '510403',
    name: '西区',
    cityCode: '5104',
    enName: 'Xi',
  },
  {
    code: '510411',
    name: '仁和区',
    cityCode: '5104',
    enName: 'Renhe',
  },
  {
    code: '510421',
    name: '米易县',
    cityCode: '5104',
    enName: 'Miyi',
  },
  {
    code: '510422',
    name: '盐边县',
    cityCode: '5104',
    enName: 'Yanbian',
  },
  {
    code: '510502',
    name: '江阳区',
    cityCode: '5105',
    enName: 'Jiangyang',
  },
  {
    code: '510503',
    name: '纳溪区',
    cityCode: '5105',
    enName: 'Naxi',
  },
  {
    code: '510504',
    name: '龙马潭区',
    cityCode: '5105',
    enName: 'Longmatan',
  },
  {
    code: '510521',
    name: '泸县',
    cityCode: '5105',
    enName: 'Lu',
  },
  {
    code: '510522',
    name: '合江县',
    cityCode: '5105',
    enName: 'Hejiang',
  },
  {
    code: '510524',
    name: '叙永县',
    cityCode: '5105',
    enName: 'Xuyong',
  },
  {
    code: '510525',
    name: '古蔺县',
    cityCode: '5105',
    enName: 'Gulin',
  },
  {
    code: '510603',
    name: '旌阳区',
    cityCode: '5106',
    enName: 'Jingyang',
  },
  {
    code: '510623',
    name: '中江县',
    cityCode: '5106',
    enName: 'Zhongjiang',
  },
  {
    code: '510626',
    name: '罗江县',
    cityCode: '5106',
    enName: 'Luojiang',
  },
  {
    code: '510681',
    name: '广汉市',
    cityCode: '5106',
    enName: 'Guanghan',
  },
  {
    code: '510682',
    name: '什邡市',
    cityCode: '5106',
    enName: 'Shifang',
  },
  {
    code: '510683',
    name: '绵竹市',
    cityCode: '5106',
    enName: 'Mianzhu',
  },
  {
    code: '510703',
    name: '涪城区',
    cityCode: '5107',
    enName: 'Fucheng',
  },
  {
    code: '510704',
    name: '游仙区',
    cityCode: '5107',
    enName: 'You',
  },
  {
    code: '510705',
    name: '安州区',
    cityCode: '5107',
    enName: 'Anzhou',
  },
  {
    code: '510722',
    name: '三台县',
    cityCode: '5107',
    enName: 'Santai',
  },
  {
    code: '510723',
    name: '盐亭县',
    cityCode: '5107',
    enName: 'Yanting',
  },
  {
    code: '510725',
    name: '梓潼县',
    cityCode: '5107',
    enName: 'Zitong',
  },
  {
    code: '510726',
    name: '北川羌族自治县',
    cityCode: '5107',
    enName: 'Beichuan Qiang Autonomous County',
  },
  {
    code: '510727',
    name: '平武县',
    cityCode: '5107',
    enName: 'Pingwu',
  },
  {
    code: '510781',
    name: '江油市',
    cityCode: '5107',
    enName: 'Jiangyou',
  },
  {
    code: '510802',
    name: '利州区',
    cityCode: '5108',
    enName: 'Lizhou',
  },
  {
    code: '510811',
    name: '昭化区',
    cityCode: '5108',
    enName: 'Zhaohua',
  },
  {
    code: '510812',
    name: '朝天区',
    cityCode: '5108',
    enName: 'Chaotian',
  },
  {
    code: '510821',
    name: '旺苍县',
    cityCode: '5108',
    enName: 'Wangcang',
  },
  {
    code: '510822',
    name: '青川县',
    cityCode: '5108',
    enName: 'Qingchuan',
  },
  {
    code: '510823',
    name: '剑阁县',
    cityCode: '5108',
    enName: 'Jiange',
  },
  {
    code: '510824',
    name: '苍溪县',
    cityCode: '5108',
    enName: 'Cangxi',
  },
  {
    code: '510903',
    name: '船山区',
    cityCode: '5109',
    enName: 'Chuanshan',
  },
  {
    code: '510904',
    name: '安居区',
    cityCode: '5109',
    enName: 'Anju',
  },
  {
    code: '510921',
    name: '蓬溪县',
    cityCode: '5109',
    enName: 'Pengxi',
  },
  {
    code: '510922',
    name: '射洪县',
    cityCode: '5109',
    enName: 'Shehong',
  },
  {
    code: '510923',
    name: '大英县',
    cityCode: '5109',
    enName: 'Daying',
  },
  {
    code: '511002',
    name: '市中区',
    cityCode: '5110',
    enName: 'Shizhong',
  },
  {
    code: '511011',
    name: '东兴区',
    cityCode: '5110',
    enName: 'Dongxing',
  },
  {
    code: '511024',
    name: '威远县',
    cityCode: '5110',
    enName: 'Weiyuan',
  },
  {
    code: '511025',
    name: '资中县',
    cityCode: '5110',
    enName: 'Zizhong',
  },
  {
    code: '511028',
    name: '隆昌县',
    cityCode: '5110',
    enName: 'Longchang',
  },
  {
    code: '511102',
    name: '市中区',
    cityCode: '5111',
    enName: 'Shizhong',
  },
  {
    code: '511111',
    name: '沙湾区',
    cityCode: '5111',
    enName: 'Shawan',
  },
  {
    code: '511112',
    name: '五通桥区',
    cityCode: '5111',
    enName: 'Wutongqiao',
  },
  {
    code: '511113',
    name: '金口河区',
    cityCode: '5111',
    enName: 'Jinkouhe',
  },
  {
    code: '511123',
    name: '犍为县',
    cityCode: '5111',
    enName: 'Qianwei',
  },
  {
    code: '511124',
    name: '井研县',
    cityCode: '5111',
    enName: 'Jingyan',
  },
  {
    code: '511126',
    name: '夹江县',
    cityCode: '5111',
    enName: 'Jiajiang',
  },
  {
    code: '511129',
    name: '沐川县',
    cityCode: '5111',
    enName: 'Muchuan',
  },
  {
    code: '511132',
    name: '峨边彝族自治县',
    cityCode: '5111',
    enName: 'Ebian Yi Autonomous County',
  },
  {
    code: '511133',
    name: '马边彝族自治县',
    cityCode: '5111',
    enName: 'Mabian Yi Autonomous County',
  },
  {
    code: '511181',
    name: '峨眉山市',
    cityCode: '5111',
    enName: 'Emeishan',
  },
  {
    code: '511302',
    name: '顺庆区',
    cityCode: '5113',
    enName: 'Shunqing',
  },
  {
    code: '511303',
    name: '高坪区',
    cityCode: '5113',
    enName: 'Gaoping',
  },
  {
    code: '511304',
    name: '嘉陵区',
    cityCode: '5113',
    enName: 'Jialing',
  },
  {
    code: '511321',
    name: '南部县',
    cityCode: '5113',
    enName: 'Nanbu',
  },
  {
    code: '511322',
    name: '营山县',
    cityCode: '5113',
    enName: 'Yingshan',
  },
  {
    code: '511323',
    name: '蓬安县',
    cityCode: '5113',
    enName: 'Pengan',
  },
  {
    code: '511324',
    name: '仪陇县',
    cityCode: '5113',
    enName: 'Yilong',
  },
  {
    code: '511325',
    name: '西充县',
    cityCode: '5113',
    enName: 'Xichong',
  },
  {
    code: '511381',
    name: '阆中市',
    cityCode: '5113',
    enName: 'Langzhong',
  },
  {
    code: '511402',
    name: '东坡区',
    cityCode: '5114',
    enName: 'Dongpo',
  },
  {
    code: '511403',
    name: '彭山区',
    cityCode: '5114',
    enName: 'Pengshan',
  },
  {
    code: '511421',
    name: '仁寿县',
    cityCode: '5114',
    enName: 'Renshou',
  },
  {
    code: '511423',
    name: '洪雅县',
    cityCode: '5114',
    enName: 'Hongya',
  },
  {
    code: '511424',
    name: '丹棱县',
    cityCode: '5114',
    enName: 'Danleng',
  },
  {
    code: '511425',
    name: '青神县',
    cityCode: '5114',
    enName: 'Qingshen',
  },
  {
    code: '511502',
    name: '翠屏区',
    cityCode: '5115',
    enName: 'Cuiping',
  },
  {
    code: '511503',
    name: '南溪区',
    cityCode: '5115',
    enName: 'Nanxi',
  },
  {
    code: '511521',
    name: '宜宾县',
    cityCode: '5115',
    enName: 'Yibin',
  },
  {
    code: '511523',
    name: '江安县',
    cityCode: '5115',
    enName: 'Jiangan',
  },
  {
    code: '511524',
    name: '长宁县',
    cityCode: '5115',
    enName: 'Changning',
  },
  {
    code: '511525',
    name: '高县',
    cityCode: '5115',
    enName: 'Gao',
  },
  {
    code: '511526',
    name: '珙县',
    cityCode: '5115',
    enName: 'Gong',
  },
  {
    code: '511527',
    name: '筠连县',
    cityCode: '5115',
    enName: 'Yunlian',
  },
  {
    code: '511528',
    name: '兴文县',
    cityCode: '5115',
    enName: 'Xingwen',
  },
  {
    code: '511529',
    name: '屏山县',
    cityCode: '5115',
    enName: 'Pingshan',
  },
  {
    code: '511602',
    name: '广安区',
    cityCode: '5116',
    enName: 'Guangan',
  },
  {
    code: '511603',
    name: '前锋区',
    cityCode: '5116',
    enName: 'Qianfeng',
  },
  {
    code: '511621',
    name: '岳池县',
    cityCode: '5116',
    enName: 'Yuechi',
  },
  {
    code: '511622',
    name: '武胜县',
    cityCode: '5116',
    enName: 'Wusheng',
  },
  {
    code: '511623',
    name: '邻水县',
    cityCode: '5116',
    enName: 'Linshui',
  },
  {
    code: '511681',
    name: '华蓥市',
    cityCode: '5116',
    enName: 'Huaying',
  },
  {
    code: '511702',
    name: '通川区',
    cityCode: '5117',
    enName: 'Tongchuan',
  },
  {
    code: '511703',
    name: '达川区',
    cityCode: '5117',
    enName: 'Dachuan',
  },
  {
    code: '511722',
    name: '宣汉县',
    cityCode: '5117',
    enName: 'Xuanhan',
  },
  {
    code: '511723',
    name: '开江县',
    cityCode: '5117',
    enName: 'Kaijiang',
  },
  {
    code: '511724',
    name: '大竹县',
    cityCode: '5117',
    enName: 'Dazhu',
  },
  {
    code: '511725',
    name: '渠县',
    cityCode: '5117',
    enName: 'Qu',
  },
  {
    code: '511781',
    name: '万源市',
    cityCode: '5117',
    enName: 'Wanyuan',
  },
  {
    code: '511802',
    name: '雨城区',
    cityCode: '5118',
    enName: 'Yucheng',
  },
  {
    code: '511803',
    name: '名山区',
    cityCode: '5118',
    enName: 'Mingshan',
  },
  {
    code: '511822',
    name: '荥经县',
    cityCode: '5118',
    enName: 'Yingjing',
  },
  {
    code: '511823',
    name: '汉源县',
    cityCode: '5118',
    enName: 'Hanyuan',
  },
  {
    code: '511824',
    name: '石棉县',
    cityCode: '5118',
    enName: 'Shimian',
  },
  {
    code: '511825',
    name: '天全县',
    cityCode: '5118',
    enName: 'Tianan',
  },
  {
    code: '511826',
    name: '芦山县',
    cityCode: '5118',
    enName: 'Lushan',
  },
  {
    code: '511827',
    name: '宝兴县',
    cityCode: '5118',
    enName: 'Baoxing',
  },
  {
    code: '511902',
    name: '巴州区',
    cityCode: '5119',
    enName: 'Bazhou',
  },
  {
    code: '511903',
    name: '恩阳区',
    cityCode: '5119',
    enName: 'Enyang',
  },
  {
    code: '511921',
    name: '通江县',
    cityCode: '5119',
    enName: 'Tongjiang',
  },
  {
    code: '511922',
    name: '南江县',
    cityCode: '5119',
    enName: 'Nanjiang',
  },
  {
    code: '511923',
    name: '平昌县',
    cityCode: '5119',
    enName: 'Pingchang',
  },
  {
    code: '512002',
    name: '雁江区',
    cityCode: '5120',
    enName: 'Yanjiang',
  },
  {
    code: '512021',
    name: '安岳县',
    cityCode: '5120',
    enName: 'Anyue',
  },
  {
    code: '512022',
    name: '乐至县',
    cityCode: '5120',
    enName: 'Lezhi',
  },
  {
    code: '513201',
    name: '马尔康市',
    cityCode: '5132',
    enName: 'Maerkang',
  },
  {
    code: '513221',
    name: '汶川县',
    cityCode: '5132',
    enName: 'Wenchuan',
  },
  {
    code: '513222',
    name: '理县',
    cityCode: '5132',
    enName: 'Li',
  },
  {
    code: '513223',
    name: '茂县',
    cityCode: '5132',
    enName: 'Mao',
  },
  {
    code: '513224',
    name: '松潘县',
    cityCode: '5132',
    enName: 'Songpan',
  },
  {
    code: '513225',
    name: '九寨沟县',
    cityCode: '5132',
    enName: 'Jiuzhaigou',
  },
  {
    code: '513226',
    name: '金川县',
    cityCode: '5132',
    enName: 'Jinchuan',
  },
  {
    code: '513227',
    name: '小金县',
    cityCode: '5132',
    enName: 'Xiaojin',
  },
  {
    code: '513228',
    name: '黑水县',
    cityCode: '5132',
    enName: 'Heishui',
  },
  {
    code: '513230',
    name: '壤塘县',
    cityCode: '5132',
    enName: 'Rangtang',
  },
  {
    code: '513231',
    name: '阿坝县',
    cityCode: '5132',
    enName: 'Aba',
  },
  {
    code: '513232',
    name: '若尔盖县',
    cityCode: '5132',
    enName: 'Ruoergai',
  },
  {
    code: '513233',
    name: '红原县',
    cityCode: '5132',
    enName: 'Hongyuan',
  },
  {
    code: '513301',
    name: '康定市',
    cityCode: '5133',
    enName: 'Kangding',
  },
  {
    code: '513322',
    name: '泸定县',
    cityCode: '5133',
    enName: 'Luding',
  },
  {
    code: '513323',
    name: '丹巴县',
    cityCode: '5133',
    enName: 'Danba',
  },
  {
    code: '513324',
    name: '九龙县',
    cityCode: '5133',
    enName: 'Jiulong',
  },
  {
    code: '513325',
    name: '雅江县',
    cityCode: '5133',
    enName: 'Yajiang',
  },
  {
    code: '513326',
    name: '道孚县',
    cityCode: '5133',
    enName: 'Daofu',
  },
  {
    code: '513327',
    name: '炉霍县',
    cityCode: '5133',
    enName: 'Luhuo',
  },
  {
    code: '513328',
    name: '甘孜县',
    cityCode: '5133',
    enName: 'Ganzi',
  },
  {
    code: '513329',
    name: '新龙县',
    cityCode: '5133',
    enName: 'Xinlong',
  },
  {
    code: '513330',
    name: '德格县',
    cityCode: '5133',
    enName: 'Dege',
  },
  {
    code: '513331',
    name: '白玉县',
    cityCode: '5133',
    enName: 'Baiyu',
  },
  {
    code: '513332',
    name: '石渠县',
    cityCode: '5133',
    enName: 'Shi',
  },
  {
    code: '513333',
    name: '色达县',
    cityCode: '5133',
    enName: 'Seda',
  },
  {
    code: '513334',
    name: '理塘县',
    cityCode: '5133',
    enName: 'Litang',
  },
  {
    code: '513335',
    name: '巴塘县',
    cityCode: '5133',
    enName: 'Batang',
  },
  {
    code: '513336',
    name: '乡城县',
    cityCode: '5133',
    enName: 'Xiangcheng',
  },
  {
    code: '513337',
    name: '稻城县',
    cityCode: '5133',
    enName: 'Daocheng',
  },
  {
    code: '513338',
    name: '得荣县',
    cityCode: '5133',
    enName: 'Derong',
  },
  {
    code: '513401',
    name: '西昌市',
    cityCode: '5134',
    enName: 'Xichang',
  },
  {
    code: '513422',
    name: '木里藏族自治县',
    cityCode: '5134',
    enName: 'Muli Tibetan Autonomous County',
  },
  {
    code: '513423',
    name: '盐源县',
    cityCode: '5134',
    enName: 'Yanyuan',
  },
  {
    code: '513424',
    name: '德昌县',
    cityCode: '5134',
    enName: 'Dechang',
  },
  {
    code: '513425',
    name: '会理县',
    cityCode: '5134',
    enName: 'Huili',
  },
  {
    code: '513426',
    name: '会东县',
    cityCode: '5134',
    enName: 'Huidong',
  },
  {
    code: '513427',
    name: '宁南县',
    cityCode: '5134',
    enName: 'Ningnan',
  },
  {
    code: '513428',
    name: '普格县',
    cityCode: '5134',
    enName: 'Puge',
  },
  {
    code: '513429',
    name: '布拖县',
    cityCode: '5134',
    enName: 'Butuo',
  },
  {
    code: '513430',
    name: '金阳县',
    cityCode: '5134',
    enName: 'Jinyang',
  },
  {
    code: '513431',
    name: '昭觉县',
    cityCode: '5134',
    enName: 'Zhaojue',
  },
  {
    code: '513432',
    name: '喜德县',
    cityCode: '5134',
    enName: 'Xide',
  },
  {
    code: '513433',
    name: '冕宁县',
    cityCode: '5134',
    enName: 'Mianning',
  },
  {
    code: '513434',
    name: '越西县',
    cityCode: '5134',
    enName: 'Yuexi',
  },
  {
    code: '513435',
    name: '甘洛县',
    cityCode: '5134',
    enName: 'Ganluo',
  },
  {
    code: '513436',
    name: '美姑县',
    cityCode: '5134',
    enName: 'Meigu',
  },
  {
    code: '513437',
    name: '雷波县',
    cityCode: '5134',
    enName: 'Leibo',
  },
  {
    code: '520102',
    name: '南明区',
    cityCode: '5201',
    enName: 'Nanming',
  },
  {
    code: '520103',
    name: '云岩区',
    cityCode: '5201',
    enName: 'Yunyan',
  },
  {
    code: '520111',
    name: '花溪区',
    cityCode: '5201',
    enName: 'Huaxi',
  },
  {
    code: '520112',
    name: '乌当区',
    cityCode: '5201',
    enName: 'Wudang',
  },
  {
    code: '520113',
    name: '白云区',
    cityCode: '5201',
    enName: 'Baiyun',
  },
  {
    code: '520115',
    name: '观山湖区',
    cityCode: '5201',
    enName: 'Guanshanhu',
  },
  {
    code: '520121',
    name: '开阳县',
    cityCode: '5201',
    enName: 'Kaiyang',
  },
  {
    code: '520122',
    name: '息烽县',
    cityCode: '5201',
    enName: 'Xifeng',
  },
  {
    code: '520123',
    name: '修文县',
    cityCode: '5201',
    enName: 'Xiuwen',
  },
  {
    code: '520181',
    name: '清镇市',
    cityCode: '5201',
    enName: 'Qingzhen',
  },
  {
    code: '520201',
    name: '钟山区',
    cityCode: '5202',
    enName: 'Zhongshan',
  },
  {
    code: '520203',
    name: '六枝特区',
    cityCode: '5202',
    enName: 'Liuzhite',
  },
  {
    code: '520221',
    name: '水城县',
    cityCode: '5202',
    enName: 'Shuicheng',
  },
  {
    code: '520222',
    name: '盘县',
    cityCode: '5202',
    enName: 'Pan',
  },
  {
    code: '520302',
    name: '红花岗区',
    cityCode: '5203',
    enName: 'Honghuagang',
  },
  {
    code: '520303',
    name: '汇川区',
    cityCode: '5203',
    enName: 'Huichuan',
  },
  {
    code: '520304',
    name: '播州区',
    cityCode: '5203',
    enName: 'Bozhou',
  },
  {
    code: '520322',
    name: '桐梓县',
    cityCode: '5203',
    enName: 'Tongzi',
  },
  {
    code: '520323',
    name: '绥阳县',
    cityCode: '5203',
    enName: 'Suiyang',
  },
  {
    code: '520324',
    name: '正安县',
    cityCode: '5203',
    enName: 'Zhengan',
  },
  {
    code: '520325',
    name: '道真仡佬族苗族自治县',
    cityCode: '5203',
    enName: 'Daozhen Gelao&Miao Autonomous County',
  },
  {
    code: '520326',
    name: '务川仡佬族苗族自治县',
    cityCode: '5203',
    enName: 'Wuchuan Gelao&Miao Autonomous County',
  },
  {
    code: '520327',
    name: '凤冈县',
    cityCode: '5203',
    enName: 'Fenggang',
  },
  {
    code: '520328',
    name: '湄潭县',
    cityCode: '5203',
    enName: 'Meitan',
  },
  {
    code: '520329',
    name: '余庆县',
    cityCode: '5203',
    enName: 'Yuqing',
  },
  {
    code: '520330',
    name: '习水县',
    cityCode: '5203',
    enName: 'Xishui',
  },
  {
    code: '520381',
    name: '赤水市',
    cityCode: '5203',
    enName: 'Chishui',
  },
  {
    code: '520382',
    name: '仁怀市',
    cityCode: '5203',
    enName: 'Renhuai',
  },
  {
    code: '520402',
    name: '西秀区',
    cityCode: '5204',
    enName: 'Xixiu',
  },
  {
    code: '520403',
    name: '平坝区',
    cityCode: '5204',
    enName: 'Pingba',
  },
  {
    code: '520422',
    name: '普定县',
    cityCode: '5204',
    enName: 'Puding',
  },
  {
    code: '520423',
    name: '镇宁布依族苗族自治县',
    cityCode: '5204',
    enName: 'Zhenning Buyi&Miao Autonomous County',
  },
  {
    code: '520424',
    name: '关岭布依族苗族自治县',
    cityCode: '5204',
    enName: 'Guanling Buyi&Miao Autonomous County',
  },
  {
    code: '520425',
    name: '紫云苗族布依族自治县',
    cityCode: '5204',
    enName: 'Ziyun Miao&Buyi Autonomous County',
  },
  {
    code: '520502',
    name: '七星关区',
    cityCode: '5205',
    enName: 'Qixingguan',
  },
  {
    code: '520521',
    name: '大方县',
    cityCode: '5205',
    enName: 'Dafang',
  },
  {
    code: '520522',
    name: '黔西县',
    cityCode: '5205',
    enName: 'Qianxi',
  },
  {
    code: '520523',
    name: '金沙县',
    cityCode: '5205',
    enName: 'Jinsha',
  },
  {
    code: '520524',
    name: '织金县',
    cityCode: '5205',
    enName: 'Zhijin',
  },
  {
    code: '520525',
    name: '纳雍县',
    cityCode: '5205',
    enName: 'Nayong',
  },
  {
    code: '520526',
    name: '威宁彝族回族苗族自治县',
    cityCode: '5205',
    enName: 'Weining Yi&Hui&Miao Autonomous County',
  },
  {
    code: '520527',
    name: '赫章县',
    cityCode: '5205',
    enName: 'Hezhang',
  },
  {
    code: '520602',
    name: '碧江区',
    cityCode: '5206',
    enName: 'Bijiang',
  },
  {
    code: '520603',
    name: '万山区',
    cityCode: '5206',
    enName: 'Wanshan',
  },
  {
    code: '520621',
    name: '江口县',
    cityCode: '5206',
    enName: 'Jiangkou',
  },
  {
    code: '520622',
    name: '玉屏侗族自治县',
    cityCode: '5206',
    enName: 'Yuping Dong Autonomous County',
  },
  {
    code: '520623',
    name: '石阡县',
    cityCode: '5206',
    enName: 'Shiqian',
  },
  {
    code: '520624',
    name: '思南县',
    cityCode: '5206',
    enName: 'Sinan',
  },
  {
    code: '520625',
    name: '印江土家族苗族自治县',
    cityCode: '5206',
    enName: 'Yinjiang Tujia&Miao Autonomous County',
  },
  {
    code: '520626',
    name: '德江县',
    cityCode: '5206',
    enName: 'Dejiang',
  },
  {
    code: '520627',
    name: '沿河土家族自治县',
    cityCode: '5206',
    enName: 'Yanhe Tujia Autonomous County',
  },
  {
    code: '520628',
    name: '松桃苗族自治县',
    cityCode: '5206',
    enName: 'Songtao Miao Autonomous County',
  },
  {
    code: '522301',
    name: '兴义市',
    cityCode: '5223',
    enName: 'Xingyi',
  },
  {
    code: '522322',
    name: '兴仁县',
    cityCode: '5223',
    enName: 'Xingren',
  },
  {
    code: '522323',
    name: '普安县',
    cityCode: '5223',
    enName: 'Puan',
  },
  {
    code: '522324',
    name: '晴隆县',
    cityCode: '5223',
    enName: 'Qinglong',
  },
  {
    code: '522325',
    name: '贞丰县',
    cityCode: '5223',
    enName: 'Zhenfeng',
  },
  {
    code: '522326',
    name: '望谟县',
    cityCode: '5223',
    enName: 'Wangmo',
  },
  {
    code: '522327',
    name: '册亨县',
    cityCode: '5223',
    enName: 'Ceheng',
  },
  {
    code: '522328',
    name: '安龙县',
    cityCode: '5223',
    enName: 'Anlong',
  },
  {
    code: '522601',
    name: '凯里市',
    cityCode: '5226',
    enName: 'Kaili',
  },
  {
    code: '522622',
    name: '黄平县',
    cityCode: '5226',
    enName: 'Huangping',
  },
  {
    code: '522623',
    name: '施秉县',
    cityCode: '5226',
    enName: 'Shibing',
  },
  {
    code: '522624',
    name: '三穗县',
    cityCode: '5226',
    enName: 'Sansui',
  },
  {
    code: '522625',
    name: '镇远县',
    cityCode: '5226',
    enName: 'Zhenyuan',
  },
  {
    code: '522626',
    name: '岑巩县',
    cityCode: '5226',
    enName: 'Cengong',
  },
  {
    code: '522627',
    name: '天柱县',
    cityCode: '5226',
    enName: 'Tianzhu',
  },
  {
    code: '522628',
    name: '锦屏县',
    cityCode: '5226',
    enName: 'Jinping',
  },
  {
    code: '522629',
    name: '剑河县',
    cityCode: '5226',
    enName: 'Jianhe',
  },
  {
    code: '522630',
    name: '台江县',
    cityCode: '5226',
    enName: 'Taijiang',
  },
  {
    code: '522631',
    name: '黎平县',
    cityCode: '5226',
    enName: 'Liping',
  },
  {
    code: '522632',
    name: '榕江县',
    cityCode: '5226',
    enName: 'Rongjiang',
  },
  {
    code: '522633',
    name: '从江县',
    cityCode: '5226',
    enName: 'Congjiang',
  },
  {
    code: '522634',
    name: '雷山县',
    cityCode: '5226',
    enName: 'Leishan',
  },
  {
    code: '522635',
    name: '麻江县',
    cityCode: '5226',
    enName: 'Majiang',
  },
  {
    code: '522636',
    name: '丹寨县',
    cityCode: '5226',
    enName: 'Danzhai',
  },
  {
    code: '522701',
    name: '都匀市',
    cityCode: '5227',
    enName: 'Douyun',
  },
  {
    code: '522702',
    name: '福泉市',
    cityCode: '5227',
    enName: 'Fuquan',
  },
  {
    code: '522722',
    name: '荔波县',
    cityCode: '5227',
    enName: 'Libo',
  },
  {
    code: '522723',
    name: '贵定县',
    cityCode: '5227',
    enName: 'Guiding',
  },
  {
    code: '522725',
    name: '瓮安县',
    cityCode: '5227',
    enName: 'Wengan',
  },
  {
    code: '522726',
    name: '独山县',
    cityCode: '5227',
    enName: 'Dushan',
  },
  {
    code: '522727',
    name: '平塘县',
    cityCode: '5227',
    enName: 'Pingtang',
  },
  {
    code: '522728',
    name: '罗甸县',
    cityCode: '5227',
    enName: 'Luodian',
  },
  {
    code: '522729',
    name: '长顺县',
    cityCode: '5227',
    enName: 'Changshun',
  },
  {
    code: '522730',
    name: '龙里县',
    cityCode: '5227',
    enName: 'Longli',
  },
  {
    code: '522731',
    name: '惠水县',
    cityCode: '5227',
    enName: 'Huishui',
  },
  {
    code: '522732',
    name: '三都水族自治县',
    cityCode: '5227',
    enName: 'Sandu Shui Autonomous County',
  },
  {
    code: '530102',
    name: '五华区',
    cityCode: '5301',
    enName: 'Wuhua',
  },
  {
    code: '530103',
    name: '盘龙区',
    cityCode: '5301',
    enName: 'Panlong',
  },
  {
    code: '530111',
    name: '官渡区',
    cityCode: '5301',
    enName: 'Guandu',
  },
  {
    code: '530112',
    name: '西山区',
    cityCode: '5301',
    enName: 'Xishan',
  },
  {
    code: '530113',
    name: '东川区',
    cityCode: '5301',
    enName: 'Dongchuan',
  },
  {
    code: '530114',
    name: '呈贡区',
    cityCode: '5301',
    enName: 'Chenggong',
  },
  {
    code: '530122',
    name: '晋宁县',
    cityCode: '5301',
    enName: 'Jinning',
  },
  {
    code: '530124',
    name: '富民县',
    cityCode: '5301',
    enName: 'Fumin',
  },
  {
    code: '530125',
    name: '宜良县',
    cityCode: '5301',
    enName: 'Yiliang',
  },
  {
    code: '530126',
    name: '石林彝族自治县',
    cityCode: '5301',
    enName: 'Shilin Yi Autonomous County',
  },
  {
    code: '530127',
    name: '嵩明县',
    cityCode: '5301',
    enName: 'Songming',
  },
  {
    code: '530128',
    name: '禄劝彝族苗族自治县',
    cityCode: '5301',
    enName: 'Lu quan Yi&Miao Autonomous County',
  },
  {
    code: '530129',
    name: '寻甸回族彝族自治县',
    cityCode: '5301',
    enName: 'Xundian Hui&Yi Autonomous County',
  },
  {
    code: '530181',
    name: '安宁市',
    cityCode: '5301',
    enName: 'Anningshi',
  },
  {
    code: '530302',
    name: '麒麟区',
    cityCode: '5303',
    enName: 'Qilin',
  },
  {
    code: '530303',
    name: '沾益区',
    cityCode: '5303',
    enName: 'Zhanyi',
  },
  {
    code: '530321',
    name: '马龙县',
    cityCode: '5303',
    enName: 'Malong',
  },
  {
    code: '530322',
    name: '陆良县',
    cityCode: '5303',
    enName: 'Luliang',
  },
  {
    code: '530323',
    name: '师宗县',
    cityCode: '5303',
    enName: 'Shizong',
  },
  {
    code: '530324',
    name: '罗平县',
    cityCode: '5303',
    enName: 'Luoping',
  },
  {
    code: '530325',
    name: '富源县',
    cityCode: '5303',
    enName: 'Fuyuan',
  },
  {
    code: '530326',
    name: '会泽县',
    cityCode: '5303',
    enName: 'Huize',
  },
  {
    code: '530381',
    name: '宣威市',
    cityCode: '5303',
    enName: 'Xuanwei',
  },
  {
    code: '530402',
    name: '红塔区',
    cityCode: '5304',
    enName: 'Hongta',
  },
  {
    code: '530403',
    name: '江川区',
    cityCode: '5304',
    enName: 'Jiangchuan',
  },
  {
    code: '530422',
    name: '澄江县',
    cityCode: '5304',
    enName: 'Chengjiang',
  },
  {
    code: '530423',
    name: '通海县',
    cityCode: '5304',
    enName: 'Tonghai',
  },
  {
    code: '530424',
    name: '华宁县',
    cityCode: '5304',
    enName: 'Huaning',
  },
  {
    code: '530425',
    name: '易门县',
    cityCode: '5304',
    enName: 'Yimen',
  },
  {
    code: '530426',
    name: '峨山彝族自治县',
    cityCode: '5304',
    enName: 'Eshan Yi Autonomous County',
  },
  {
    code: '530427',
    name: '新平彝族傣族自治县',
    cityCode: '5304',
    enName: 'Xinping Yi&Dai Autonomous County',
  },
  {
    code: '530428',
    name: '元江哈尼族彝族傣族自治县',
    cityCode: '5304',
    enName: 'Yuanjiang Hani&Yi&Dai Autonomous County',
  },
  {
    code: '530502',
    name: '隆阳区',
    cityCode: '5305',
    enName: 'Longyang',
  },
  {
    code: '530521',
    name: '施甸县',
    cityCode: '5305',
    enName: 'Shidian',
  },
  {
    code: '530523',
    name: '龙陵县',
    cityCode: '5305',
    enName: 'Longling',
  },
  {
    code: '530524',
    name: '昌宁县',
    cityCode: '5305',
    enName: 'Changning',
  },
  {
    code: '530581',
    name: '腾冲市',
    cityCode: '5305',
    enName: 'Tengchong',
  },
  {
    code: '530602',
    name: '昭阳区',
    cityCode: '5306',
    enName: 'Zhaoyang',
  },
  {
    code: '530621',
    name: '鲁甸县',
    cityCode: '5306',
    enName: 'Ludian',
  },
  {
    code: '530622',
    name: '巧家县',
    cityCode: '5306',
    enName: 'Qiaojia',
  },
  {
    code: '530623',
    name: '盐津县',
    cityCode: '5306',
    enName: 'Yanjin',
  },
  {
    code: '530624',
    name: '大关县',
    cityCode: '5306',
    enName: 'Daguan',
  },
  {
    code: '530625',
    name: '永善县',
    cityCode: '5306',
    enName: 'Yongshan',
  },
  {
    code: '530626',
    name: '绥江县',
    cityCode: '5306',
    enName: 'Suijiang',
  },
  {
    code: '530627',
    name: '镇雄县',
    cityCode: '5306',
    enName: 'Zhenxiong',
  },
  {
    code: '530628',
    name: '彝良县',
    cityCode: '5306',
    enName: 'Yiliang',
  },
  {
    code: '530629',
    name: '威信县',
    cityCode: '5306',
    enName: 'Weixin',
  },
  {
    code: '530630',
    name: '水富县',
    cityCode: '5306',
    enName: 'Shuifu',
  },
  {
    code: '530702',
    name: '古城区',
    cityCode: '5307',
    enName: 'Gucheng',
  },
  {
    code: '530721',
    name: '玉龙纳西族自治县',
    cityCode: '5307',
    enName: 'Yulong Naxi Autonomous County',
  },
  {
    code: '530722',
    name: '永胜县',
    cityCode: '5307',
    enName: 'Yongsheng',
  },
  {
    code: '530723',
    name: '华坪县',
    cityCode: '5307',
    enName: 'Huaping',
  },
  {
    code: '530724',
    name: '宁蒗彝族自治县',
    cityCode: '5307',
    enName: 'Ninglang Yi Autonomous County',
  },
  {
    code: '530802',
    name: '思茅区',
    cityCode: '5308',
    enName: 'Simao',
  },
  {
    code: '530821',
    name: '宁洱哈尼族彝族自治县',
    cityCode: '5308',
    enName: 'Ninger Hani&Yi Autonomous County',
  },
  {
    code: '530822',
    name: '墨江哈尼族自治县',
    cityCode: '5308',
    enName: 'Mojiang Hani Autonomous County',
  },
  {
    code: '530823',
    name: '景东彝族自治县',
    cityCode: '5308',
    enName: 'Jingdong Yi Autonomous County',
  },
  {
    code: '530824',
    name: '景谷傣族彝族自治县',
    cityCode: '5308',
    enName: 'Jinggu Dai&Yi Autonomous County',
  },
  {
    code: '530825',
    name: '镇沅彝族哈尼族拉祜族自治县',
    cityCode: '5308',
    enName: 'Zhenyuan Yi&Hani&Lahu Autonomous County',
  },
  {
    code: '530826',
    name: '江城哈尼族彝族自治县',
    cityCode: '5308',
    enName: 'Jiangcheng Hani&Yi Autonomous County',
  },
  {
    code: '530827',
    name: '孟连傣族拉祜族佤族自治县',
    cityCode: '5308',
    enName: 'Mengliand Dai&Lahu&wa Autonomous County',
  },
  {
    code: '530828',
    name: '澜沧拉祜族自治县',
    cityCode: '5308',
    enName: 'Lancang Lahu Autonomous County',
  },
  {
    code: '530829',
    name: '西盟佤族自治县',
    cityCode: '5308',
    enName: 'Ximeng Wa Autonomous County',
  },
  {
    code: '530902',
    name: '临翔区',
    cityCode: '5309',
    enName: 'Ling',
  },
  {
    code: '530921',
    name: '凤庆县',
    cityCode: '5309',
    enName: 'Fengqing',
  },
  {
    code: '530922',
    name: '云县',
    cityCode: '5309',
    enName: 'Yun',
  },
  {
    code: '530923',
    name: '永德县',
    cityCode: '5309',
    enName: 'Yongde',
  },
  {
    code: '530924',
    name: '镇康县',
    cityCode: '5309',
    enName: 'Zhenkang',
  },
  {
    code: '530925',
    name: '双江拉祜族佤族布朗族傣族自治县',
    cityCode: '5309',
    enName: 'Shuangjiang Lahu&Wa&Bulang&Dai Autonomous County',
  },
  {
    code: '530926',
    name: '耿马傣族佤族自治县',
    cityCode: '5309',
    enName: 'Gengma Dai&Wa Autonomous County',
  },
  {
    code: '530927',
    name: '沧源佤族自治县',
    cityCode: '5309',
    enName: 'Cangyuan Wa Autonomous County',
  },
  {
    code: '532301',
    name: '楚雄市',
    cityCode: '5323',
    enName: 'Chuxiong',
  },
  {
    code: '532322',
    name: '双柏县',
    cityCode: '5323',
    enName: 'Shuangbai',
  },
  {
    code: '532323',
    name: '牟定县',
    cityCode: '5323',
    enName: 'Muding',
  },
  {
    code: '532324',
    name: '南华县',
    cityCode: '5323',
    enName: 'Nanhua',
  },
  {
    code: '532325',
    name: '姚安县',
    cityCode: '5323',
    enName: 'Yaoan',
  },
  {
    code: '532326',
    name: '大姚县',
    cityCode: '5323',
    enName: 'Dayao',
  },
  {
    code: '532327',
    name: '永仁县',
    cityCode: '5323',
    enName: 'Yongren',
  },
  {
    code: '532328',
    name: '元谋县',
    cityCode: '5323',
    enName: 'Yuanmou',
  },
  {
    code: '532329',
    name: '武定县',
    cityCode: '5323',
    enName: 'Wuding',
  },
  {
    code: '532331',
    name: '禄丰县',
    cityCode: '5323',
    enName: 'Lufeng',
  },
  {
    code: '532501',
    name: '个旧市',
    cityCode: '5325',
    enName: 'Gejiu',
  },
  {
    code: '532502',
    name: '开远市',
    cityCode: '5325',
    enName: 'Kaiyuan',
  },
  {
    code: '532503',
    name: '蒙自市',
    cityCode: '5325',
    enName: 'Mengzi',
  },
  {
    code: '532504',
    name: '弥勒市',
    cityCode: '5325',
    enName: 'Mile',
  },
  {
    code: '532523',
    name: '屏边苗族自治县',
    cityCode: '5325',
    enName: 'Pingbian Miao Autonomous County',
  },
  {
    code: '532524',
    name: '建水县',
    cityCode: '5325',
    enName: 'Jianshui',
  },
  {
    code: '532525',
    name: '石屏县',
    cityCode: '5325',
    enName: 'Shiping',
  },
  {
    code: '532527',
    name: '泸西县',
    cityCode: '5325',
    enName: 'Luxi',
  },
  {
    code: '532528',
    name: '元阳县',
    cityCode: '5325',
    enName: 'Yuanyang',
  },
  {
    code: '532529',
    name: '红河县',
    cityCode: '5325',
    enName: 'Honghe',
  },
  {
    code: '532530',
    name: '金平苗族瑶族傣族自治县',
    cityCode: '5325',
    enName: 'Jinping Miao&Yao&Dai Autonomous County',
  },
  {
    code: '532531',
    name: '绿春县',
    cityCode: '5325',
    enName: 'Lüchun',
  },
  {
    code: '532532',
    name: '河口瑶族自治县',
    cityCode: '5325',
    enName: 'Hekou Yao Autonomous County',
  },
  {
    code: '532601',
    name: '文山市',
    cityCode: '5326',
    enName: 'Wenshan',
  },
  {
    code: '532622',
    name: '砚山县',
    cityCode: '5326',
    enName: 'Yanshan',
  },
  {
    code: '532623',
    name: '西畴县',
    cityCode: '5326',
    enName: 'Xichou',
  },
  {
    code: '532624',
    name: '麻栗坡县',
    cityCode: '5326',
    enName: 'Malipo',
  },
  {
    code: '532625',
    name: '马关县',
    cityCode: '5326',
    enName: 'Maguan',
  },
  {
    code: '532626',
    name: '丘北县',
    cityCode: '5326',
    enName: 'Qiubei',
  },
  {
    code: '532627',
    name: '广南县',
    cityCode: '5326',
    enName: 'Guangnan',
  },
  {
    code: '532628',
    name: '富宁县',
    cityCode: '5326',
    enName: 'Funing',
  },
  {
    code: '532801',
    name: '景洪市',
    cityCode: '5328',
    enName: 'Jinghong',
  },
  {
    code: '532822',
    name: '勐海县',
    cityCode: '5328',
    enName: 'Menghai',
  },
  {
    code: '532823',
    name: '勐腊县',
    cityCode: '5328',
    enName: 'Mengla',
  },
  {
    code: '532901',
    name: '大理市',
    cityCode: '5329',
    enName: 'Dali',
  },
  {
    code: '532922',
    name: '漾濞彝族自治县',
    cityCode: '5329',
    enName: 'Yangbi Yi Autonomous County',
  },
  {
    code: '532923',
    name: '祥云县',
    cityCode: '5329',
    enName: 'Xiangyun',
  },
  {
    code: '532924',
    name: '宾川县',
    cityCode: '5329',
    enName: 'Binchuan',
  },
  {
    code: '532925',
    name: '弥渡县',
    cityCode: '5329',
    enName: 'Midu',
  },
  {
    code: '532926',
    name: '南涧彝族自治县',
    cityCode: '5329',
    enName: 'Nanjian Yi Autonomous County',
  },
  {
    code: '532927',
    name: '巍山彝族回族自治县',
    cityCode: '5329',
    enName: 'Weishan Yi&Hui Autonomous County',
  },
  {
    code: '532928',
    name: '永平县',
    cityCode: '5329',
    enName: 'Yongping',
  },
  {
    code: '532929',
    name: '云龙县',
    cityCode: '5329',
    enName: 'Yunlong',
  },
  {
    code: '532930',
    name: '洱源县',
    cityCode: '5329',
    enName: 'Eryuan',
  },
  {
    code: '532931',
    name: '剑川县',
    cityCode: '5329',
    enName: 'Jianchuan',
  },
  {
    code: '532932',
    name: '鹤庆县',
    cityCode: '5329',
    enName: 'Heqing',
  },
  {
    code: '533102',
    name: '瑞丽市',
    cityCode: '5331',
    enName: 'Ruili',
  },
  {
    code: '533103',
    name: '芒市',
    cityCode: '5331',
    enName: 'Mangshi',
  },
  {
    code: '533122',
    name: '梁河县',
    cityCode: '5331',
    enName: 'Lianghe',
  },
  {
    code: '533123',
    name: '盈江县',
    cityCode: '5331',
    enName: 'Yingjiang',
  },
  {
    code: '533124',
    name: '陇川县',
    cityCode: '5331',
    enName: 'Longchuan',
  },
  {
    code: '533301',
    name: '泸水市',
    cityCode: '5333',
    enName: 'Lushui',
  },
  {
    code: '533323',
    name: '福贡县',
    cityCode: '5333',
    enName: 'Fugong',
  },
  {
    code: '533324',
    name: '贡山独龙族怒族自治县',
    cityCode: '5333',
    enName: 'Gongshan Dulong&Nu Autonomous County',
  },
  {
    code: '533325',
    name: '兰坪白族普米族自治县',
    cityCode: '5333',
    enName: 'Lanping Bai&Pumi Autonomous County',
  },
  {
    code: '533401',
    name: '香格里拉市',
    cityCode: '5334',
    enName: 'Xianggelila',
  },
  {
    code: '533422',
    name: '德钦县',
    cityCode: '5334',
    enName: 'Deqin',
  },
  {
    code: '533423',
    name: '维西傈僳族自治县',
    cityCode: '5334',
    enName: 'Weixi Lisu Autonomous County',
  },
  {
    code: '540102',
    name: '城关区',
    cityCode: '5401',
    enName: 'Chengguan',
  },
  {
    code: '540103',
    name: '堆龙德庆区',
    cityCode: '5401',
    enName: 'Duilongdeqing',
  },
  {
    code: '540121',
    name: '林周县',
    cityCode: '5401',
    enName: 'Linzhou',
  },
  {
    code: '540122',
    name: '当雄县',
    cityCode: '5401',
    enName: 'Dangxiong',
  },
  {
    code: '540123',
    name: '尼木县',
    cityCode: '5401',
    enName: 'Nimu',
  },
  {
    code: '540124',
    name: '曲水县',
    cityCode: '5401',
    enName: 'Qushui',
  },
  {
    code: '540126',
    name: '达孜县',
    cityCode: '5401',
    enName: 'Dazi',
  },
  {
    code: '540127',
    name: '墨竹工卡县',
    cityCode: '5401',
    enName: 'Mozhugongka',
  },
  {
    code: '540202',
    name: '桑珠孜区',
    cityCode: '5402',
    enName: 'Sangzhuzi',
  },
  {
    code: '540221',
    name: '南木林县',
    cityCode: '5402',
    enName: 'Nanmulin',
  },
  {
    code: '540222',
    name: '江孜县',
    cityCode: '5402',
    enName: 'Jiangzi',
  },
  {
    code: '540223',
    name: '定日县',
    cityCode: '5402',
    enName: 'Dingri',
  },
  {
    code: '540224',
    name: '萨迦县',
    cityCode: '5402',
    enName: 'Sajia',
  },
  {
    code: '540225',
    name: '拉孜县',
    cityCode: '5402',
    enName: 'Lazi',
  },
  {
    code: '540226',
    name: '昂仁县',
    cityCode: '5402',
    enName: 'Angren',
  },
  {
    code: '540227',
    name: '谢通门县',
    cityCode: '5402',
    enName: 'Xietongmen',
  },
  {
    code: '540228',
    name: '白朗县',
    cityCode: '5402',
    enName: 'Bailang',
  },
  {
    code: '540229',
    name: '仁布县',
    cityCode: '5402',
    enName: 'Renbu',
  },
  {
    code: '540230',
    name: '康马县',
    cityCode: '5402',
    enName: 'Kangma',
  },
  {
    code: '540231',
    name: '定结县',
    cityCode: '5402',
    enName: 'Dingjie',
  },
  {
    code: '540232',
    name: '仲巴县',
    cityCode: '5402',
    enName: 'Zhongba',
  },
  {
    code: '540233',
    name: '亚东县',
    cityCode: '5402',
    enName: 'Yadong',
  },
  {
    code: '540234',
    name: '吉隆县',
    cityCode: '5402',
    enName: 'Jilong',
  },
  {
    code: '540235',
    name: '聂拉木县',
    cityCode: '5402',
    enName: 'Nielamu',
  },
  {
    code: '540236',
    name: '萨嘎县',
    cityCode: '5402',
    enName: 'Saga',
  },
  {
    code: '540237',
    name: '岗巴县',
    cityCode: '5402',
    enName: 'Gangba',
  },
  {
    code: '540302',
    name: '卡若区',
    cityCode: '5403',
    enName: 'Karuo',
  },
  {
    code: '540321',
    name: '江达县',
    cityCode: '5403',
    enName: 'Jiangda',
  },
  {
    code: '540322',
    name: '贡觉县',
    cityCode: '5403',
    enName: 'Gongjue',
  },
  {
    code: '540323',
    name: '类乌齐县',
    cityCode: '5403',
    enName: 'Leiwuqi',
  },
  {
    code: '540324',
    name: '丁青县',
    cityCode: '5403',
    enName: 'Dingqing',
  },
  {
    code: '540325',
    name: '察雅县',
    cityCode: '5403',
    enName: 'Chaya',
  },
  {
    code: '540326',
    name: '八宿县',
    cityCode: '5403',
    enName: 'Basu',
  },
  {
    code: '540327',
    name: '左贡县',
    cityCode: '5403',
    enName: 'Zuogong',
  },
  {
    code: '540328',
    name: '芒康县',
    cityCode: '5403',
    enName: 'Mangkang',
  },
  {
    code: '540329',
    name: '洛隆县',
    cityCode: '5403',
    enName: 'Luolong',
  },
  {
    code: '540330',
    name: '边坝县',
    cityCode: '5403',
    enName: 'Bianba',
  },
  {
    code: '540402',
    name: '巴宜区',
    cityCode: '5404',
    enName: 'Bayi',
  },
  {
    code: '540421',
    name: '工布江达县',
    cityCode: '5404',
    enName: 'Gongbujiangda',
  },
  {
    code: '540422',
    name: '米林县',
    cityCode: '5404',
    enName: 'Milin',
  },
  {
    code: '540423',
    name: '墨脱县',
    cityCode: '5404',
    enName: 'Motuo',
  },
  {
    code: '540424',
    name: '波密县',
    cityCode: '5404',
    enName: 'Bomi',
  },
  {
    code: '540425',
    name: '察隅县',
    cityCode: '5404',
    enName: 'Chayu',
  },
  {
    code: '540426',
    name: '朗县',
    cityCode: '5404',
    enName: 'Lang',
  },
  {
    code: '540501',
    name: '市辖区',
    cityCode: '5405',
    enName: 'Shixiaqu',
  },
  {
    code: '540502',
    name: '乃东区',
    cityCode: '5405',
    enName: 'Naidong',
  },
  {
    code: '540521',
    name: '扎囊县',
    cityCode: '5405',
    enName: 'Zhanang',
  },
  {
    code: '540522',
    name: '贡嘎县',
    cityCode: '5405',
    enName: 'Gongga',
  },
  {
    code: '540523',
    name: '桑日县',
    cityCode: '5405',
    enName: 'Sangri',
  },
  {
    code: '540524',
    name: '琼结县',
    cityCode: '5405',
    enName: 'Qiongjie',
  },
  {
    code: '540525',
    name: '曲松县',
    cityCode: '5405',
    enName: 'Qusong',
  },
  {
    code: '540526',
    name: '措美县',
    cityCode: '5405',
    enName: 'Cuomei',
  },
  {
    code: '540527',
    name: '洛扎县',
    cityCode: '5405',
    enName: 'Luozha',
  },
  {
    code: '540528',
    name: '加查县',
    cityCode: '5405',
    enName: 'Jiacha',
  },
  {
    code: '540529',
    name: '隆子县',
    cityCode: '5405',
    enName: 'Longzi',
  },
  {
    code: '540530',
    name: '错那县',
    cityCode: '5405',
    enName: 'Cuona',
  },
  {
    code: '540531',
    name: '浪卡子县',
    cityCode: '5405',
    enName: 'Langkazi',
  },
  {
    code: '542421',
    name: '那曲县',
    cityCode: '5424',
    enName: 'Na',
  },
  {
    code: '542422',
    name: '嘉黎县',
    cityCode: '5424',
    enName: 'Jiali',
  },
  {
    code: '542423',
    name: '比如县',
    cityCode: '5424',
    enName: 'Biru',
  },
  {
    code: '542424',
    name: '聂荣县',
    cityCode: '5424',
    enName: 'Nierong',
  },
  {
    code: '542425',
    name: '安多县',
    cityCode: '5424',
    enName: 'Anduo',
  },
  {
    code: '542426',
    name: '申扎县',
    cityCode: '5424',
    enName: 'Shenzha',
  },
  {
    code: '542427',
    name: '索县',
    cityCode: '5424',
    enName: 'Suo',
  },
  {
    code: '542428',
    name: '班戈县',
    cityCode: '5424',
    enName: 'Bange',
  },
  {
    code: '542429',
    name: '巴青县',
    cityCode: '5424',
    enName: 'Baqing',
  },
  {
    code: '542430',
    name: '尼玛县',
    cityCode: '5424',
    enName: 'Nima',
  },
  {
    code: '542431',
    name: '双湖县',
    cityCode: '5424',
    enName: 'Shuanghu',
  },
  {
    code: '542521',
    name: '普兰县',
    cityCode: '5425',
    enName: 'Pulan',
  },
  {
    code: '542522',
    name: '札达县',
    cityCode: '5425',
    enName: 'Zhada',
  },
  {
    code: '542523',
    name: '噶尔县',
    cityCode: '5425',
    enName: 'Gaer',
  },
  {
    code: '542524',
    name: '日土县',
    cityCode: '5425',
    enName: 'Ritu',
  },
  {
    code: '542525',
    name: '革吉县',
    cityCode: '5425',
    enName: 'Geji',
  },
  {
    code: '542526',
    name: '改则县',
    cityCode: '5425',
    enName: 'Gaize',
  },
  {
    code: '542527',
    name: '措勤县',
    cityCode: '5425',
    enName: 'Cuoqin',
  },
  {
    code: '610102',
    name: '新城区',
    cityCode: '6101',
    enName: 'Xincheng',
  },
  {
    code: '610103',
    name: '碑林区',
    cityCode: '6101',
    enName: 'Beilin',
  },
  {
    code: '610104',
    name: '莲湖区',
    cityCode: '6101',
    enName: 'Lianhu',
  },
  {
    code: '610111',
    name: '灞桥区',
    cityCode: '6101',
    enName: 'Baqiao',
  },
  {
    code: '610112',
    name: '未央区',
    cityCode: '6101',
    enName: 'Weiyang',
  },
  {
    code: '610113',
    name: '雁塔区',
    cityCode: '6101',
    enName: 'Yanta',
  },
  {
    code: '610114',
    name: '阎良区',
    cityCode: '6101',
    enName: 'Yanliang',
  },
  {
    code: '610115',
    name: '临潼区',
    cityCode: '6101',
    enName: 'Lintong',
  },
  {
    code: '610116',
    name: '长安区',
    cityCode: '6101',
    enName: 'Changan',
  },
  {
    code: '610117',
    name: '高陵区',
    cityCode: '6101',
    enName: 'Gaoling',
  },
  {
    code: '610122',
    name: '蓝田县',
    cityCode: '6101',
    enName: 'Lantian',
  },
  {
    code: '610124',
    name: '周至县',
    cityCode: '6101',
    enName: 'Zhouzhi',
  },
  {
    code: '610125',
    name: '户县',
    cityCode: '6101',
    enName: 'Hu',
  },
  {
    code: '610202',
    name: '王益区',
    cityCode: '6102',
    enName: 'Wangyi',
  },
  {
    code: '610203',
    name: '印台区',
    cityCode: '6102',
    enName: 'Yintai',
  },
  {
    code: '610204',
    name: '耀州区',
    cityCode: '6102',
    enName: 'Yaozhou',
  },
  {
    code: '610222',
    name: '宜君县',
    cityCode: '6102',
    enName: 'Yijun',
  },
  {
    code: '610302',
    name: '渭滨区',
    cityCode: '6103',
    enName: 'Weibin',
  },
  {
    code: '610303',
    name: '金台区',
    cityCode: '6103',
    enName: 'Jintai',
  },
  {
    code: '610304',
    name: '陈仓区',
    cityCode: '6103',
    enName: 'Chencang',
  },
  {
    code: '610322',
    name: '凤翔县',
    cityCode: '6103',
    enName: 'Fenggxian',
  },
  {
    code: '610323',
    name: '岐山县',
    cityCode: '6103',
    enName: 'Qishan',
  },
  {
    code: '610324',
    name: '扶风县',
    cityCode: '6103',
    enName: 'Fufeng',
  },
  {
    code: '610326',
    name: '眉县',
    cityCode: '6103',
    enName: 'Mei',
  },
  {
    code: '610327',
    name: '陇县',
    cityCode: '6103',
    enName: 'Long',
  },
  {
    code: '610328',
    name: '千阳县',
    cityCode: '6103',
    enName: 'Qianyang',
  },
  {
    code: '610329',
    name: '麟游县',
    cityCode: '6103',
    enName: 'Linyou',
  },
  {
    code: '610330',
    name: '凤县',
    cityCode: '6103',
    enName: 'Feng',
  },
  {
    code: '610331',
    name: '太白县',
    cityCode: '6103',
    enName: 'Taibai',
  },
  {
    code: '610402',
    name: '秦都区',
    cityCode: '6104',
    enName: 'Qindou',
  },
  {
    code: '610403',
    name: '杨陵区',
    cityCode: '6104',
    enName: 'Yangling',
  },
  {
    code: '610404',
    name: '渭城区',
    cityCode: '6104',
    enName: 'Weicheng',
  },
  {
    code: '610422',
    name: '三原县',
    cityCode: '6104',
    enName: 'Sanyuan',
  },
  {
    code: '610423',
    name: '泾阳县',
    cityCode: '6104',
    enName: 'Jingyang',
  },
  {
    code: '610424',
    name: '乾县',
    cityCode: '6104',
    enName: 'Qian',
  },
  {
    code: '610425',
    name: '礼泉县',
    cityCode: '6104',
    enName: 'Lian',
  },
  {
    code: '610426',
    name: '永寿县',
    cityCode: '6104',
    enName: 'Yongshou',
  },
  {
    code: '610427',
    name: '彬县',
    cityCode: '6104',
    enName: 'Bin',
  },
  {
    code: '610428',
    name: '长武县',
    cityCode: '6104',
    enName: 'Changwu',
  },
  {
    code: '610429',
    name: '旬邑县',
    cityCode: '6104',
    enName: 'Xunyi',
  },
  {
    code: '610430',
    name: '淳化县',
    cityCode: '6104',
    enName: 'Chunhua',
  },
  {
    code: '610431',
    name: '武功县',
    cityCode: '6104',
    enName: 'Wugong',
  },
  {
    code: '610481',
    name: '兴平市',
    cityCode: '6104',
    enName: 'Xingping',
  },
  {
    code: '610502',
    name: '临渭区',
    cityCode: '6105',
    enName: 'Linwei',
  },
  {
    code: '610503',
    name: '华州区',
    cityCode: '6105',
    enName: 'Huazhou',
  },
  {
    code: '610522',
    name: '潼关县',
    cityCode: '6105',
    enName: 'Tongguan',
  },
  {
    code: '610523',
    name: '大荔县',
    cityCode: '6105',
    enName: 'Dali',
  },
  {
    code: '610524',
    name: '合阳县',
    cityCode: '6105',
    enName: 'Heyang',
  },
  {
    code: '610525',
    name: '澄城县',
    cityCode: '6105',
    enName: 'Chengcheng',
  },
  {
    code: '610526',
    name: '蒲城县',
    cityCode: '6105',
    enName: 'Pucheng',
  },
  {
    code: '610527',
    name: '白水县',
    cityCode: '6105',
    enName: 'Baishui',
  },
  {
    code: '610528',
    name: '富平县',
    cityCode: '6105',
    enName: 'Fuping',
  },
  {
    code: '610581',
    name: '韩城市',
    cityCode: '6105',
    enName: 'Hancheng',
  },
  {
    code: '610582',
    name: '华阴市',
    cityCode: '6105',
    enName: 'Huayin',
  },
  {
    code: '610602',
    name: '宝塔区',
    cityCode: '6106',
    enName: 'Baota',
  },
  {
    code: '610603',
    name: '安塞区',
    cityCode: '6106',
    enName: 'Ansai',
  },
  {
    code: '610621',
    name: '延长县',
    cityCode: '6106',
    enName: 'Yanchang',
  },
  {
    code: '610622',
    name: '延川县',
    cityCode: '6106',
    enName: 'Yanchuan',
  },
  {
    code: '610623',
    name: '子长县',
    cityCode: '6106',
    enName: 'Zichang',
  },
  {
    code: '610625',
    name: '志丹县',
    cityCode: '6106',
    enName: 'Zhidan',
  },
  {
    code: '610626',
    name: '吴起县',
    cityCode: '6106',
    enName: 'Wuqi',
  },
  {
    code: '610627',
    name: '甘泉县',
    cityCode: '6106',
    enName: 'Ganan',
  },
  {
    code: '610628',
    name: '富县',
    cityCode: '6106',
    enName: 'Fu',
  },
  {
    code: '610629',
    name: '洛川县',
    cityCode: '6106',
    enName: 'Luochuan',
  },
  {
    code: '610630',
    name: '宜川县',
    cityCode: '6106',
    enName: 'Yichuan',
  },
  {
    code: '610631',
    name: '黄龙县',
    cityCode: '6106',
    enName: 'Huanglong',
  },
  {
    code: '610632',
    name: '黄陵县',
    cityCode: '6106',
    enName: 'Huangling',
  },
  {
    code: '610702',
    name: '汉台区',
    cityCode: '6107',
    enName: 'Hantai',
  },
  {
    code: '610721',
    name: '南郑县',
    cityCode: '6107',
    enName: 'Nanzheng',
  },
  {
    code: '610722',
    name: '城固县',
    cityCode: '6107',
    enName: 'Chenggu',
  },
  {
    code: '610723',
    name: '洋县',
    cityCode: '6107',
    enName: 'Yang',
  },
  {
    code: '610724',
    name: '西乡县',
    cityCode: '6107',
    enName: 'Xigxian',
  },
  {
    code: '610725',
    name: '勉县',
    cityCode: '6107',
    enName: 'Mian',
  },
  {
    code: '610726',
    name: '宁强县',
    cityCode: '6107',
    enName: 'Ningqiang',
  },
  {
    code: '610727',
    name: '略阳县',
    cityCode: '6107',
    enName: 'Lüeyang',
  },
  {
    code: '610728',
    name: '镇巴县',
    cityCode: '6107',
    enName: 'Zhenba',
  },
  {
    code: '610729',
    name: '留坝县',
    cityCode: '6107',
    enName: 'Liuba',
  },
  {
    code: '610730',
    name: '佛坪县',
    cityCode: '6107',
    enName: 'Foping',
  },
  {
    code: '610802',
    name: '榆阳区',
    cityCode: '6108',
    enName: 'Yuyang',
  },
  {
    code: '610803',
    name: '横山区',
    cityCode: '6108',
    enName: 'Hengshan',
  },
  {
    code: '610821',
    name: '神木县',
    cityCode: '6108',
    enName: 'Shenmu',
  },
  {
    code: '610822',
    name: '府谷县',
    cityCode: '6108',
    enName: 'Fugu',
  },
  {
    code: '610824',
    name: '靖边县',
    cityCode: '6108',
    enName: 'Jingbian',
  },
  {
    code: '610825',
    name: '定边县',
    cityCode: '6108',
    enName: 'Dingbian',
  },
  {
    code: '610826',
    name: '绥德县',
    cityCode: '6108',
    enName: 'Suide',
  },
  {
    code: '610827',
    name: '米脂县',
    cityCode: '6108',
    enName: 'Mizhi',
  },
  {
    code: '610828',
    name: '佳县',
    cityCode: '6108',
    enName: 'Jia',
  },
  {
    code: '610829',
    name: '吴堡县',
    cityCode: '6108',
    enName: 'Wubao',
  },
  {
    code: '610830',
    name: '清涧县',
    cityCode: '6108',
    enName: 'Qingjian',
  },
  {
    code: '610831',
    name: '子洲县',
    cityCode: '6108',
    enName: 'Zizhou',
  },
  {
    code: '610902',
    name: '汉滨区',
    cityCode: '6109',
    enName: 'Hanbin',
  },
  {
    code: '610921',
    name: '汉阴县',
    cityCode: '6109',
    enName: 'Hanyin',
  },
  {
    code: '610922',
    name: '石泉县',
    cityCode: '6109',
    enName: 'Shian',
  },
  {
    code: '610923',
    name: '宁陕县',
    cityCode: '6109',
    enName: 'Ningshan',
  },
  {
    code: '610924',
    name: '紫阳县',
    cityCode: '6109',
    enName: 'Ziyang',
  },
  {
    code: '610925',
    name: '岚皋县',
    cityCode: '6109',
    enName: 'Langao',
  },
  {
    code: '610926',
    name: '平利县',
    cityCode: '6109',
    enName: 'Pingli',
  },
  {
    code: '610927',
    name: '镇坪县',
    cityCode: '6109',
    enName: 'Zhenping',
  },
  {
    code: '610928',
    name: '旬阳县',
    cityCode: '6109',
    enName: 'Xunyang',
  },
  {
    code: '610929',
    name: '白河县',
    cityCode: '6109',
    enName: 'Baihe',
  },
  {
    code: '611002',
    name: '商州区',
    cityCode: '6110',
    enName: 'Shangzhou',
  },
  {
    code: '611021',
    name: '洛南县',
    cityCode: '6110',
    enName: 'Luonan',
  },
  {
    code: '611022',
    name: '丹凤县',
    cityCode: '6110',
    enName: 'Danfeng',
  },
  {
    code: '611023',
    name: '商南县',
    cityCode: '6110',
    enName: 'Shangnan',
  },
  {
    code: '611024',
    name: '山阳县',
    cityCode: '6110',
    enName: 'Shanyang',
  },
  {
    code: '611025',
    name: '镇安县',
    cityCode: '6110',
    enName: 'Zhenan',
  },
  {
    code: '611026',
    name: '柞水县',
    cityCode: '6110',
    enName: 'Zhashui',
  },
  {
    code: '620102',
    name: '城关区',
    cityCode: '6201',
    enName: 'Chengguan',
  },
  {
    code: '620103',
    name: '七里河区',
    cityCode: '6201',
    enName: 'Qilihe',
  },
  {
    code: '620104',
    name: '西固区',
    cityCode: '6201',
    enName: 'Xigu',
  },
  {
    code: '620105',
    name: '安宁区',
    cityCode: '6201',
    enName: 'Anning',
  },
  {
    code: '620111',
    name: '红古区',
    cityCode: '6201',
    enName: 'Honggu',
  },
  {
    code: '620121',
    name: '永登县',
    cityCode: '6201',
    enName: 'Yongdeng',
  },
  {
    code: '620122',
    name: '皋兰县',
    cityCode: '6201',
    enName: 'Gaolan',
  },
  {
    code: '620123',
    name: '榆中县',
    cityCode: '6201',
    enName: 'Yuzhong',
  },
  {
    code: '620201',
    name: '嘉峪关市',
    cityCode: '6202',
    enName: 'Jiayuguan',
  },
  {
    code: '620302',
    name: '金川区',
    cityCode: '6203',
    enName: 'Jinchuan',
  },
  {
    code: '620321',
    name: '永昌县',
    cityCode: '6203',
    enName: 'Yongchang',
  },
  {
    code: '620402',
    name: '白银区',
    cityCode: '6204',
    enName: 'Baiyin',
  },
  {
    code: '620403',
    name: '平川区',
    cityCode: '6204',
    enName: 'Pingchuan',
  },
  {
    code: '620421',
    name: '靖远县',
    cityCode: '6204',
    enName: 'Jingyuan',
  },
  {
    code: '620422',
    name: '会宁县',
    cityCode: '6204',
    enName: 'Huining',
  },
  {
    code: '620423',
    name: '景泰县',
    cityCode: '6204',
    enName: 'Jingtai',
  },
  {
    code: '620502',
    name: '秦州区',
    cityCode: '6205',
    enName: 'Qinzhou',
  },
  {
    code: '620503',
    name: '麦积区',
    cityCode: '6205',
    enName: 'Maiji',
  },
  {
    code: '620521',
    name: '清水县',
    cityCode: '6205',
    enName: 'Qingshui',
  },
  {
    code: '620522',
    name: '秦安县',
    cityCode: '6205',
    enName: 'Qinan',
  },
  {
    code: '620523',
    name: '甘谷县',
    cityCode: '6205',
    enName: 'Gangu',
  },
  {
    code: '620524',
    name: '武山县',
    cityCode: '6205',
    enName: 'Wushan',
  },
  {
    code: '620525',
    name: '张家川回族自治县',
    cityCode: '6205',
    enName: 'Zhangjiachuan Hui Autonomous County',
  },
  {
    code: '620602',
    name: '凉州区',
    cityCode: '6206',
    enName: 'Liangzhou',
  },
  {
    code: '620621',
    name: '民勤县',
    cityCode: '6206',
    enName: 'Minqin',
  },
  {
    code: '620622',
    name: '古浪县',
    cityCode: '6206',
    enName: 'Gulang',
  },
  {
    code: '620623',
    name: '天祝藏族自治县',
    cityCode: '6206',
    enName: 'Tianzhu Zang Autonomous County',
  },
  {
    code: '620702',
    name: '甘州区',
    cityCode: '6207',
    enName: 'Ganzhou',
  },
  {
    code: '620721',
    name: '肃南裕固族自治县',
    cityCode: '6207',
    enName: 'Sunan Yugu Autonomous County',
  },
  {
    code: '620722',
    name: '民乐县',
    cityCode: '6207',
    enName: 'Minyue',
  },
  {
    code: '620723',
    name: '临泽县',
    cityCode: '6207',
    enName: 'Linze',
  },
  {
    code: '620724',
    name: '高台县',
    cityCode: '6207',
    enName: 'Gaotai',
  },
  {
    code: '620725',
    name: '山丹县',
    cityCode: '6207',
    enName: 'Shandan',
  },
  {
    code: '620802',
    name: '崆峒区',
    cityCode: '6208',
    enName: 'Kongtong',
  },
  {
    code: '620821',
    name: '泾川县',
    cityCode: '6208',
    enName: 'Jingchuan',
  },
  {
    code: '620822',
    name: '灵台县',
    cityCode: '6208',
    enName: 'Lingtai',
  },
  {
    code: '620823',
    name: '崇信县',
    cityCode: '6208',
    enName: 'Chongxin',
  },
  {
    code: '620824',
    name: '华亭县',
    cityCode: '6208',
    enName: 'Huating',
  },
  {
    code: '620825',
    name: '庄浪县',
    cityCode: '6208',
    enName: 'Zhuanglang',
  },
  {
    code: '620826',
    name: '静宁县',
    cityCode: '6208',
    enName: 'Jingning',
  },
  {
    code: '620902',
    name: '肃州区',
    cityCode: '6209',
    enName: 'Suzhou',
  },
  {
    code: '620921',
    name: '金塔县',
    cityCode: '6209',
    enName: 'Jinta',
  },
  {
    code: '620922',
    name: '瓜州县',
    cityCode: '6209',
    enName: 'Guazhou',
  },
  {
    code: '620923',
    name: '肃北蒙古族自治县',
    cityCode: '6209',
    enName: 'Subei Mongolian Autonomous County',
  },
  {
    code: '620924',
    name: '阿克塞哈萨克族自治县',
    cityCode: '6209',
    enName: 'Akesai Hasake Autonomous County',
  },
  {
    code: '620981',
    name: '玉门市',
    cityCode: '6209',
    enName: 'Yumen',
  },
  {
    code: '620982',
    name: '敦煌市',
    cityCode: '6209',
    enName: 'Dunhuang',
  },
  {
    code: '621002',
    name: '西峰区',
    cityCode: '6210',
    enName: 'Xifeng',
  },
  {
    code: '621021',
    name: '庆城县',
    cityCode: '6210',
    enName: 'Qingcheng',
  },
  {
    code: '621022',
    name: '环县',
    cityCode: '6210',
    enName: 'Huan',
  },
  {
    code: '621023',
    name: '华池县',
    cityCode: '6210',
    enName: 'Huachi',
  },
  {
    code: '621024',
    name: '合水县',
    cityCode: '6210',
    enName: 'Heshui',
  },
  {
    code: '621025',
    name: '正宁县',
    cityCode: '6210',
    enName: 'Zhengning',
  },
  {
    code: '621026',
    name: '宁县',
    cityCode: '6210',
    enName: 'Ning',
  },
  {
    code: '621027',
    name: '镇原县',
    cityCode: '6210',
    enName: 'Zhenyuan',
  },
  {
    code: '621102',
    name: '安定区',
    cityCode: '6211',
    enName: 'Anding',
  },
  {
    code: '621121',
    name: '通渭县',
    cityCode: '6211',
    enName: 'Tongwei',
  },
  {
    code: '621122',
    name: '陇西县',
    cityCode: '6211',
    enName: 'Longxi',
  },
  {
    code: '621123',
    name: '渭源县',
    cityCode: '6211',
    enName: 'Weiyuan',
  },
  {
    code: '621124',
    name: '临洮县',
    cityCode: '6211',
    enName: 'Lintao',
  },
  {
    code: '621125',
    name: '漳县',
    cityCode: '6211',
    enName: 'Zhang',
  },
  {
    code: '621126',
    name: '岷县',
    cityCode: '6211',
    enName: 'Min',
  },
  {
    code: '621202',
    name: '武都区',
    cityCode: '6212',
    enName: 'Wudou',
  },
  {
    code: '621221',
    name: '成县',
    cityCode: '6212',
    enName: 'Cheng',
  },
  {
    code: '621222',
    name: '文县',
    cityCode: '6212',
    enName: 'Wen',
  },
  {
    code: '621223',
    name: '宕昌县',
    cityCode: '6212',
    enName: 'Dangchang',
  },
  {
    code: '621224',
    name: '康县',
    cityCode: '6212',
    enName: 'Kang',
  },
  {
    code: '621225',
    name: '西和县',
    cityCode: '6212',
    enName: 'Xihe',
  },
  {
    code: '621226',
    name: '礼县',
    cityCode: '6212',
    enName: 'Li',
  },
  {
    code: '621227',
    name: '徽县',
    cityCode: '6212',
    enName: 'Hui',
  },
  {
    code: '621228',
    name: '两当县',
    cityCode: '6212',
    enName: 'Liangdang',
  },
  {
    code: '622901',
    name: '临夏市',
    cityCode: '6229',
    enName: 'Linxia',
  },
  {
    code: '622921',
    name: '临夏县',
    cityCode: '6229',
    enName: 'Linxia',
  },
  {
    code: '622922',
    name: '康乐县',
    cityCode: '6229',
    enName: 'Kangle',
  },
  {
    code: '622923',
    name: '永靖县',
    cityCode: '6229',
    enName: 'Yongjing',
  },
  {
    code: '622924',
    name: '广河县',
    cityCode: '6229',
    enName: 'Guanghe',
  },
  {
    code: '622925',
    name: '和政县',
    cityCode: '6229',
    enName: 'Hezheng',
  },
  {
    code: '622926',
    name: '东乡族自治县',
    cityCode: '6229',
    enName: 'Dongxiang Autonomous County',
  },
  {
    code: '622927',
    name: '积石山保安族东乡族撒拉族自治县',
    cityCode: '6229',
    enName: 'Jishishan Baoan&Dongg&Sala Autonomous County',
  },
  {
    code: '623001',
    name: '合作市',
    cityCode: '6230',
    enName: 'Hezuo',
  },
  {
    code: '623021',
    name: '临潭县',
    cityCode: '6230',
    enName: 'Lintan',
  },
  {
    code: '623022',
    name: '卓尼县',
    cityCode: '6230',
    enName: 'Zhuoni',
  },
  {
    code: '623023',
    name: '舟曲县',
    cityCode: '6230',
    enName: 'Zhou',
  },
  {
    code: '623024',
    name: '迭部县',
    cityCode: '6230',
    enName: 'Diebu',
  },
  {
    code: '623025',
    name: '玛曲县',
    cityCode: '6230',
    enName: 'Ma',
  },
  {
    code: '623026',
    name: '碌曲县',
    cityCode: '6230',
    enName: 'Lu',
  },
  {
    code: '623027',
    name: '夏河县',
    cityCode: '6230',
    enName: 'Xiahe',
  },
  {
    code: '630102',
    name: '城东区',
    cityCode: '6301',
    enName: 'Chengdong',
  },
  {
    code: '630103',
    name: '城中区',
    cityCode: '6301',
    enName: 'Chengzhong',
  },
  {
    code: '630104',
    name: '城西区',
    cityCode: '6301',
    enName: 'Chengxi',
  },
  {
    code: '630105',
    name: '城北区',
    cityCode: '6301',
    enName: 'Chengbei',
  },
  {
    code: '630121',
    name: '大通回族土族自治县',
    cityCode: '6301',
    enName: 'Datong Hui Autonomous County',
  },
  {
    code: '630122',
    name: '湟中县',
    cityCode: '6301',
    enName: 'Huangzhong',
  },
  {
    code: '630123',
    name: '湟源县',
    cityCode: '6301',
    enName: 'Huangyuan',
  },
  {
    code: '630202',
    name: '乐都区',
    cityCode: '6302',
    enName: 'Ledou',
  },
  {
    code: '630203',
    name: '平安区',
    cityCode: '6302',
    enName: 'Pingan',
  },
  {
    code: '630222',
    name: '民和回族土族自治县',
    cityCode: '6302',
    enName: 'Minhe Hui&tu Autonomous County',
  },
  {
    code: '630223',
    name: '互助土族自治县',
    cityCode: '6302',
    enName: 'Huzhu Tu Autonomous County',
  },
  {
    code: '630224',
    name: '化隆回族自治县',
    cityCode: '6302',
    enName: 'Hualong Hui Autonomous County',
  },
  {
    code: '630225',
    name: '循化撒拉族自治县',
    cityCode: '6302',
    enName: 'Xunhua Sala Autonomous County',
  },
  {
    code: '632221',
    name: '门源回族自治县',
    cityCode: '6322',
    enName: 'Menyuan Hui Autonomous County',
  },
  {
    code: '632222',
    name: '祁连县',
    cityCode: '6322',
    enName: 'Qilian',
  },
  {
    code: '632223',
    name: '海晏县',
    cityCode: '6322',
    enName: 'Haiyan',
  },
  {
    code: '632224',
    name: '刚察县',
    cityCode: '6322',
    enName: 'Gangcha',
  },
  {
    code: '632321',
    name: '同仁县',
    cityCode: '6323',
    enName: 'Tongren',
  },
  {
    code: '632322',
    name: '尖扎县',
    cityCode: '6323',
    enName: 'Jianzha',
  },
  {
    code: '632323',
    name: '泽库县',
    cityCode: '6323',
    enName: 'Zeku',
  },
  {
    code: '632324',
    name: '河南蒙古族自治县',
    cityCode: '6323',
    enName: 'Henan Mongolian Autonomous County',
  },
  {
    code: '632521',
    name: '共和县',
    cityCode: '6325',
    enName: 'Gonghe',
  },
  {
    code: '632522',
    name: '同德县',
    cityCode: '6325',
    enName: 'Tongde',
  },
  {
    code: '632523',
    name: '贵德县',
    cityCode: '6325',
    enName: 'Guide',
  },
  {
    code: '632524',
    name: '兴海县',
    cityCode: '6325',
    enName: 'Xinghai',
  },
  {
    code: '632525',
    name: '贵南县',
    cityCode: '6325',
    enName: 'Guinan',
  },
  {
    code: '632621',
    name: '玛沁县',
    cityCode: '6326',
    enName: 'Maqin',
  },
  {
    code: '632622',
    name: '班玛县',
    cityCode: '6326',
    enName: 'Banma',
  },
  {
    code: '632623',
    name: '甘德县',
    cityCode: '6326',
    enName: 'Gande',
  },
  {
    code: '632624',
    name: '达日县',
    cityCode: '6326',
    enName: 'Dari',
  },
  {
    code: '632625',
    name: '久治县',
    cityCode: '6326',
    enName: 'Jiuzhi',
  },
  {
    code: '632626',
    name: '玛多县',
    cityCode: '6326',
    enName: 'Maduo',
  },
  {
    code: '632701',
    name: '玉树市',
    cityCode: '6327',
    enName: 'Yushu',
  },
  {
    code: '632722',
    name: '杂多县',
    cityCode: '6327',
    enName: 'Zaduo',
  },
  {
    code: '632723',
    name: '称多县',
    cityCode: '6327',
    enName: 'Chengduo',
  },
  {
    code: '632724',
    name: '治多县',
    cityCode: '6327',
    enName: 'Zhiduo',
  },
  {
    code: '632725',
    name: '囊谦县',
    cityCode: '6327',
    enName: 'Nangqian',
  },
  {
    code: '632726',
    name: '曲麻莱县',
    cityCode: '6327',
    enName: 'Qumalai',
  },
  {
    code: '632801',
    name: '格尔木市',
    cityCode: '6328',
    enName: 'Geermu',
  },
  {
    code: '632802',
    name: '德令哈市',
    cityCode: '6328',
    enName: 'Delingha',
  },
  {
    code: '632821',
    name: '乌兰县',
    cityCode: '6328',
    enName: 'Wulan',
  },
  {
    code: '632822',
    name: '都兰县',
    cityCode: '6328',
    enName: 'Doulan',
  },
  {
    code: '632823',
    name: '天峻县',
    cityCode: '6328',
    enName: 'Tianjun',
  },
  {
    code: '640104',
    name: '兴庆区',
    cityCode: '6401',
    enName: 'Xingqing',
  },
  {
    code: '640105',
    name: '西夏区',
    cityCode: '6401',
    enName: 'Xixia',
  },
  {
    code: '640106',
    name: '金凤区',
    cityCode: '6401',
    enName: 'Jinfeng',
  },
  {
    code: '640121',
    name: '永宁县',
    cityCode: '6401',
    enName: 'Yongning',
  },
  {
    code: '640122',
    name: '贺兰县',
    cityCode: '6401',
    enName: 'Helan',
  },
  {
    code: '640181',
    name: '灵武市',
    cityCode: '6401',
    enName: 'Lingwu',
  },
  {
    code: '640202',
    name: '大武口区',
    cityCode: '6402',
    enName: 'Dawukou',
  },
  {
    code: '640205',
    name: '惠农区',
    cityCode: '6402',
    enName: 'Huinong',
  },
  {
    code: '640221',
    name: '平罗县',
    cityCode: '6402',
    enName: 'Pingluo',
  },
  {
    code: '640302',
    name: '利通区',
    cityCode: '6403',
    enName: 'Litong',
  },
  {
    code: '640303',
    name: '红寺堡区',
    cityCode: '6403',
    enName: 'Hongsibao',
  },
  {
    code: '640323',
    name: '盐池县',
    cityCode: '6403',
    enName: 'Yanchi',
  },
  {
    code: '640324',
    name: '同心县',
    cityCode: '6403',
    enName: 'Tongxin',
  },
  {
    code: '640381',
    name: '青铜峡市',
    cityCode: '6403',
    enName: 'Qingtongxia',
  },
  {
    code: '640402',
    name: '原州区',
    cityCode: '6404',
    enName: 'Yuanzhou',
  },
  {
    code: '640422',
    name: '西吉县',
    cityCode: '6404',
    enName: 'Xiji',
  },
  {
    code: '640423',
    name: '隆德县',
    cityCode: '6404',
    enName: 'Longde',
  },
  {
    code: '640424',
    name: '泾源县',
    cityCode: '6404',
    enName: 'Jingyuan',
  },
  {
    code: '640425',
    name: '彭阳县',
    cityCode: '6404',
    enName: 'Pengyang',
  },
  {
    code: '640502',
    name: '沙坡头区',
    cityCode: '6405',
    enName: 'Shapotou',
  },
  {
    code: '640521',
    name: '中宁县',
    cityCode: '6405',
    enName: 'Zhongning',
  },
  {
    code: '640522',
    name: '海原县',
    cityCode: '6405',
    enName: 'Haiyuan',
  },
  {
    code: '650102',
    name: '天山区',
    cityCode: '6501',
    enName: 'Tianshan',
  },
  {
    code: '650103',
    name: '沙依巴克区',
    cityCode: '6501',
    enName: 'Shayibake',
  },
  {
    code: '650104',
    name: '新市区',
    cityCode: '6501',
    enName: 'Xinshi',
  },
  {
    code: '650105',
    name: '水磨沟区',
    cityCode: '6501',
    enName: 'Shuimogou',
  },
  {
    code: '650106',
    name: '头屯河区',
    cityCode: '6501',
    enName: 'Toutunhe',
  },
  {
    code: '650107',
    name: '达坂城区',
    cityCode: '6501',
    enName: 'Dabancheng',
  },
  {
    code: '650109',
    name: '米东区',
    cityCode: '6501',
    enName: 'Midong',
  },
  {
    code: '650121',
    name: '乌鲁木齐县',
    cityCode: '6501',
    enName: 'Wulumuqi',
  },
  {
    code: '650202',
    name: '独山子区',
    cityCode: '6502',
    enName: 'Dushanzi',
  },
  {
    code: '650203',
    name: '克拉玛依区',
    cityCode: '6502',
    enName: 'Kelamayi',
  },
  {
    code: '650204',
    name: '白碱滩区',
    cityCode: '6502',
    enName: 'Baijiantan',
  },
  {
    code: '650205',
    name: '乌尔禾区',
    cityCode: '6502',
    enName: 'Wuerhe',
  },
  {
    code: '650402',
    name: '高昌区',
    cityCode: '6504',
    enName: 'Gaochang',
  },
  {
    code: '650421',
    name: '鄯善县',
    cityCode: '6504',
    enName: 'Shanshan',
  },
  {
    code: '650422',
    name: '托克逊县',
    cityCode: '6504',
    enName: 'Tuokexun',
  },
  {
    code: '650502',
    name: '伊州区',
    cityCode: '6505',
    enName: 'Yizhou',
  },
  {
    code: '650521',
    name: '巴里坤哈萨克自治县',
    cityCode: '6505',
    enName: 'Barikun Kazakh Autonomous County',
  },
  {
    code: '650522',
    name: '伊吾县',
    cityCode: '6505',
    enName: 'Yiwu',
  },
  {
    code: '652301',
    name: '昌吉市',
    cityCode: '6523',
    enName: 'Changjishi',
  },
  {
    code: '652302',
    name: '阜康市',
    cityCode: '6523',
    enName: 'Fukangshi',
  },
  {
    code: '652323',
    name: '呼图壁县',
    cityCode: '6523',
    enName: 'Hutubi',
  },
  {
    code: '652324',
    name: '玛纳斯县',
    cityCode: '6523',
    enName: 'Manasi',
  },
  {
    code: '652325',
    name: '奇台县',
    cityCode: '6523',
    enName: 'Qitai',
  },
  {
    code: '652327',
    name: '吉木萨尔县',
    cityCode: '6523',
    enName: 'Jimusaer',
  },
  {
    code: '652328',
    name: '木垒哈萨克自治县',
    cityCode: '6523',
    enName: 'Mulei Kazakh Autonomous County',
  },
  {
    code: '652701',
    name: '博乐市',
    cityCode: '6527',
    enName: 'Bole',
  },
  {
    code: '652702',
    name: '阿拉山口市',
    cityCode: '6527',
    enName: 'Alashankou',
  },
  {
    code: '652722',
    name: '精河县',
    cityCode: '6527',
    enName: 'Jinghe',
  },
  {
    code: '652723',
    name: '温泉县',
    cityCode: '6527',
    enName: 'Wenan',
  },
  {
    code: '652801',
    name: '库尔勒市',
    cityCode: '6528',
    enName: 'Kuerle',
  },
  {
    code: '652822',
    name: '轮台县',
    cityCode: '6528',
    enName: 'Luntai',
  },
  {
    code: '652823',
    name: '尉犁县',
    cityCode: '6528',
    enName: 'Weili',
  },
  {
    code: '652824',
    name: '若羌县',
    cityCode: '6528',
    enName: 'Ruoqiang',
  },
  {
    code: '652825',
    name: '且末县',
    cityCode: '6528',
    enName: 'Qiemo',
  },
  {
    code: '652826',
    name: '焉耆回族自治县',
    cityCode: '6528',
    enName: 'Yanqi Hui Autonomous County',
  },
  {
    code: '652827',
    name: '和静县',
    cityCode: '6528',
    enName: 'Hejing',
  },
  {
    code: '652828',
    name: '和硕县',
    cityCode: '6528',
    enName: 'Heshuo',
  },
  {
    code: '652829',
    name: '博湖县',
    cityCode: '6528',
    enName: 'Bohu',
  },
  {
    code: '652901',
    name: '阿克苏市',
    cityCode: '6529',
    enName: 'Akesu',
  },
  {
    code: '652922',
    name: '温宿县',
    cityCode: '6529',
    enName: 'Wensu',
  },
  {
    code: '652923',
    name: '库车县',
    cityCode: '6529',
    enName: 'Kuche',
  },
  {
    code: '652924',
    name: '沙雅县',
    cityCode: '6529',
    enName: 'Shaya',
  },
  {
    code: '652925',
    name: '新和县',
    cityCode: '6529',
    enName: 'Xinhe',
  },
  {
    code: '652926',
    name: '拜城县',
    cityCode: '6529',
    enName: 'Baicheng',
  },
  {
    code: '652927',
    name: '乌什县',
    cityCode: '6529',
    enName: 'Wushi',
  },
  {
    code: '652928',
    name: '阿瓦提县',
    cityCode: '6529',
    enName: 'Awati',
  },
  {
    code: '652929',
    name: '柯坪县',
    cityCode: '6529',
    enName: 'Keping',
  },
  {
    code: '653001',
    name: '阿图什市',
    cityCode: '6530',
    enName: 'Artux',
  },
  {
    code: '653022',
    name: '阿克陶县',
    cityCode: '6530',
    enName: 'Aketao',
  },
  {
    code: '653023',
    name: '阿合奇县',
    cityCode: '6530',
    enName: 'Aheqi',
  },
  {
    code: '653024',
    name: '乌恰县',
    cityCode: '6530',
    enName: 'Wuqia',
  },
  {
    code: '653101',
    name: '喀什市',
    cityCode: '6531',
    enName: 'Kashi',
  },
  {
    code: '653121',
    name: '疏附县',
    cityCode: '6531',
    enName: 'Shufu',
  },
  {
    code: '653122',
    name: '疏勒县',
    cityCode: '6531',
    enName: 'Shule',
  },
  {
    code: '653123',
    name: '英吉沙县',
    cityCode: '6531',
    enName: 'Yingjisha',
  },
  {
    code: '653124',
    name: '泽普县',
    cityCode: '6531',
    enName: 'Zepu',
  },
  {
    code: '653125',
    name: '莎车县',
    cityCode: '6531',
    enName: 'Shache',
  },
  {
    code: '653126',
    name: '叶城县',
    cityCode: '6531',
    enName: 'Yecheng',
  },
  {
    code: '653127',
    name: '麦盖提县',
    cityCode: '6531',
    enName: 'Maigaiti',
  },
  {
    code: '653128',
    name: '岳普湖县',
    cityCode: '6531',
    enName: 'Yuepuhu',
  },
  {
    code: '653129',
    name: '伽师县',
    cityCode: '6531',
    enName: 'Jiashi',
  },
  {
    code: '653130',
    name: '巴楚县',
    cityCode: '6531',
    enName: 'Bachu',
  },
  {
    code: '653131',
    name: '塔什库尔干塔吉克自治县',
    cityCode: '6531',
    enName: 'Taxkorgan Tajik Autonomous County',
  },
  {
    code: '653201',
    name: '和田市',
    cityCode: '6532',
    enName: 'Hetian',
  },
  {
    code: '653221',
    name: '和田县',
    cityCode: '6532',
    enName: 'Hetian',
  },
  {
    code: '653222',
    name: '墨玉县',
    cityCode: '6532',
    enName: 'Moyu',
  },
  {
    code: '653223',
    name: '皮山县',
    cityCode: '6532',
    enName: 'Pishan',
  },
  {
    code: '653224',
    name: '洛浦县',
    cityCode: '6532',
    enName: 'Luopu',
  },
  {
    code: '653225',
    name: '策勒县',
    cityCode: '6532',
    enName: 'Cele',
  },
  {
    code: '653226',
    name: '于田县',
    cityCode: '6532',
    enName: 'Yutian',
  },
  {
    code: '653227',
    name: '民丰县',
    cityCode: '6532',
    enName: 'Minfeng',
  },
  {
    code: '654002',
    name: '伊宁市',
    cityCode: '6540',
    enName: 'Yining',
  },
  {
    code: '654003',
    name: '奎屯市',
    cityCode: '6540',
    enName: 'Kuitun',
  },
  {
    code: '654004',
    name: '霍尔果斯市',
    cityCode: '6540',
    enName: 'Huoerguosi',
  },
  {
    code: '654021',
    name: '伊宁县',
    cityCode: '6540',
    enName: 'Yining',
  },
  {
    code: '654022',
    name: '察布查尔锡伯自治县',
    cityCode: '6540',
    enName: 'Qapqal Xibe Autonomous County',
  },
  {
    code: '654023',
    name: '霍城县',
    cityCode: '6540',
    enName: 'Huocheng',
  },
  {
    code: '654024',
    name: '巩留县',
    cityCode: '6540',
    enName: 'Gongliu',
  },
  {
    code: '654025',
    name: '新源县',
    cityCode: '6540',
    enName: 'Xinyuan',
  },
  {
    code: '654026',
    name: '昭苏县',
    cityCode: '6540',
    enName: 'Zhaosu',
  },
  {
    code: '654027',
    name: '特克斯县',
    cityCode: '6540',
    enName: 'Tekesi',
  },
  {
    code: '654028',
    name: '尼勒克县',
    cityCode: '6540',
    enName: 'Nileke',
  },
  {
    code: '654201',
    name: '塔城市',
    cityCode: '6542',
    enName: 'Tachengshi',
  },
  {
    code: '654202',
    name: '乌苏市',
    cityCode: '6542',
    enName: 'Wusushi',
  },
  {
    code: '654221',
    name: '额敏县',
    cityCode: '6542',
    enName: 'Emin',
  },
  {
    code: '654223',
    name: '沙湾县',
    cityCode: '6542',
    enName: 'Shawan',
  },
  {
    code: '654224',
    name: '托里县',
    cityCode: '6542',
    enName: 'Tuoli',
  },
  {
    code: '654225',
    name: '裕民县',
    cityCode: '6542',
    enName: 'Yumin',
  },
  {
    code: '654226',
    name: '和布克赛尔蒙古自治县',
    cityCode: '6542',
    enName: 'Hoboksar Mongolian Autonomous County',
  },
  {
    code: '654301',
    name: '阿勒泰市',
    cityCode: '6543',
    enName: 'Aletai',
  },
  {
    code: '654321',
    name: '布尔津县',
    cityCode: '6543',
    enName: 'Buerjin',
  },
  {
    code: '654322',
    name: '富蕴县',
    cityCode: '6543',
    enName: 'Fuyun',
  },
  {
    code: '654323',
    name: '福海县',
    cityCode: '6543',
    enName: 'Fuhai',
  },
  {
    code: '654324',
    name: '哈巴河县',
    cityCode: '6543',
    enName: 'Habahe',
  },
  {
    code: '654325',
    name: '青河县',
    cityCode: '6543',
    enName: 'Qinghe',
  },
  {
    code: '654326',
    name: '吉木乃县',
    cityCode: '6543',
    enName: 'Jimunai',
  },
  {
    code: '659001',
    name: '石河子市',
    cityCode: '6590',
    enName: 'Shihezi',
  },
  {
    code: '659002',
    name: '阿拉尔市',
    cityCode: '6590',
    enName: 'Alaer',
  },
  {
    code: '659003',
    name: '图木舒克市',
    cityCode: '6590',
    enName: 'Tumushuke',
  },
  {
    code: '659004',
    name: '五家渠市',
    cityCode: '6590',
    enName: 'Wujia',
  },
  {
    code: '659006',
    name: '铁门关市',
    cityCode: '6590',
    enName: 'Tiemenguan',
  },
  {
    code: '710000',
    name: '台湾省',
    cityCode: '7100',
    enName: 'Taiwan',
  },
  {
    code: '810000',
    name: '香港特别行政区',
    cityCode: '8100',
    enName: 'Hong Kong',
  },
  {
    code: '820000',
    name: '澳门特别行政区',
    cityCode: '8200',
    enName: 'Aomen',
  },
];

export interface DistrictType {
  code: string;
  name: string;
  cityCode: string;
  enName: string;
}

export default cityChild;
